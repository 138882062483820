import { useState, useEffect } from "react";
import isEqual from "react-fast-compare";

// Our hook
export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const handler = window.setTimeout(() => {
      if (!isEqual(value, debouncedValue)) {
        setDebouncedValue(value);
      }
    }, delay);

    return () => {
      window.clearTimeout(handler);
    };
  }, [value, delay, debouncedValue]);

  return debouncedValue;
}
