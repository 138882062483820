import * as React from "react";
import { FieldProps, getIn } from "formik";
import Form from "react-bootstrap/Form";

function LabeledRadioSet(
  props: {
    options: { value: any; label: string }[];
    label?: string;
    id?: string;
  } & FieldProps
) {
  const { options, label, field, form, ...rest } = props;

  // This works around an issue in Formik which doesn't pick up dynamic fields
  React.useEffect(
    () =>
      props.form.setFieldValue(
        field.name,
        field.value !== undefined && field.value !== null ? field.value : ""
      ),
    [field.name]
  );

  const name = field.name;
  const touched = getIn(form.touched, name);
  const error = form.errors[name];

  return (
    <Form.Group>
      {label && <Form.Label className="font-weight-bold">{label}</Form.Label>}
      {options.map(opt => {
        const checked = getIn(form.values, name) == opt.value;

        return (
          <Form.Check
            key={opt.value}
            id={`${name}-${opt.value}`}
            name={name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={opt.value}
            checked={checked}
            type="radio"
            label={opt.label}
            isInvalid={touched && error !== undefined}
            custom
            {...rest}
          />
        );
      })}
      {touched && <div className="text-danger small">{error}</div>}
    </Form.Group>
  );
}

export default React.memo(LabeledRadioSet);
