import * as React from "react";

import makeAxios from "@app/support/api_client";
import { createSessionStore } from "../../support/sessionStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classNames from "classnames";
import styles from "./style.module.scss";
import { setupCache } from "axios-cache-interceptor";

// We don't use the default Axios here because setupCache is mutating the passed instance
// and we need cache headers to NOT be on for other Axios calls, such as CORS preflight
// in the export process.
const api = setupCache(makeAxios());

// State persistence
const store = createSessionStore("accountTree");

// Types
type LinkRecord = {
  id: number;
  href: string;
  login: string;
  name: string;
};

type AccountRecord = {
  id: number;
  login: string;
  href: string;
  name: string;
  links?: number;
  childAccounts?: AccountRecord[];
};

interface LinkLinkProps {
  id: number;
  links: LinkRecord[];
  count?: number;
  startExpanded: boolean;
  load();
}

interface TreeComponentProps {
  collapse?: number[];
  account: number;
  highlight?: number;
  showLinks?: boolean;
  ancestorsOnly?: boolean;
  expand: number[];
}

interface LinkLineLoaderProps {
  id: number;
  count?: number;
  eager: boolean;
}

interface AccountLineLoaderProps {
  id: number;
  data?: AccountRecord;
  eager: boolean;
}

interface AccountLineProps {
  account: AccountRecord;
  expanded: boolean;
  load();
}

interface ContextInterface {
  expandedAccounts: number[];
  collapsedAccounts: number[];
  expandedLinks: number[];
  highlightedAccount: number | null;
  ancestorsOnly: boolean;
  showLinks: boolean;
  toggleLink(id: number);
  toggleAccount(id: number);
}

const ExpandedContext = React.createContext<ContextInterface>({
  expandedAccounts: [],
  collapsedAccounts: [],
  expandedLinks: [],
  highlightedAccount: null,
  ancestorsOnly: false,
  showLinks: true,
  toggleLink: () => null,
  toggleAccount: () => null,
});

// Provides lazy loading for a list of Links in an account
const LinkLineLoader = React.memo((props: LinkLineLoaderProps) => {
  const [loaded, setLoaded] = React.useState(false);
  const [data, setData] = React.useState<LinkRecord[]>([]);
  const getTree = React.useCallback(() => {
    if (!loaded) {
      setLoaded(true);
      api
        .get("/trees/links", { params: { id: props.id } })
        .then((resp) => setData(resp.data.links as LinkRecord[]));
    }
  }, [props.id, loaded]);
  if (props.eager) {
    getTree();
  }

  return (
    <LinkLine
      id={props.id}
      count={props.count}
      load={getTree}
      startExpanded={props.eager}
      links={data}
    />
  );
});

// Renders a Link in an account
const LinkLine = React.memo((props: LinkLinkProps) => {
  const [expanded, setExpanded] = React.useState(props.startExpanded);
  const context = React.useContext(ExpandedContext);

  const load = React.useCallback(
    (e) => {
      e.preventDefault();
      props.load();
      context.toggleLink(props.id);
      setExpanded(!expanded);
    },
    [props.load, context.toggleLink, props.id, setExpanded, expanded, context, props]
  );

  const children = (props.links || []).map((link) => (
    <li key={`link-${link.id}`}>
      <a href={link.href}>
        <span className={styles.link}>{link.login}</span> {link.name}
      </a>
    </li>
  ));

  return (
    <li>
      <Row className={styles.item} noGutters>
        <Col>
          <a href="#" onClick={load}>
            <FontAwesomeIcon icon="link" className="mr-2" />
            {props.count} Link
            {props.count == 1 ? "" : "s"}
          </a>
          {expanded && <ul className={styles.tree}>{children}</ul>}
        </Col>
      </Row>
    </li>
  );
});

// Provides lazy loading for an account
const AccountLineLoader = React.memo((props: AccountLineLoaderProps) => {
  const context = React.useContext(ExpandedContext);
  const [data, setData] = React.useState(
    props.data ||
      ({
        id: 0,
        login: "",
        href: "",
        name: "",
      } as AccountRecord)
  );

  const getTree = React.useCallback(() => {
    if (data.childAccounts === undefined) {
      api.get("/trees/account", { params: { id: props.id } }).then((resp) => {
        const acc = resp.data.account as AccountRecord;
        if (context.ancestorsOnly && acc.childAccounts !== undefined) {
          const childrenOnAncestorPath = acc.childAccounts.filter(
            (a) => context.expandedAccounts.indexOf(a.id) !== -1
          );
          if (childrenOnAncestorPath.length > 0) {
            acc.childAccounts = childrenOnAncestorPath;
          }
        }
        setData(acc);
      });
    }
  }, [data.childAccounts, props.id, context.ancestorsOnly, context.expandedAccounts, setData]);
  if (props.eager) {
    getTree();
  }

  return <AccountLine load={getTree} account={data} expanded={props.eager} />;
});

const AccountLine = React.memo((props: AccountLineProps) => {
  const context = React.useContext(ExpandedContext);
  const [expanded, setExpanded] = React.useState(props.expanded);
  const icon = expanded ? "folder-open" : "folder";
  const { account } = props;

  const toggle = () => {
    props.load();
    setExpanded(!expanded);
    context.toggleAccount(account.id);
  };

  const links = (
    <LinkLineLoader
      key={`link-${account.id}`}
      id={account.id}
      count={account.links}
      eager={context.expandedLinks.indexOf(account.id) !== -1}
    />
  );

  const children =
    account.childAccounts &&
    account.childAccounts.map((c) => {
      return (
        <AccountLineLoader
          id={c.id}
          key={`account-${c.id}`}
          eager={
            context.expandedAccounts.indexOf(c.id) !== -1 &&
            context.collapsedAccounts.indexOf(c.id) === -1
          }
          data={c}
        />
      );
    });

  return (
    <li>
      <Row
        className={classNames(styles.item, {
          [styles.highlight]: context.highlightedAccount == props.account.id,
        })}
        noGutters
      >
        <Col>
          <a href="#" onClick={toggle}>
            <FontAwesomeIcon icon={icon} className="mr-2" />
            {account.name} ({account.login})
          </a>
        </Col>
        <Col xs="auto">
          <a href={account.href}>
            <FontAwesomeIcon icon="eye" />
          </a>
        </Col>
      </Row>
      {expanded && (
        <ul className={styles.tree}>
          {context.showLinks !== false && links}
          {children}
        </ul>
      )}
    </li>
  );
});

function inflateStore(expand: number[]) {
  if (expand.length > 0) {
    return {
      links: [] as number[],
      accounts: expand,
    };
  }
  const s = store.read();
  if (!s.links || !s.accounts) {
    return {
      links: [] as number[],
      accounts: [] as number[],
    };
  } else {
    return s;
  }
}

const TreeComponent = React.memo((props: TreeComponentProps) => {
  const { highlight, showLinks, ancestorsOnly, expand, collapse } = props;
  const [data, setData] = React.useState(() => inflateStore(expand || []));

  const toggleLink = React.useCallback(
    (id: number) => {
      const index = data.links.indexOf(id);
      if (index === -1) {
        data.links.push(id);
      } else {
        data.links.splice(index, 1);
      }
      setData(data);
      store.write(data);
    },
    [setData, data]
  );

  const toggleAccount = (id: number) => {
    const index = data.accounts.indexOf(id);
    if (index === -1) {
      data.accounts.push(id);
    } else {
      data.accounts.splice(index, 1);
    }
    setData(data);
    store.write(data);
  };

  const collapsedAccounts = collapse || [];
  const expandedAccounts = data.accounts || [];
  const expandedLinks = data.links || [];
  const highlightedAccount = highlight || null;

  return (
    <ExpandedContext.Provider
      value={{
        collapsedAccounts,
        expandedAccounts,
        expandedLinks,
        toggleAccount,
        toggleLink,
        highlightedAccount,
        ancestorsOnly: ancestorsOnly || false,
        showLinks: showLinks === false ? false : true,
      }}
    >
      <ul className={classNames(styles.tree, styles.treeTop)}>
        <AccountLineLoader id={props.account} eager={true} />
      </ul>
    </ExpandedContext.Provider>
  );
});

export default TreeComponent;
