import React from "react";
import ReportviewSelector from "../ReportviewSelector";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

function AddReportviews(props) {
  const confirm = "Checked reports will be added. Continue?";
  const action = "Add Reportviews";
  const [push, setPush] = React.useState(false);
  return (
    <>
      <input type="hidden" name="mode" value="add" />
      <ReportviewSelector {...props} />
      <Row className="align-items-center my-3">
        <Col className="text-center" xs="auto">
          <Button
            type="submit"
            variant="primary"
            size="lg"
            name="child"
            onClick={(e) => this.confirm(e, confirm)}
          >
            {action}
          </Button>
        </Col>
        <Col>
          <Row>
            <Col>
              <label htmlFor="rv_push_up">
                <input type="checkbox" name="rv_push_up" id="rv_push_up" /> Push reports to ancestor
                accounts?
              </label>
            </Col>
          </Row>
          <Row>
            <Col>
              <label htmlFor="rv_push_down">
                <input
                  type="checkbox"
                  name="rv_push_down"
                  id="rv_push_down"
                  checked={push}
                  onChange={(e) => setPush(e.currentTarget.checked)}
                />{" "}
                Push reports to {props.directChildrenCount} child accounts?
              </label>
            </Col>
          </Row>
          {push && (
            <Row>
              <Col>
                <label htmlFor="rv_push_down_all">
                  <input type="checkbox" name="rv_push_down_all" id="rv_push_down_all" /> Also push
                  reports to {props.allChildrenCount - props.directChildrenCount - 1} descendant
                  accounts?
                </label>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
}

export default AddReportviews;
