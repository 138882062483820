import React from "react";
import ReportviewSelector from "../ReportviewSelector";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

function PushReportviews(props) {
  const confirm = "Checked reports will be added to the sub-accounts. Continue?";
  const action = `Push selected reports to ${props.directChildrenCount} child accounts`;
  return (
    <>
      <input type="hidden" name="mode" value="push" />
      <ReportviewSelector {...props} />
      <Row>
        <Col className="text-center my-3">
          <Button
            type="submit"
            variant="primary"
            size="lg"
            name="child"
            onClick={(e) => this.confirm(e, confirm)}
          >
            {action}
          </Button>
          <Row className="mt-2">
            <Col>
              <label htmlFor="rv_push_down_all">
                <input type="checkbox" name="rv_push_down_all" id="rv_push_down_all" /> Also push
                reports to {props.allChildrenCount - 1} descendant accounts?
              </label>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default PushReportviews;
