import * as React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import RestDataProvider, { toastErrors } from "../Helpers/RestDataProvider";
import ComboDetailList from "./ComboDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, Formik } from "formik";
import { Scrollable, Searchable } from "../Helpers";
import { toast } from "react-toastify";
import LabeledField from "../Forms/Widgets/LabeledField";
import LabeledCheckbox from "../Forms/Widgets/LabeledCheckbox";
import ConfirmButton from "../Forms/Widgets/ConfirmButton";

import { defaultClient as axios } from "@app/support/api_client";
import isEqual from "react-fast-compare";

export type TComboTarget = {
  id: number;
  status?: number;
  name: string;
  disabled: boolean;
  mode?: number;
};

function CreateComboTarget({ mode, onClose, onSuccess }: { mode: number; onClose(); onSuccess() }) {
  const handleSubmit = (values) => {
    const v = { ...values };
    v.disabled = v.disabled ? 1 : 0;

    axios
      .post(`/combo_targets/`, {
        format: "json",
        comboTarget: Object.assign({ mode }, v),
      })
      .then(() => {
        onClose();
        onSuccess();
        toast.success("Created new combo target");
      })
      .catch(toastErrors);
  };

  const initialValues = {
    disabled: 0,
    mode,
    name: "",
  };

  return (
    <ComboTargetForm
      onClose={onClose}
      onSubmit={handleSubmit}
      title="Create New Combo Target"
      initialValues={initialValues}
    />
  );
}

function EditComboTarget({
  target,
  onClose,
  onSuccess,
}: {
  target: TComboTarget;
  onClose();
  onSuccess();
}) {
  const onDelete = React.useCallback(() => {
    axios
      .delete(`/combo_targets/${target.id}.json`)
      .then(() => {
        onClose();
        onSuccess();
        toast.success(`Deleted combo target: ${target.name}`);
      })
      .catch(toastErrors);
  }, [onClose, onSuccess, target.id, target.name]);

  const handleSubmit = (values) => {
    const v = { ...values };
    v.disabled = v.disabled ? 1 : 0;

    axios
      .put(`/combo_targets/${values.id}`, {
        format: "json",
        comboTarget: values,
      })
      .then(() => {
        onSuccess();
        onClose();
        toast.success("Updated combo target");
      })
      .catch(toastErrors);
  };

  return (
    <ComboTargetForm
      onDelete={onDelete}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={target.name}
      initialValues={target}
    />
  );
}

function ComboTargetForm({
  initialValues,
  title,
  onDelete,
  onClose,
  onSubmit,
}: {
  initialValues: any;
  title: string;
  onDelete?(e);
  onClose();
  onSubmit(values: any);
}) {
  const validate = (values) => {
    const errors = {} as any;
    if (values.name === "") {
      errors.name = "may not be blank";
    }
    return errors;
  };

  return (
    <Modal centered show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        enableReinitialize={true}
        render={(props) => (
          <form onSubmit={props.handleSubmit}>
            <Modal.Body>
              <Form.Group>
                <Field type="text" component={LabeledField} name="name" label="Name" />
                <Field component={LabeledCheckbox} custom name="disabled" label="Disabled?" />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              {onDelete && (
                <ConfirmButton
                  icon="trash"
                  label="Delete"
                  prompt="Really delete?"
                  onConfirm={onDelete}
                />
              )}
              <Button type="submit">
                <FontAwesomeIcon icon="save" /> Save
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
}

export default function ComboBrowser({ mode, canEdit }: { mode: number; canEdit: boolean }) {
  const [editingTarget, setEditingTarget] = React.useState<TComboTarget | null>(null);
  const [creatingNew, setCreatingNew] = React.useState(false);
  const [selected, setSelected] = React.useState<TComboTarget | null>(null);
  const clearSelected = React.useCallback(() => {
    setCreatingNew(false);
    setEditingTarget(null);
  }, [setEditingTarget]);

  const onSelect = React.useCallback(
    (next) => {
      if (selected === next) {
        setSelected(null);
      } else {
        setSelected(next);
      }
    },
    [selected]
  );

  return (
    <div>
      <Row className="mb-2">
        <Col>
          <h3>Combo Targets</h3>
        </Col>
        <Col xs="auto">
          {canEdit && (
            <Button size="sm" onClick={() => setCreatingNew(true)}>
              <FontAwesomeIcon icon="plus" /> Add New Target
            </Button>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <RestDataProvider
            active={true}
            url="/combo_targets.json"
            params={{ mode }}
            defaultValue={[] as TComboTarget[]}
            path="comboTargets"
          >
            {({ data, refresh }) => {
              return (
                <Searchable>
                  {({ searchRegexp }) => {
                    const combos = data.filter((t) => t.name.match(searchRegexp));
                    combos.sort((a, b) => (a.name < b.name ? -1 : 1));
                    return (
                      <Scrollable maxHeight={"50vh"}>
                        {creatingNew && (
                          <CreateComboTarget
                            mode={mode}
                            onClose={clearSelected}
                            onSuccess={refresh}
                          />
                        )}
                        {editingTarget && (
                          <EditComboTarget
                            target={editingTarget}
                            onClose={clearSelected}
                            onSuccess={refresh}
                          />
                        )}
                        <ListGroup variant="flush">
                          {combos.map((c) => {
                            const isSelected = isEqual(selected, c);
                            return (
                              <div className="list-group-item py-2" key={c.id}>
                                <Row>
                                  <Col>
                                    <a href="#" onClick={() => onSelect(c)}>
                                      <FontAwesomeIcon
                                        icon={isSelected ? "caret-down" : "caret-right"}
                                      />{" "}
                                      {c.name}
                                    </a>
                                  </Col>
                                  {isSelected && canEdit && (
                                    <Col xs="auto">
                                      <a href="#" onClick={() => setEditingTarget(c)}>
                                        <FontAwesomeIcon icon="pencil-alt" /> Edit
                                      </a>
                                    </Col>
                                  )}
                                </Row>
                                {isSelected && (
                                  <ComboDetailList
                                    comboTargetMode={mode}
                                    comboTargetId={c.id}
                                    canEdit={canEdit}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </ListGroup>
                      </Scrollable>
                    );
                  }}
                </Searchable>
              );
            }}
          </RestDataProvider>
        </Col>
      </Row>
    </div>
  );
}
