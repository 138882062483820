import * as React from "react";
import Search from "../Search";
import AdvancedSearch from "../AdvancedSearch";

type SearchSelectorState = {
  mode: string;
};

class SearchSelector extends React.PureComponent<any, any> {
  state = { mode: "smart" };

  toggle() {
    this.setState({ mode: this.state.mode === "smart" ? "advanced" : "smart" });
  }
  render() {
    return (
      <div>
        {this.state.mode === "smart" && <Search autoFocus={true} />}
        {this.state.mode === "advanced" && <AdvancedSearch />}
        <div className="text-right mt-3">
          <a href="#" onClick={() => this.toggle()} className="small">
            {this.state.mode === "smart" ? "Classic Search" : "Standard Search"}
          </a>
        </div>
      </div>
    );
  }
}

export default SearchSelector;
