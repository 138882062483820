import * as React from "react";
import { FieldProps, getIn } from "formik";
import Form from "react-bootstrap/Form";

function LabeledField(
  props: {
    label?: string;
  } & FieldProps
) {
  React.useEffect(() => {
    props.form.setFieldValue(props.field.name, props.field.value || "");
  }, [props.field.name]);

  const { label, field, form, ...rest } = props;

  const name = field.name;
  const touched = getIn(form.touched, name);
  const error = form.errors[name];
  return (
    <Form.Group>
      {label && <Form.Label className="font-weight-bold">{label}</Form.Label>}
      <Form.Control
        onBlur={field.onBlur}
        onChange={field.onChange}
        value={(field.value !== null && field.value !== undefined && field.value.toString()) || ""}
        isInvalid={touched && error !== undefined}
        name={name}
        {...rest}
      />
      {touched && <div className="text-danger small">{error}</div>}
    </Form.Group>
  );
}

export default React.memo(LabeledField);
