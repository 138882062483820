import * as React from "react";
import moment from "moment";
import RangeCalendar from "rc-calendar/lib/RangeCalendar";

import "rc-calendar/assets/index.css";

export default function DateRangePicker(props: {
  startDate?: string | moment.Moment;
  endDate?: string | moment.Moment;
  onChange(range: moment.Moment[]);
}) {
  const [range, setRange] = React.useState([moment(props.startDate), moment(props.endDate)]);
  const { onChange } = props;
  React.useEffect(() => {
    if (onChange !== undefined) {
      onChange(range);
    }
  }, [range, onChange]);
  return (
    <div>
      <input type="hidden" name="startDate" value={range[0].format("YYYY-MM-DD")} />
      <input type="hidden" name="endDate" value={range[1].format("YYYY-MM-DD")} />
      <RangeCalendar selectedValue={range} onSelect={setRange} />
    </div>
  );
}
