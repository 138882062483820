import * as React from "react";
import { useCallback, useState } from "react";
import Col from "react-bootstrap/Col";
import ExportConfig from "./config";
import produce from "immer";

function ExportFieldMappingField({ field, initialValue, setFieldValue }) {
  const [value, setValue] = useState(initialValue);

  const updateValue = useCallback(() => {
    setFieldValue(field, value);
  }, [setFieldValue, field, value]);

  return (
    <tr key={field}>
      <td className="text-right align-middle" style={{ whiteSpace: "nowrap", width: "25%" }}>
        {[field, ExportConfig.fieldNames[field]].filter((e) => e && e !== "").join(" - ")}
      </td>
      <td>
        <input
          type="text"
          data-field={field}
          className="form-control form-control-sm"
          placeholder={field}
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
          onBlur={updateValue}
        />
      </td>
    </tr>
  );
}

export default function ExportFieldMapping({ payload, setPayload }) {
  const fields = payload.columns.map((c) => c.name);
  const setFieldValueTo = React.useCallback(
    (field, value) => {
      const newPayload = produce(payload, (next) => {
        const c = next.columns.find((c) => c.name == field);
        const newTitle = value === "" ? field : value;
        c.title = newTitle;
      });

      setPayload(newPayload);
    },
    [payload, setPayload]
  );

  return (
    <div>
      {fields.length === 0 && <p>You have not selected any output fields.</p>}
      {fields.length > 0 && (
        <Col>
          <p>If you wish to change any output column names, set their desired names below.</p>
          <table className="table table-sm small">
            <thead>
              <tr>
                <th>Field</th>
                <th>Output Column Name</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((field) => {
                const payloadValue =
                  (payload.columns.find((f) => f.name === field) || {}).title || "";
                const value = payloadValue === "" ? ExportConfig.fields[field] || "" : payloadValue;
                return (
                  <ExportFieldMappingField
                    key={field}
                    field={field}
                    initialValue={value}
                    setFieldValue={setFieldValueTo}
                  />
                );
              })}
            </tbody>
          </table>
        </Col>
      )}
    </div>
  );
}
