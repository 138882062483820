import * as React from "react";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import SimpleDatePicker from "../DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./style.module.scss";

const matchOptions = {
  exact: { name: "Exactly Matches" },
  partial: { name: "Contains" },
  starts: { name: "Starts With" },
  after: { name: "On or After" },
  before: { name: "On or Before" },
  on: { name: "On" }
};

const textOptions = ["exact", "partial", "starts"];
const dateOptions = ["after", "before", "on"];

const searchWithinOptions = {
  acct_login: { name: "Account Login", match: textOptions, group: "account" },
  acct_name: { name: "Account Name", match: textOptions, group: "account" },
  acct_email: { name: "Account E-Mail", match: textOptions, group: "account" },
  acct_id: { name: "Account ID", match: textOptions, group: "account" },
  acct_date: {
    name: "Account Creation Date",
    match: dateOptions,
    group: "account"
  },

  link_login: { name: "Link Login", match: textOptions, group: "link" },
  link_name: { name: "Link Name", match: textOptions, group: "link" },
  link_email: { name: "Link E-Mail", match: textOptions, group: "link" },
  link_id: { name: "Link ID", match: textOptions, group: "link" },
  link_date: { name: "Link Creation Date", match: dateOptions, group: "link" },

  first_name: {
    name: "Respondent First Name",
    match: textOptions,
    group: "respondent"
  },
  last_name: {
    name: "Respondent Last Name",
    match: textOptions,
    group: "respondent"
  },
  resp_link: {
    name: "Respondents of a Link",
    match: textOptions,
    group: "respondent"
  },
  resp_pwd: {
    name: "Respondent Password",
    match: textOptions,
    group: "respondent"
  },
  resp_email: {
    name: "Respondent E-Mail",
    match: textOptions,
    group: "respondent"
  },
  resp_co: {
    name: "Respondent Company",
    match: textOptions,
    group: "respondent"
  },
  job: {
    name: "Respondent Position/Job",
    match: textOptions,
    group: "respondent"
  },
  resp_id: { name: "Respondent ID", match: textOptions, group: "respondent" },
  resp_date: {
    name: "Respondent Report Date",
    match: dateOptions,
    group: "respondent"
  },

  report_id: { name: "Report ID", match: textOptions, group: "report" },
  report_date: {
    name: "Report Creation Date",
    match: dateOptions,
    group: "report"
  }
};

function AdvancedSearchEntry(props: {
  index: number;
  setGroupFilter(index: number, filter: string): void;
  filter: string | null;
}) {
  const { index, setGroupFilter } = props;
  const [field, setField] = React.useState("acct_login");
  const [match, setMatch] = React.useState("extract");

  const changeField = React.useCallback(
    e => {
      setField(e.currentTarget.value);
      if (index === 0) {
        setGroupFilter(props.index, searchWithinOptions[e.currentTarget.value].group);
      }
    },
    [setField, setGroupFilter, index]
  );
  const onSetMatch = React.useCallback(e => setMatch(e.currentTarget.value), [setMatch]);

  let input;
  if (searchWithinOptions[field].match === dateOptions) {
    input = (
      <div className={styles.dateBox}>
        <SimpleDatePicker name="searches[]" />
      </div>
    );
  } else {
    input = (
      <input
        type="text"
        name="searches[]"
        autoFocus
        className="form-control"
        style={{ flexGrow: 2 }}
      />
    );
  }

  const activeSearchOptions = Object.entries(searchWithinOptions).filter(
    ([_, v]) => props.filter === null || props.filter === v.group
  );

  return (
    <Row noGutters>
      <Col xs={3}>
        <select
          name="fields[]"
          className={`form-control custom-select ${styles.box_a}`}
          style={{ flexGrow: 1 }}
          onChange={changeField}
        >
          {activeSearchOptions.map(([key, opt]) => {
            return (
              <option key={key} value={key}>
                {opt.name}
              </option>
            );
          })}
        </select>
      </Col>
      <Col xs={3}>
        <select
          name="matches[]"
          value={match}
          className={`form-control custom-select ${styles.box_b}`}
          style={{ flexGrow: 1 }}
          onChange={onSetMatch}
        >
          {searchWithinOptions[field].match.map(opt => {
            return (
              <option key={opt} value={opt}>
                {matchOptions[opt].name}
              </option>
            );
          })}
        </select>
      </Col>
      <Col>{input}</Col>
    </Row>
  );
}

function AdvancedSearch(props: { conditions: { id: number }[]; filter: string }) {
  const [conditions, setConditions] = React.useState(props.conditions);
  const [filter, setFilter] = React.useState(props.filter);

  const addCriteria = React.useCallback(
    () => setConditions([...conditions, { id: conditions.length }]),
    [conditions, setConditions]
  );

  const removeCriteria = React.useCallback(
    id => setConditions(conditions.filter(c => c.id !== id)),
    [conditions, setConditions]
  );

  const setGroupFilter = React.useCallback(
    (index, filter) => {
      if (index === 0) {
        setFilter(filter);
      }
    },
    [setFilter]
  );

  return (
    <React.Fragment>
      <input type="hidden" name="group" value={filter} />
      {conditions.map((c, idx) => {
        return (
          <Row key={c.id} className="align-items-center mb-3">
            <Col>
              <Row>
                <Col>
                  <AdvancedSearchEntry
                    index={idx}
                    filter={idx === 0 ? null : filter}
                    setGroupFilter={setGroupFilter}
                  />
                </Col>
                {conditions.length > 1 && (
                  <Col xs="auto">
                    <Button variant="secondary" onClick={() => removeCriteria(c.id)}>
                      <FontAwesomeIcon icon="minus" style={{ cursor: "pointer" }} />
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        );
      })}
      <Row>
        <Col className="text-right">
          <a href="#" onClick={addCriteria}>
            <FontAwesomeIcon icon="plus" /> Add Criteria
          </a>
        </Col>
      </Row>
      <Row className="border-top py-3 mt-3">
        <Col className="text-right">
          <Button type="submit" variant="primary" block>
            Search
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
}

AdvancedSearch.defaultProps = {
  conditions: [{ id: 0 }],
  filter: "account"
};
export default AdvancedSearch;
