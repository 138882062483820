import * as React from "react";
import Calendar from "rc-calendar";
import moment from "moment";
import Form from "react-bootstrap/Form";
import { useDebouncedState } from "../../support/useDebouncedState";
import "rc-calendar/assets/index.css";

function SimpleDatePicker(props: { name: string }) {
  const [date, setDate] = React.useState(moment());

  const [focused, setFocused] = useDebouncedState();
  const focus = React.useCallback(() => setFocused(true), [setFocused]);
  const blur = React.useCallback(() => setFocused(false), [setFocused]);

  return (
    <div onFocus={focus} onBlur={blur} tabIndex={1}>
      <Form.Control
        name={props.name}
        value={(date && date.format("YYYY-MM-DD")) || ""}
        onBlur={(e) => setDate(moment(e.currentTarget.value))}
      />
      {focused && (
        <Calendar
          showDateInput={true}
          value={date}
          format="YYYY-MM-DD"
          dateInputPlaceholder="YYYY-MM-DD"
          onSelect={setDate}
          onChange={(v) => setDate(moment(v))}
          style={{ position: "absolute", zIndex: 9999 }}
          {...props}
        />
      )}
    </div>
  );
}
export default SimpleDatePicker;
