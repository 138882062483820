import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Searchable, CardNavItem, Scrollable } from "../Helpers";
import ListGroup from "react-bootstrap/ListGroup";
import { TAssessment, TInstrument } from "../../support/dataTypes";
import { defaultClient as axios } from "@app/support/api_client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { sprintf } from "sprintf-js";

function ReportviewDetail({
  id,
  sampleReportEndpoint,
}: {
  id: number;
  sampleReportEndpoint: string;
}) {
  const [assessment, setAssessment] = React.useState<TAssessment>({
    id: 0,
    name: "",
    reportviews: [],
  });

  React.useEffect(() => {
    axios.get(`/assessments/${id}.json`).then((res) => {
      const payload = res.data as TAssessment;
      setAssessment(payload);
    });
  }, [id]);

  return (
    <Card>
      <Card.Header>Reportviews Dependent on Selected Assessment</Card.Header>
      <ListGroup variant="flush">
        {assessment.reportviews.map((r) => (
          <ListGroup.Item key={r.id} className="p-1">
            <a href={`/report_config/${r.id}/edit`}>{r.id} </a>- {r.name} &nbsp;
            <a href={sprintf(sampleReportEndpoint, r.id)} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFilePdf} />
            </a>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Card>
  );
}

function InstrumentDetail({
  id,
  sampleReportEndpoint,
}: {
  id: number;
  sampleReportEndpoint: string;
}) {
  const [instrument, setInstrument] = React.useState<TInstrument>({
    id: -1,
    status: 0,
    name: "",
    description: "",
    assessments: [],
  });

  const [selectedAssessment, setSelectedAssessment] = React.useState<number | null>(null);

  React.useEffect(() => {
    axios.get(`/instruments/${id}.json`).then((res) => {
      const payload = res.data as TInstrument;
      setInstrument(payload);
      if (payload.assessments.length > 0) {
        setSelectedAssessment(payload.assessments[0].id);
      } else {
        setSelectedAssessment(null);
      }
    });
  }, [id]);

  return (
    <div>
      <Card className="mb-4">
        <Card.Header>Dependent Assessments</Card.Header>
        <Scrollable maxHeight="25vh">
          <ListGroup variant="flush">
            {instrument.assessments.map((i) => (
              <ListGroup.Item
                key={i.id}
                className="p-1"
                onClick={() => setSelectedAssessment(i.id)}
                active={i.id === selectedAssessment}
                style={{ cursor: "pointer" }}
              >
                <a
                  href={`/report_config/assessments?id=${i.id}`}
                  style={i.id === selectedAssessment ? { color: "inherit" } : {}}
                >
                  {i.id}{" "}
                </a>
                - {i.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Scrollable>
      </Card>
      {selectedAssessment !== null && (
        <ReportviewDetail id={selectedAssessment} sampleReportEndpoint={sampleReportEndpoint} />
      )}
    </div>
  );
}

export default function InstrumentBrowser({
  id,
  instruments,
  sampleReportEndpoint,
}: {
  id: number | null;
  instruments: TInstrument[];
  sampleReportEndpoint: string;
}) {
  const [selected, setSelected] = React.useState<number | null>(id);

  React.useEffect(() => {
    if (id) {
      const setScrollTop = () => {
        const instrumentsList = document.querySelector("#instruments");
        const selectedElement = document.querySelector(".bg-primary");
        if (instrumentsList instanceof Element && selectedElement instanceof HTMLDivElement) {
          instrumentsList.scrollTop = selectedElement.offsetTop;
        }
      };

      window.addEventListener("load", setScrollTop);

      return () => window.removeEventListener("load", setScrollTop);
    }
  }, [id]);

  return (
    <Searchable>
      {({ searchRegexp }) => {
        const visible = instruments.filter((a) => `${a.id} ${a.name}`.match(searchRegexp));

        return (
          <Row noGutters>
            <Col className="border-right">
              <Scrollable maxHeight="50vh" group flush className="small" id="instruments">
                {visible.map((v) => (
                  <CardNavItem
                    key={v.id}
                    onClick={() => setSelected(v.id)}
                    selected={v.id === selected}
                  >
                    {v.id} - {v.name}
                  </CardNavItem>
                ))}
              </Scrollable>
            </Col>
            {selected && (
              <Col className="m-2 small">
                <InstrumentDetail id={selected} sampleReportEndpoint={sampleReportEndpoint} />
              </Col>
            )}
          </Row>
        );
      }}
    </Searchable>
  );
}
