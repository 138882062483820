import { useState, useEffect } from "react";

export function useDebouncedState(initial, delay = 25) {
  const [value, setValue] = useState(initial);
  const [debouncedValue, setDebouncedValue] = useState(initial);

  useEffect(() => {
    const handler = window.setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => window.clearTimeout(handler);
  }, [value, delay]);

  return [debouncedValue, setValue];
}
