import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import LabeledField from "../Forms/Widgets/LabeledField";
import { Field } from "formik";

import { TLanguageType, TQuestionItem } from ".";
import MultipleChoiceEditor from "./MultipleChoiceEditor";
interface LocalizedQuestionProps {
  name: string;
  kind: string;
  language: TLanguageType;
  items: TQuestionItem[];
}

class LocalizedQuestion extends React.PureComponent<LocalizedQuestionProps> {
  render() {
    const { name, language, items, kind } = this.props;

    const isSelectType = kind === "select" || kind === "select-all" || kind === "yes/no";
    const canAddItems = kind === "select" || kind === "select-all";

    return (
      <Row>
        <Col xs={3} className="font-weight-bold text-right">
          {language.name}
        </Col>
        <Col>
          <Row>
            <Col>
              <Field
                label="Prompt"
                name={`${name}.prompts.lang${language.id}`}
                component={LabeledField}
              />
            </Col>
          </Row>
          {isSelectType && (
            <Row>
              <Col>
                <MultipleChoiceEditor
                  name={`${name}.items`}
                  languageId={language.id}
                  items={items}
                  canAdd={canAddItems}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    );
  }
}

export default LocalizedQuestion;
