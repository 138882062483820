import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { TLanguageType } from ".";
import Form from "react-bootstrap/Form";

interface LanguageSelectorProps {
  languages: TLanguageType[];
  selectedLanguages: TLanguageType[];
  setLanguages(selectedLanguages: TLanguageType[]);
}

export default function LanguageSelector(props: LanguageSelectorProps) {
  const { languages, setLanguages, selectedLanguages } = props;

  const addLanguage = React.useCallback(
    e => {
      const id = parseInt(e.currentTarget.value, 10);
      const l = languages.find(l => l.id === id);
      if (l !== undefined) {
        setLanguages([...selectedLanguages, l]);
      }
    },
    [languages, setLanguages, selectedLanguages]
  );

  const removeLanguage = React.useCallback(
    e => {
      const id = parseInt(e.currentTarget.value, 10);
      setLanguages(selectedLanguages.filter(sl => sl.id !== id));
    },
    [selectedLanguages, setLanguages]
  );

  const unusedLanguages = languages.filter(
    l => selectedLanguages.find(l2 => l2.id === l.id) === undefined
  );
  const usedLanguages = selectedLanguages;

  return (
    <div>
      <Row>
        <Col xs="auto">
          <Form.Group>
            <Form.Label className="font-weight-bold">Add Language</Form.Label>
            <select onChange={addLanguage} className="form-control" value="none">
              <option value="none">({unusedLanguages.length} available)</option>
              {unusedLanguages.map(l => (
                <option key={l.id} value={l.id}>
                  {l.name}
                </option>
              ))}
            </select>
          </Form.Group>
        </Col>
        <Col xs="auto">
          <Form.Group>
            <Form.Label className="font-weight-bold">Remove Language</Form.Label>
            <select onChange={removeLanguage} className="form-control" value="none">
              <option value="none">({usedLanguages.length} available)</option>
              {usedLanguages.map(l => (
                <option key={l.id} value={l.id}>
                  {l.name}
                </option>
              ))}
            </select>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
}
