import * as React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { defaultClient as Axios } from "@app/support/api_client";

function DoExport({ payload }) {
  const [downloadUrl, setDownloadUrl] = React.useState("");
  const [checking, setChecking] = React.useState(false);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    setDownloadUrl("");
  }, [payload]);

  const cancelChecks = React.useCallback(() => {
    setChecking(false);
  }, [setChecking]);

  const checkUrl = React.useCallback(
    (url, wait) => {
      Axios.head(`${url}?wait=${wait}`)
        .then(() => {
          setChecking(false);
          setDownloadUrl(url + "?wait=300&_skipProxy=1");
        })
        .catch(() => {
          checkUrl(url, wait);
        });
    },
    [setChecking, setDownloadUrl]
  );

  const download = React.useCallback(() => {
    Axios.post("/exports", { payload })
      .then((data) => {
        setChecking(true);
        checkUrl(data.data["target"], 60);
      })
      .catch((e) => {
        setError(e.response.data.error);
      });
  }, [payload, setChecking, setError, checkUrl]);

  return (
    <Card>
      <Card.Header>Perform Export</Card.Header>
      <Card.Body>
        {error !== "" && <p className="alert alert-danger">{error}</p>}
        {checking && (
          <div className="alert alert-info">
            <Row>
              <Col>
                <FontAwesomeIcon icon="spinner" spin /> Waiting for export to be ready...
              </Col>
              <Col xs="auto">
                <Button onClick={cancelChecks} variant="danger" size="sm">
                  <FontAwesomeIcon icon="times" />
                </Button>
              </Col>
            </Row>
          </div>
        )}
        <div>
          {downloadUrl === "" && !checking && (
            <Button onClick={download}>
              <FontAwesomeIcon icon="file-excel" /> Perform Export
            </Button>
          )}
          {downloadUrl !== "" && (
            <a href={downloadUrl} target="_blank noopener noreferrer" className="btn btn-success">
              <FontAwesomeIcon icon="cloud-download-alt" /> Download Ready!
            </a>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

function DownloadConfiguration({ payload, environment }) {
  const downloadConfig = React.useCallback(() => {
    const json = JSON.stringify({
      ...payload,
      environment: environment,
      date: new Date().toISOString(),
    });
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const tempLink = document.createElement("a");
    tempLink.href = url;
    const d = new Date();
    tempLink.setAttribute(
      "download",
      `export-config-${d.getFullYear()}-${d.getMonth()}-${d.getDay()}.json`
    );
    document.body.appendChild(tempLink);
    tempLink.click();
    setTimeout(() => {
      document.body.removeChild(tempLink);
    }, 0);
  }, [payload, environment]);

  return (
    <Card>
      <Card.Header>Save Configuration</Card.Header>
      <Card.Body>
        <p>
          If you wish to re-run this export again at a later date, download this configuration so
          you can reuse it later.
        </p>
        <Button onClick={downloadConfig}>
          <FontAwesomeIcon icon="arrow-down" /> Download Export Configuration
        </Button>
      </Card.Body>
    </Card>
  );
}

export default function ExportFinish({
  payload,
  environment,
}: {
  payload: any;
  environment: string;
}) {
  return (
    <div>
      <Row>
        <Col>
          <DownloadConfiguration payload={payload} environment={environment} />
        </Col>
        <Col>{<DoExport payload={payload} />}</Col>
      </Row>

      <hr />
    </div>
  );
}
