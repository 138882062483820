import * as React from "react";
import classnames from "classnames";

export { Scrollable } from "./Scrollable";
export { Searchable } from "./Searchable";

export function CardNavItem({
  selected,
  onClick,
  children
}: {
  selected: boolean;
  onClick(e: React.MouseEvent<HTMLElement>);
  children?: React.ReactNode;
}) {
  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={onClick}
      className={classnames("list-group-item", {
        "bg-primary": selected,
        "text-white": selected
      })}
    >
      {children}
    </div>
  );
}
