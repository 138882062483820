import Button from "react-bootstrap/Button";
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ConfirmButton(props) {
  const { children, icon, label, prompt, onConfirm, ...rest } = props;
  const click = React.useCallback(
    e => {
      const res = window.confirm(prompt || "<missing prompt>");
      if (res && onConfirm) {
        onConfirm(e);
      }
    },
    [onConfirm, prompt]
  );
  return (
    <Button variant="danger" {...rest} onClick={click}>
      {icon && <FontAwesomeIcon icon={icon} />} {label}
    </Button>
  );
}

export default React.memo(ConfirmButton);
