import * as React from "react";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { CardNavItem, Scrollable, Searchable } from "../Helpers";
import { TReportview, TReportviewGroup } from "../../support/dataTypes";

const filterReportviews = (
  groupName: string,
  reportviews: TReportview[],
  search: RegExp
): TReportview[] => {
  return reportviews.filter(
    (r) =>
      groupName.match(search) ||
      (r.name || "").match(search) ||
      (r.description || "").match(search) ||
      r.id.toString().match(search) ||
      (r.product_code || "").match(search) !== null ||
      (r.short_name || "").match(search) !== null
  );
};
class Reportview extends React.PureComponent<TReportview> {
  private Statuses = ["Normal", "Unpushable", "Deprecated", "Deprecated & Unpushable", "Combo"];

  render() {
    const { id, name, description, href, status } = this.props;
    return (
      <div className="list-group-item">
        <Row>
          <Col>
            <a href={href}>
              {id} - {name} {description ? "-- " + description : ""}
            </a>
          </Col>
          <Col xs="auto">{this.Statuses[status || 0] || status}</Col>
        </Row>
      </div>
    );
  }
}

function ReportviewBrowser({ groups }: { groups: TReportviewGroup[] }) {
  const [selectedGroup, setSelectedGroup] = useState<number | null>(null);

  return (
    <Searchable>
      {({ searchRegexp }) => {
        const visibleGroups = groups.filter(
          (g) => filterReportviews(g.name, g.reportviews, searchRegexp).length > 0
        );
        const activeGroup = visibleGroups.find((g) => g.id === selectedGroup) ||
          visibleGroups[0] || { id: -1, name: "", reportviews: [] };
        const visibleReportviews =
          activeGroup && filterReportviews(activeGroup.name, activeGroup.reportviews, searchRegexp);

        return (
          <Row noGutters className="border-top">
            <Col xs={4} className="border-right">
              <Scrollable maxHeight="50vh" group flush>
                {visibleGroups.map((group) => (
                  <CardNavItem
                    key={group.id}
                    selected={group.id === activeGroup.id}
                    onClick={() => setSelectedGroup(group.id)}
                  >
                    {group.name}
                  </CardNavItem>
                ))}
              </Scrollable>
            </Col>
            <Col>
              <Scrollable maxHeight="50vh" group flush>
                {visibleReportviews.map((r) => (
                  <Reportview key={r.id} {...r} />
                ))}
              </Scrollable>
            </Col>
          </Row>
        );
      }}
    </Searchable>
  );
}

export default ReportviewBrowser;
