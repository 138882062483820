import * as React from "react";
import { useCallback } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import TypeaheadSearchField, {
  Props as TypeaheadSearchFieldProps,
  SingleValueProps
} from "./TypeaheadSearchField";

export default function SearchableSelect<T>({
  url,
  direction,
  onItemSelect,
  value,
  ...rest
}: TypeaheadSearchFieldProps<T> & SingleValueProps<T>) {
  // If onItemSelect isn't passed, just keep the state on this component
  const [stateValue, setValue] = React.useState<T>();
  const defaultOnItemSelect = React.useCallback(v => setValue(v), [setValue]);

  React.useEffect(() => {
    if (value !== null) {
      setValue(value);
    }
  }, [value]);

  // The select box variant should prevent Enter keypresses from bubbling up to the form.
  const ref = React.useRef<HTMLDivElement>(null);
  const onKeyDown = useCallback(e => {
    switch (e.keyCode) {
      case 13:
        ref.current && ref.current.blur();
        e.preventDefault();
        e.stopPropagation();
        break;
    }
  }, []);

  return (
    <div onKeyDown={onKeyDown} tabIndex={1} ref={ref}>
      <TypeaheadSearchField
        eager={true}
        url={url}
        onItemSelect={onItemSelect || defaultOnItemSelect}
        {...rest}
        direction={direction}
        icon={`caret-${direction || "down"}` as IconProp}
        iconPosition="after"
        value={stateValue}
      />
    </div>
  );
}

SearchableSelect.defaultProps = {
  labelKey: "name",
  valueKey: "id"
};
