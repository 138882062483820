import * as React from "react";
import { FieldProps, getIn } from "formik";
import Form from "react-bootstrap/Form";

function LabeledCheckbox(
  props: {
    label?: string;
    id?: string;
    type?: "checkbox" | "radio";
  } & FieldProps
) {
  const { field, form, ...rest } = props;

  // This works around an issue in Formik which doesn't pick up dynamic fields
  React.useEffect(
    () =>
      props.form.setFieldValue(
        field.name,
        field.value !== undefined && field.value !== null ? field.value : ""
      ),
    [field.name]
  );

  const name = field.name;
  const touched = getIn(form.touched, name);
  const error = form.errors[name];
  const checked = !!getIn(form.values, name);

  return (
    <Form.Group>
      <Form.Check
        id={props.id || name}
        name={name}
        onBlur={field.onBlur}
        onChange={field.onChange}
        value={JSON.stringify(field.value)}
        checked={checked}
        custom
        isInvalid={touched && error !== undefined}
        {...rest}
      />
      {touched && <div className="text-danger small">{error}</div>}
    </Form.Group>
  );
}

export default React.memo(LabeledCheckbox);
