import * as React from "react";
import { TReportviewGroup } from "../../../support/dataTypes";
import { Field } from "formik";
import LabeledSearchSelect from "./LabeledSearchSelect";
import { defaultClient as axios } from "@app/support/api_client";

function FilterableReportviewSelector({ label, name }: { label: string; name: string }) {
  const [reportviewGroups, setReportviewGroups] = React.useState<TReportviewGroup[]>([]);

  React.useEffect(() => {
    axios.get("/reportview_groups.json?include=reportviews").then((res) => {
      setReportviewGroups(res.data);
    });
  }, []);

  const options = reportviewGroups.flatMap((rg) =>
    rg.reportviews.map((rv) => ({
      group: rg.name,
      name: `${rv.id} - ${rv.name}`,
      value: rv.id.toString(),
    }))
  );
  return <Field component={LabeledSearchSelect} options={options} name={name} label={label} />;
}

export default React.memo(FilterableReportviewSelector);
