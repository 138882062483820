import * as React from "react";
import classnames from "classnames";

export function Scrollable({
  maxHeight,
  children,
  group,
  flush,
  className,
  style,
  ...props
}: {
  group?: boolean;
  flush?: boolean;
  maxHeight: string;
  children?: React.ReactNode;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div
      className={classnames(className, {
        "list-group": group,
        "list-group-flush": group && flush
      })}
      style={Object.assign({}, style, { maxHeight: maxHeight, overflowY: "auto" })}
      {...props}
    >
      {children}
    </div>
  );
}
