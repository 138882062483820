import * as React from "react";
import { useEffect, useState, useMemo, useCallback } from "react";
import Form from "react-bootstrap/Form";
import { FormControlProps } from "react-bootstrap/FormControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDebounce } from "../../support/useDebounce";
import { useDebouncedState } from "../../support/useDebouncedState";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export function Searchable(
  props: {
    name?: string;
    icon?: IconProp | null;
    iconPosition?: "before" | "after";
    waiting?: boolean;
    value?: string;
    autoFocus?: boolean;
    delayMs?: number;
    selectOnBlur?: boolean;
    includeBlank?: boolean;
    children({
      setFocused,
      setSearch,
      search,
      searchRegexp,
      highlighted,
      selected,
      focused,
    }: {
      setFocused(focused: boolean);
      setSearch(value: string);
      setSelectedIndex(index: number);
      setHighlightedIndex(index: number);
      search: string;
      searchRegexp: RegExp;
      highlighted: number | null;
      selected: number | null;
      focused: boolean;
    });
    blurValue?: string;
  } & React.HTMLAttributes<HTMLInputElement> &
    FormControlProps
) {
  const {
    icon,
    iconPosition,
    children,
    placeholder,
    blurValue,
    tabIndex,
    value,
    delayMs,
    waiting,
    selectOnBlur,
    includeBlank,
    ...rest
  } = props;

  const initialIndex = {
    highlighted: null,
    selected: null,
  };

  const [search, setSearch] = useState<string>(value || "");
  const [focused, setFocused] = useDebouncedState(false);
  const [index, setIndex] = useState<{ highlighted: number | null; selected: null | number }>(
    initialIndex
  );
  useEffect(() => setIndex({ selected: null, highlighted: null }), [search]);
  const debouncedSearch = useDebounce<string>(search, delayMs || 0);

  // If the Value prop changes, update the Search state
  useEffect(() => {
    if (value !== undefined) {
      setSearch(value);
    }
  }, [value]);

  // When search changes, we want to update our regex, as well
  const searchRegexp = useMemo(() => {
    return new RegExp(
      (debouncedSearch || "").toString().replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"),
      "i"
    );
  }, [debouncedSearch]);

  const { disabled } = props;

  const onKeyDown = useCallback(
    (e) => {
      if (disabled) return;
      const next = { ...index };

      switch (e.keyCode) {
        case 9:
          // tab
          if (selectOnBlur) {
            next.selected = next.highlighted;
          }
          break;
        case 13: // enter
          setFocused(false);
          next.selected = next.highlighted;
          break;
        case 33: // pageup
          setFocused(true);
          if (next.highlighted === null) next.highlighted = 0;
          next.highlighted -= 10;
          break;
        case 34: //pagedown
          setFocused(true);
          if (next.highlighted === null) next.highlighted = -1;
          next.highlighted += 10;
          break;
        case 38: //up
          setFocused(true);
          if (next.highlighted === null) next.highlighted = 0;
          next.highlighted--;
          break;
        case 40: //down
          setFocused(true);
          if (next.highlighted === null) next.highlighted = -1;
          next.highlighted++;
          break;
        case 27: // escape
          setFocused(false);
          setSearch("");
          next.highlighted = null;
          break;
      }
      if (next.highlighted !== null) {
        next.highlighted = next.highlighted % 65535;
      }

      switch (e.keyCode) {
        case 13:
          if (index.selected !== null) {
            e.stopPropagation();
            e.preventDefault();
          }
          break;
        case 33:
        case 34:
        case 38:
        case 40:
          e.stopPropagation();
          e.preventDefault();
      }
      setIndex(next);
    },
    [index, disabled, selectOnBlur, setFocused]
  );

  const onChange = useCallback(
    (e) => {
      setSearch(e.currentTarget.value);
      setIndex(initialIndex);
      if (e.currentTarget.value !== "") {
        setFocused(true);
      }
    },
    [setFocused, initialIndex]
  );

  const onFocus = useCallback(() => {
    if (!props.disabled) setFocused(true);
  }, [props.disabled, setFocused]);
  const onBlur = useCallback(() => setFocused(false), [setFocused]);

  const onSetHighlighted = React.useCallback(
    (highlighted) => setIndex({ highlighted, selected: index.selected }),
    [index]
  );
  const onSetSelected = React.useCallback(
    (selected) => setIndex({ highlighted: index.highlighted, selected }),
    [index]
  );

  const iconJsx = (
    <div className="input-group-prepend">
      <span className={`input-group-text`}>
        {/* this works around a bug in the Typescript compiler, see https://github.com/FortAwesome/Font-Awesome/issues/14774 */}
        {icon && <FontAwesomeIcon icon={icon} />}
        {!icon && <FontAwesomeIcon icon={"search"} />}
      </span>
    </div>
  );

  return (
    <div
      tabIndex={1}
      onClick={onFocus}
      onFocus={onFocus}
      onBlur={onBlur}
      style={{ flex: 1, position: "relative", outline: "none" }}
    >
      <div className="input-group">
        {icon !== null && iconPosition !== "after" && iconJsx}
        <Form.Control
          type="search"
          placeholder={placeholder || "Filter..."}
          onChange={onChange}
          autoComplete="off"
          value={blurValue && blurValue !== "" && !focused ? blurValue : search}
          onKeyDown={onKeyDown}
          {...rest}
        />
        {icon !== null && iconPosition === "after" && iconJsx}
        {waiting && iconPosition !== "after" && (
          <div className="input-group-append">
            <span className="input-group-text">
              <FontAwesomeIcon icon="spinner" spin />
            </span>
          </div>
        )}
      </div>
      {children({
        search: debouncedSearch,
        searchRegexp,
        highlighted: index.highlighted,
        selected: index.selected,
        setFocused,
        setSearch,
        focused,
        setHighlightedIndex: onSetHighlighted,
        setSelectedIndex: onSetSelected,
      })}
    </div>
  );
}
