import * as React from "react";
import { connect } from "react-redux";
import { Provider } from "../Store";

import Nav from "react-bootstrap/Nav";
import AccountsPane from "./AccountsPane";
import ReportsPane from "./ReportsPane";
import LinksPane from "./LinksPane";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { StoreType } from "../Store/reducers";

import styles from "./style.module.scss";

type PaneKey = "toggle" | "accounts" | "links" | "reports";

type ColumnDefinition = {
  key: string;
  title: string;
  width?: number;
};

type PaneDefinition = {
  key: PaneKey;
  title: string;
  component: string;
  icon: IconName;
  columns: ColumnDefinition[];
};

interface ClipboardPropsFromState {
  accounts: number;
  links: number;
  reports: number;
}

interface ClipboardPropsFromParent {
  panes: PaneDefinition[];
  targets: ClipboardTargets;
}

interface ClipboardProps extends ClipboardPropsFromState, ClipboardPropsFromParent {}

export type ClipboardTargets = {
  account?: number;
  link?: number;
  trashLink?: number;
};

type ClipboardState = {
  open: boolean;
  key: PaneKey | null;
};

const components = {
  AccountsPane: AccountsPane,
  ReportsPane: ReportsPane,
  LinksPane: LinksPane,
};

class RecordClipboard extends React.PureComponent<ClipboardProps, ClipboardState> {
  static defaultProps: any;

  state = {
    open: false,
    key: null,
  };

  changeNav(key: PaneKey | null) {
    let open = this.state.open;
    if (key === "toggle" || !this.state.open) {
      open = !this.state.open;
    }
    if (key === "toggle" && !open) {
      key = null;
    }
    this.setState({ open, key });
  }

  static getDerivedStateFromProps(nextProps: ClipboardProps, prevState: ClipboardState) {
    if (prevState.key !== null && nextProps[prevState.key] === 0) {
      return { open: false };
    }
    return null;
  }

  render() {
    const panes = this.props.panes.map((pane) => {
      const C = components[pane.component];
      const active = this.state.key === pane.key;
      const { key, ...rest } = pane;
      return <C key={key} active={active} {...rest} targets={this.props.targets} />;
    });

    const nav = this.props.panes.map((pane) => {
      const c = this.props[pane.key];
      const t = c === 1 ? pane.title.replace(/s$/, "") : pane.title;
      return (
        c > 0 && (
          <Nav.Item className="col-auto" key={pane.key}>
            <Nav.Link eventKey={pane.key} disabled={c == 0}>
              <FontAwesomeIcon icon={pane.icon} /> {c} {t}
            </Nav.Link>
          </Nav.Item>
        )
      );
    });

    const { accounts, links, reports } = this.props;

    if (accounts === 0 && links === 0 && reports === 0) {
      return null;
    }

    return (
      <div className={classnames({ [styles.open]: this.state.open }, styles.container)}>
        {this.state.open && <div className={styles.pane}>{panes}</div>}

        <Nav
          variant={this.state.open ? "tabs" : "pills"}
          className={styles.bottomTabs}
          onSelect={(key) => this.changeNav((key || "accounts") as PaneKey)}
          activeKey={this.state.key || ""}
        >
          {nav}
          <Nav.Item className="ml-auto">
            <Nav.Link eventKey="toggle">
              <FontAwesomeIcon icon="window-minimize" />
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    );
  }
}

RecordClipboard.defaultProps = {
  panes: [
    {
      key: "accounts",
      title: "Accounts",
      component: "AccountsPane",
      icon: "key",
      columns: [
        { key: "login", title: "Login", width: 4 },
        { key: "name", title: "Name" },
      ],
    },
    {
      key: "links",
      title: "Links",
      component: "LinksPane",
      icon: "link",
      columns: [
        { key: "login", title: "Login", width: 4 },
        { key: "name", title: "Name" },
      ],
    },
    {
      key: "reports",
      title: "Reports",
      component: "ReportsPane",
      icon: "file-alt",
      columns: [
        { key: "login", title: "Login", width: 4 },
        { key: "name", title: "Name" },
        { key: "shortName", title: "Report" },
      ],
    },
  ],
};

const mapStateToProps = (state: StoreType): ClipboardPropsFromState => ({
  accounts: state.accountClipboard.length,
  links: state.linkClipboard.length,
  reports: state.reportClipboard.length,
});

const ConnectedClipboard = connect(mapStateToProps)(RecordClipboard);

const fn = (props: ClipboardProps) => (
  <Provider>
    <ConnectedClipboard {...props} />
  </Provider>
);

export default fn;
