import * as React from "react";
import RestDataProvider, { toastErrors } from "../Helpers/RestDataProvider";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FilterableReportviewSelector from "../Forms/Widgets/FilterableReportviewSelector";
import { TReportview } from "../../support/dataTypes";
import { Formik, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import LabeledField from "../Forms/Widgets/LabeledField";
import LabeledRadioSet from "../Forms/Widgets/LabeledRadioSet";
import ConfirmButton from "../Forms/Widgets/ConfirmButton";

import { defaultClient as axios } from "@app/support/api_client";

export type TComboSource = {
  id: number;
  reportview: TReportview;
  sequence: number;
  comboMin: number;
  comboMax: number;
};

type TEmptyComboSource = {
  id: 0;
};

const SEQUENCE_MAP = ["A", "B", "C", "D"];

interface FormInterface {
  comboTargetId: number;
  comboDetailId: number;
  comboTargetMode: number;
  onSuccess: () => void;
  onClose: () => void;
}

/** Renders a form to edit a ComboSource record */
function ComboSourceForm({
  title,
  initialValues,
  comboTargetMode,
  onClose,
  onSubmit,
  onDelete,
}: {
  title: string;
  initialValues: any;
  comboTargetMode: number;
  onClose();
  onSubmit(values: any);
  onDelete?(e: any): void;
}) {
  const sequenceValues = ["A"];
  if (comboTargetMode === 2 || comboTargetMode === 5 || comboTargetMode === 6) {
    sequenceValues.push("B");
  }
  if (comboTargetMode === 5 || comboTargetMode === 6) {
    sequenceValues.push("C");
    sequenceValues.push("D");
  }
  const label = comboTargetMode === 2 ? "Compare" : "Source";
  const options = sequenceValues.map((v, i) => ({ value: i, label: `${label} ${v}` }));

  const validate = (values) => {
    const errors = {} as any;
    if (!values.reportview_id) {
      errors.reportview_id = "must be present";
    }
    return errors;
  };

  return (
    <Modal centered show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize={true}
        validate={validate}
        render={(props) => (
          <form onSubmit={props.handleSubmit}>
            <Modal.Body>
              <FilterableReportviewSelector name="reportview_id" label="Reportview" />
              <Row>
                <Col>
                  <Field component={LabeledField} name="combo_min" label="Minimum" />
                </Col>
                <Col>
                  <Field component={LabeledField} name="combo_max" label="Maximum" />
                </Col>
                <Col>
                  <Field
                    component={LabeledRadioSet}
                    name="sequence"
                    label="Sequence"
                    options={options}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              {onDelete && (
                <ConfirmButton
                  icon="trash"
                  label="Delete"
                  prompt="Really delete?"
                  onConfirm={onDelete}
                />
              )}
              <Button type="submit">
                <FontAwesomeIcon icon="save" /> Save
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
}

/** Edit an existing combo source record in a modal */
function EditComboSource({
  comboSource,
  onSuccess,
  onClose,
  comboTargetId,
  comboDetailId,
  comboTargetMode,
}: FormInterface & { comboSource: TComboSource }) {
  const uri = `/combo_targets/${comboTargetId}/combo_details/${comboDetailId}/combo_sources/${comboSource.id}.json`;
  const handleSubmit = (values) => {
    axios
      .put(uri, { comboSource: values })
      .then(() => {
        onClose();
        onSuccess();
        toast.success("Updated combo source");
      })
      .catch(toastErrors);
  };

  const initialValues = {
    combo_max: comboSource.comboMax,
    combo_min: comboSource.comboMin,
    reportview_id: comboSource.reportview.id,
    sequence: comboSource.sequence,
  };

  const onDelete = React.useCallback(() => {
    axios
      .delete(uri)
      .then(() => {
        toast.success("Deleted combo source");
        onClose();
        onSuccess();
      })
      .catch(toastErrors);
  }, [uri, onClose, onSuccess]);

  return (
    <ComboSourceForm
      title="Edit Combo Source"
      comboTargetMode={comboTargetMode}
      initialValues={initialValues}
      onClose={onClose}
      onSubmit={handleSubmit}
      onDelete={onDelete}
    />
  );
}

/** Create a new combo source in a modal */
function CreateComboSource({
  onSuccess,
  onClose,
  comboTargetMode,
  comboTargetId,
  comboDetailId,
}: FormInterface) {
  const handleSubmit = (values) => {
    axios
      .post(`/combo_targets/${comboTargetId}/combo_details/${comboDetailId}/combo_sources.json`, {
        comboSource: values,
      })
      .then(() => {
        onClose();
        onSuccess();
        toast.success("Added new source");
      })
      .catch(toastErrors);
  };

  const initialValues = {
    combo_max: 1,
    combo_min: 1,
    reportview_id: null,
    sequence: 0,
  };

  return (
    <ComboSourceForm
      comboTargetMode={comboTargetMode}
      title="Create New Combo Detail"
      initialValues={initialValues}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
}

/** Wrapper which renders create/edit form based on inputs */
function ComboSourceEditor({
  comboTargetId,
  comboDetailId,
  comboSource,
  onSuccess,
  onClose,
  comboTargetMode,
}: FormInterface & {
  comboSource: TComboSource | TEmptyComboSource;
}) {
  return (
    <React.Fragment>
      {comboSource.id === 0 && (
        <CreateComboSource
          comboTargetId={comboTargetId}
          comboDetailId={comboDetailId}
          comboTargetMode={comboTargetMode}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      )}
      {comboSource.id !== 0 && (
        <EditComboSource
          comboTargetId={comboTargetId}
          comboDetailId={comboDetailId}
          comboTargetMode={comboTargetMode}
          comboSource={comboSource as TComboSource}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      )}
    </React.Fragment>
  );
}

/** Render a single ComboSource line item */
function ComboSource({
  comboSource,
  setEditing,
  canEdit,
}: {
  comboSource: TComboSource;
  refreshSources();
  setEditing(record: TComboSource);
  canEdit: boolean;
}) {
  const { comboMin, comboMax, sequence, reportview } = comboSource;
  const range = comboMin == comboMax ? comboMin : `${comboMin} to ${comboMax}`;
  const edit = React.useCallback(
    (e) => {
      setEditing(comboSource);
      e.preventDefault();
    },
    [setEditing, comboSource]
  );
  return (
    <Row>
      <Col>
        <strong>{SEQUENCE_MAP[sequence] || "A"}:</strong> {range} of {reportview.id} -{" "}
        {reportview.name}{" "}
        {reportview.description && (
          <span style={{ color: "#888", fontSize: "10pt" }}>{reportview.description}</span>
        )}
      </Col>
      {canEdit && (
        <Col xs="auto">
          <a href="#" onClick={edit}>
            <FontAwesomeIcon icon="pencil-alt" /> Edit
          </a>
        </Col>
      )}
    </Row>
  );
}

/** Render a list of ComboSource items */
export function ComboSourceList({
  comboTargetId,
  comboDetailId,
  comboTargetMode,
  canEdit,
}: {
  comboTargetId: number;
  comboDetailId: number;
  comboTargetMode: number;
  canEdit: boolean;
}) {
  const [editing, setEditing] = React.useState<TComboSource | TEmptyComboSource | null>(null);
  const clearEditing = React.useCallback(() => setEditing(null), [setEditing]);

  let action;
  switch (comboTargetMode) {
    case 1:
      action = "by combining existing reports of type";
      break;
    case 2:
      action = "by comparing existing reports of type";
      break;
    case 3:
      action = "by converting existing reports of type";
      break;
    case 4:
      action = "by converting it into a";
      break;
    case 5:
      action = "by dynamically joining existing reports of type";
      break;
    case 6:
      action = "by statically joining existing reports of type";
      break;
  }

  return (
    <Card className="my-3">
      <Card.Header>
        <Row>
          <Col>
            <strong>{action}:</strong>
          </Col>
          {canEdit && (
            <Col xs="auto">
              <Button size="sm" onClick={() => setEditing({ id: 0 })}>
                <FontAwesomeIcon icon="plus" /> Add New Source
              </Button>
            </Col>
          )}
        </Row>
      </Card.Header>
      <ListGroup>
        <RestDataProvider
          active={true}
          url={`/combo_targets/${comboTargetId}/combo_details/${comboDetailId}/combo_sources.json?camelize=true&fields=id,reportview,sequence,combo_min,combo_max`}
          params={{ combo_detail_id: comboDetailId }}
          defaultValue={[] as TComboSource[]}
          path="comboSources"
        >
          {({ data, refresh }) => {
            return (
              <div>
                {data.map((s) => (
                  <ListGroup.Item key={s.id}>
                    <ComboSource
                      comboSource={s}
                      refreshSources={refresh}
                      setEditing={setEditing}
                      canEdit={canEdit}
                    />
                  </ListGroup.Item>
                ))}
                {editing && (
                  <ComboSourceEditor
                    comboDetailId={comboDetailId}
                    comboTargetId={comboTargetId}
                    comboTargetMode={comboTargetMode}
                    comboSource={editing}
                    onSuccess={refresh}
                    onClose={clearEditing}
                  />
                )}
              </div>
            );
          }}
        </RestDataProvider>
      </ListGroup>
    </Card>
  );
}
