import * as React from "react";
import PickerHandle from "..";
import { connect } from "react-redux";

import { pickup, putdown } from "../../Store/actions";
import { Provider } from "../../Store";

const findMatch = (c, id) => c.find((e) => e.id === id);

const Hoc = connect(
  (state, ownProps) => {
    const selection = ownProps.records.filter((record) => {
      const id = record.fields.id;
      switch (record.type) {
        case "account":
          return findMatch(state.accountClipboard, id) !== undefined;
        case "report":
          return findMatch(state.reportClipboard, id) !== undefined;
        case "link":
          return findMatch(state.linkClipboard, id) !== undefined;
      }
    });
    return { selection };
  },
  {
    pickup,
    putdown,
  }
)(PickerHandle);

const BoundHandle = function (props) {
  return (
    <Provider>
      <Hoc {...props} />
    </Provider>
  );
};
export default BoundHandle;
