import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import KeyValueEditor from "../KeyValueEditor";
import isEqual from "react-fast-compare";
import TestItemLocales from "../../../../config/locales/en.test_item_defaults.yml";
const itemDefaults = TestItemLocales.en.test_items.defaults.statements;

function defaultsFor(k1, k2) {
  return ((itemDefaults[k1] || {})[k2] || ["", ""]).map((i) => [i.toString()]);
}

export default function StatementEditor(props: {
  readonly: boolean;
  name: string;
  items: string[];
  presentationType: string;
  instrumentKey: string;
}) {
  const { name } = props;
  const resizble = true;

  const [lastLabels, setLastLabels] = useState({});
  const [instrumentType, setInstrumentType] = useState(
    props.instrumentKey || StatementEditor.defaultProps.instrumentKey
  );
  const [presentation, setPresentation] = useState(
    props.presentationType || StatementEditor.defaultProps.presentationType
  );

  const [items, setItems] = useState(() => {
    if (props.items && props.items.length > 0) {
      return props.items.map((i) => [i]);
    } else {
      return defaultsFor(instrumentType, presentation);
    }
  });

  const updateDefaultLabels = useCallback(
    (instrumentType, presentation) => {
      const defaults =
        lastLabels[`${instrumentType}-${presentation}`] ||
        defaultsFor(instrumentType, presentation);
      setItems(defaults);
    },
    [lastLabels, setItems]
  );

  const onSetInstrumentType = useCallback(
    (e) => {
      const existingDefaults = defaultsFor(instrumentType, presentation);
      if (isEqual(existingDefaults, items)) {
        updateDefaultLabels(e.currentTarget.value, presentation);
      }
      setInstrumentType(e.currentTarget.value);
    },
    [setInstrumentType, items, instrumentType, presentation, updateDefaultLabels]
  );

  const onSetPresentationType = useCallback(
    (e) => {
      const existingDefaults = defaultsFor(instrumentType, presentation);
      if (isEqual(existingDefaults, items)) {
        updateDefaultLabels(instrumentType, e.currentTarget.value);
      }
      setPresentation(e.currentTarget.value);
    },
    [setPresentation, items, instrumentType, presentation, updateDefaultLabels]
  );

  useEffect(() => {
    const l = { ...lastLabels };
    l[`${instrumentType}-${presentation}`] = items;
    if (!isEqual(l, lastLabels)) {
      setLastLabels(l);
    }
  }, [items, instrumentType, presentation, lastLabels]);

  let notes: string | null = null;
  switch (presentation) {
    case "likert":
      notes =
        "Statements are the values for each point on the Likert scale. Often these will be numeric values, 1-N.";
      break;
    case "force_rank":
      "Statements are the statements that the user will be asked to rank.";
  }

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Instrument Type (data value)</Form.Label>
            <select
              disabled={props.readonly}
              name={`${name}[instrument_key]`}
              className="form-control"
              value={instrumentType}
              onChange={onSetInstrumentType}
            >
              <option value="SA2R4">Behaviors (SA2R4)</option>
              <option value="PIAV2">Motivators (PIAV2)</option>
              <option value="HA3">Hartman (HA3)</option>
              <option value="EQ">Emotional Intelligence (EQ)</option>
              <option value="STRESS">Stress (STRESS)</option>
              <option value="STRESS2">Stress Section 2 (STRESS2)</option>
              <option value="DNAPLUS">DNA 25 - Talent (DNAPLUS)</option>
              <option value="SSI3">Target Selling Insights (SSI3)</option>
              <option value="TB">Other (Test Bank) (TB)</option>
            </select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Question Presentation (data value)</Form.Label>
            <select
              disabled={props.readonly}
              name={`${name}[presentation_type]`}
              className="form-control"
              value={presentation}
              onChange={onSetPresentationType}
            >
              <option value="likert">Likert (likert)</option>
              <option value="force_rank">Force Rank (force_rank)</option>
            </select>
          </Form.Group>
        </Col>
      </Row>
      {notes && <p>{notes}</p>}
      <KeyValueEditor
        editable={!props.readonly}
        initialValues={items}
        fieldNames={[`${name}[statements]`]}
        onSetItems={(i) => setItems(i)}
        columnLabels={["Label"]}
        resizeable={resizble}
      />
    </div>
  );
}

StatementEditor.defaultProps = {
  instrumentKey: "SA2R4",
  presentationType: "likert",
};
