import React from "react";
import KeyValueEditor from "..";

export default function StandaloneKeyValueEditor({
  initialValues,
  fieldNames,
  columnLabels,
}: {
  fieldNames: string[];
  columnLabels: string[];
  initialValues: string[][];
}) {
  const [items, setItems] = React.useState(initialValues);
  return (
    <KeyValueEditor
      onSetItems={setItems}
      initialValues={items}
      columnLabels={columnLabels}
      fieldNames={fieldNames}
    />
  );
}
