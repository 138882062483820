import * as React from "react";

import SortableList, { SortableDragHandle } from "../SortableList";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import SearchableSelect from "../Forms/Widgets/SearchableSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type TTestItem = {
  id: number;
  name: string;
  attributeScale?: string;
};

const testItemSchema = {
  type: "array",
  items: {
    type: "object",
    properties: {
      id: { type: "number" },
      name: { type: "string" },
      attributeScale: { type: ["string", "null"] },
    },
  },
};

export default function StudyQuestionEditor({
  testItems,
  languageId,
  readonly,
}: {
  languageId: number;
  testItems: TTestItem[];
  readonly: boolean;
}) {
  const [items, setItems] = React.useState<TTestItem[]>(testItems);
  const onItemsChanged = React.useCallback((newItems) => setItems(newItems), [setItems]);
  const onAddItem = React.useCallback(
    (newItem: TTestItem) => setItems([...items, newItem]),
    [items, setItems]
  );

  return (
    <div>
      {!readonly && (
        <Row className="mb-3 align-items-center">
          <Col xs={{ span: 4 }}>
            <h5>Attach Question</h5>
          </Col>
          <Col>
            <SearchableSelect<TTestItem>
              direction="up"
              filter={(r) => !items.find((i) => i.id === r.id)}
              icon={null}
              labelKey="name"
              onItemSelect={onAddItem}
              params={{ language_id: languageId, camelize: true, perPage: 100 }}
              placeholder="Type to find a question"
              queryParamName="search"
              schema={testItemSchema}
              url="/test_items.json"
              valueKey="id"
            />
          </Col>
        </Row>
      )}
      <ListGroup>
        <SortableList
          items={items}
          onItemsChanged={onItemsChanged}
          editable={!readonly}
          sortable={!readonly}
          useDragHandle
          lockAxis="y"
          lockToContainerEdges={true}
        >
          {({ item, index, removeRow }) => {
            return (
              <div>
                <input type="hidden" name="study_items[]" value={item.id} />
                <div className="d-flex align-items-center mb-2">
                  <span className="mr-2">
                    <SortableDragHandle />
                  </span>
                  <ListGroupItem style={{ flex: 1 }}>
                    <Row>
                      <Col xs={8}>
                        {index + 1}. <a href={`/test_items/${item.id}`}>{item.name}</a>
                      </Col>
                      <Col>{item.attributeScale || "[no scale]"}</Col>
                      <Col xs="auto">
                        {!readonly && (
                          <Button onClick={() => removeRow(index)} variant="danger" size="sm">
                            <FontAwesomeIcon icon="minus" />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </ListGroupItem>
                </div>
              </div>
            );
          }}
        </SortableList>
      </ListGroup>
    </div>
  );
}
