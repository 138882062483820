import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";

function PrintOptionButton({
  name,
  value,
  populate,
  active,
}: {
  name: string;
  value: string;
  populate(value: string, incl: boolean);
  active: boolean;
}) {
  const onSetAdd = React.useCallback(() => populate(value, true), [value, populate]);
  const onSetRemove = React.useCallback(() => populate(value, false), [value, populate]);

  return (
    <Row key={name} className="mb-2">
      <Col>{name}</Col>
      <Col xs="auto">
        <Button
          variant={active ? "success" : "secondary"}
          block
          onClick={active ? onSetRemove : onSetAdd}
        >
          <FontAwesomeIcon icon={active ? "check" : "plus"} />
        </Button>
      </Col>
    </Row>
  );
}

function trimmedLines(text: string) {
  return text.split(/\r?\n/m);
}

function PrintOptions(props: {
  options: string;
  presets: {
    name: string;
    value: string;
  }[];
}) {
  const [newOptions, setOptions] = useState<string[]>(() => trimmedLines(props.options));
  const [expert, setExpert] = useState(false);

  const populate = useCallback(
    (v: string, incl: boolean) => {
      let newLines: string[];
      if (incl) {
        if (!newOptions.includes(v)) {
          newLines = newOptions.concat([v]);
        } else {
          newLines = newOptions;
        }
      } else {
        newLines = newOptions.filter((l) => l != v);
      }
      setOptions(newLines.filter((line) => line.length !== 0));
    },
    [setOptions, newOptions]
  );

  const buttons = props.presets.map((po, i) => (
    <PrintOptionButton
      key={i}
      name={po.name}
      value={po.value}
      populate={populate}
      active={newOptions.includes(po.value)}
    />
  ));

  const onSetOptions = useCallback(
    (e) => {
      setOptions(trimmedLines(e.currentTarget.value));
    },
    [setOptions]
  );

  return (
    <React.Fragment>
      <Row>
        <Col xs={2}>
          <Form.Label className="font-weight-bold">Print Options</Form.Label>
        </Col>
        <Col xs={8}>{buttons}</Col>
      </Row>
      <Row className="mb-3">
        <Col className="offset-2 small">
          <a href="#" onClick={() => setExpert(!expert)}>
            Expert Mode
          </a>
        </Col>
      </Row>
      {expert && (
        <Form.Group className="row border-top py-3 mb-3">
          <Col xs={{ offset: 2, span: 8 }}>
            <textarea
              style={{ fontFamily: "monospace", whiteSpace: "pre" }}
              className="bg-secondary text-white p-2"
              value={newOptions.join("\n")}
              onChange={onSetOptions}
              cols={80}
              rows={20}
            ></textarea>
          </Col>
        </Form.Group>
      )}
      <input type="hidden" name="account[print_options]" value={newOptions.join("\n")} />
      <Form.Group className="row border-top py-3">
        <Col xs={{ offset: 2, span: 2 }}>
          <Button type="submit" block>
            Save & Push
          </Button>
        </Col>
      </Form.Group>
    </React.Fragment>
  );
}

PrintOptions.defaultProps = {
  options: "",
  presets: [
    { name: "2013 Report Style", value: "<REPORT_STYLE>2013</REPORT_STYLE>" },
    { name: "Legacy TSI Labels", value: "<TSILABELS>LEGACY</TSILABELS>" },
    { name: "Legacy EQ Pages", value: "<EQFLAV>LEGACY</EQFLAV>" },
    { name: "Disable Comp Report Watermarks", value: "<COMPWATERMARK>false</COMPWATERMARK>" },
    {
      name: "Restricted comp report for international",
      value: "<RESTRICTEDCOMPINTL>TRUE</RESTRICTEDCOMPINTL>",
    },
    { name: "Enable JTC Wheel", value: "<JTCWHEEL>true</JTCWHEEL>" },
  ],
};
export default PrintOptions;
