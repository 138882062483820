import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";

import styles from "./style.module.scss";

interface ToggleProps {
  records: any[];
  pickup: Function;
  putdown: Function;
}

function toggleState(props) {
  const selected = new Set(props.selection);
  const selectedLength = props.records.filter(e => selected.has(e)).length;

  if (selectedLength === props.records.length && selectedLength > 0) {
    return 2;
  } else if (selectedLength === 0) {
    return 0;
  } else {
    return 1;
  }
}

function toggle(props: ToggleProps) {
  const state = toggleState(props);
  if (state === 0) {
    props.records.forEach(r => props.pickup(r.type, r.fields));
  } else {
    props.records.forEach(r => props.putdown(r.type, r.fields.id));
  }
}

const iconStates: IconName[] = ["square", "minus-square", "check-square"];

function PickerHandle(props) {
  const icon = iconStates[toggleState(props)];
  return (
    <span className={styles.picker} onClick={() => toggle(props)}>
      <FontAwesomeIcon icon={["far", icon]} />
    </span>
  );
}

export default PickerHandle;
