import * as React from "react";
import { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrayMove from "array-move";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  SortableContainerProps,
} from "react-sortable-hoc";

export const SortableDragHandle = SortableHandle(() => (
  <span className="btn btn-light" style={{ cursor: "grab" }}>
    <FontAwesomeIcon icon="bars" />
  </span>
));

const Container = SortableContainer(({ children }) => <div>{children}</div>);
const Item = SortableElement(({ children }) => <div>{children}</div>);

interface SortableListProps<T> {
  items: T[];
  resizeable: boolean;
  editable: boolean;
  sortable: boolean;
  onItemsChanged(items: T[]): void;
  children(props: {
    item: T;
    index: number;
    removeRow(index: number): void;
    setRow(index: number, value: T): void;
    moveRow(args: { oldIndex: number; newIndex: number }): void;
  }): React.ReactElement;
}

function SortableList<T>({
  items,
  resizeable,
  editable,
  sortable,
  onItemsChanged,
  children,
  ...sortableContainerProps
}: SortableListProps<T> & SortableContainerProps) {
  const setRow = useCallback(
    (index, row) => {
      const i = [...items];
      i[index] = row;
      onItemsChanged(i);
    },
    [items, onItemsChanged]
  );

  const removeRow = useCallback(
    (index) => {
      const i = [...items];
      i.splice(index, 1);
      onItemsChanged(i);
    },
    [onItemsChanged, items]
  );

  const moveRow = useCallback(
    ({ oldIndex, newIndex }) => onItemsChanged(arrayMove(items, oldIndex, newIndex)),
    [items, onItemsChanged]
  );

  const { onSortEnd, ...sortRest } = sortableContainerProps;
  return (
    <Container onSortEnd={moveRow} {...sortRest}>
      {items.map((item, index) => (
        <Item index={index} key={index} disabled={!sortable}>
          {children({ item, index, moveRow, removeRow, setRow })}
        </Item>
      ))}
    </Container>
  );
}

SortableList.defaultProps = {
  editable: true,
  sortable: true,
  resizeable: true,
};

export default SortableList;
