import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Searchable, CardNavItem, Scrollable } from "../Helpers";
import { TAssessment } from "../../support/dataTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { sprintf } from "sprintf-js";
import { defaultClient as axios } from "@app/support/api_client";

function AssessmentBrowser({
  id,
  assessments,
  sampleReportEndpoint,
}: {
  id: number | null;
  assessments: TAssessment[];
  sampleReportEndpoint: string;
}) {
  const [selected, setSelected] = React.useState<number | null>(null);
  const [detail, setDetail] = React.useState({ name: "", instruments: [], reportviews: [] });

  const updateSelected = React.useCallback(
    (id) => {
      setSelected(id);
      if (id !== null) {
        axios.get(`/assessments/${id}.json`).then((res) => setDetail(res.data));
      }
    },
    [setDetail, setSelected]
  );

  React.useEffect(() => {
    if (id) {
      updateSelected(id);

      const setScrollTop = () => {
        const assessmentsList = document.querySelector("#assessments");
        const selectedElement = document.querySelector(".bg-primary");

        if (assessmentsList instanceof Element && selectedElement instanceof HTMLDivElement) {
          assessmentsList.scrollTop = selectedElement.offsetTop;
        }
      };

      window.onload = setScrollTop;

      return () => {
        window.onload = null;
      };
    }
  }, [id, updateSelected]);

  return (
    <Searchable>
      {({ searchRegexp }) => {
        const visible = assessments.filter((a) => `${a.id} ${a.name}`.match(searchRegexp));

        return (
          <Row noGutters>
            <Col className="border-right">
              <Scrollable maxHeight="50vh" group flush className="small" id="assessments">
                {visible.map((v) => (
                  <CardNavItem
                    key={v.id}
                    onClick={() => updateSelected(v.id)}
                    selected={v.id === selected}
                  >
                    {v.id} - {v.name}
                  </CardNavItem>
                ))}
              </Scrollable>
            </Col>
            {detail.name.length > 0 && (
              <Col className="p-4">
                <Scrollable maxHeight="50vh">
                  <h5>{detail.name}</h5>
                  {detail.instruments.length > 0 && (
                    <Card className="my-4 small">
                      <Card.Header>
                        <strong>Contained Instruments</strong>
                      </Card.Header>

                      <div className="list-group list-group-flush">
                        {(detail.instruments || []).map((i: any) => (
                          <div key={i.name} className="list-group-item">
                            <a href={`/report_config/instruments?id=${i.id}`}>{i.id} </a>- {i.name}
                          </div>
                        ))}
                      </div>
                    </Card>
                  )}

                  {detail.reportviews.length > 0 && (
                    <Card className="my-4 small">
                      <Card.Header>
                        <strong>Dependent Reportviews</strong>
                      </Card.Header>

                      <div className="list-group list-group-flush">
                        {(detail.reportviews || []).map((i: any) => (
                          <div key={i.id} className="list-group-item">
                            <a href={`/report_config/${i.id}/edit`}>{i.id} </a>- {i.name} &nbsp;
                            <a
                              href={sprintf(sampleReportEndpoint, i.id)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FontAwesomeIcon icon={faFilePdf} />
                            </a>
                          </div>
                        ))}
                      </div>
                    </Card>
                  )}
                </Scrollable>
              </Col>
            )}
          </Row>
        );
      }}
    </Searchable>
  );
}
export default AssessmentBrowser;
