import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface PositionedQuestionProps {
  index: number;
  total: number;
  move(index: number, delta: number): void;
}

export default class PositionHandle extends React.PureComponent<PositionedQuestionProps> {
  moveUp = () => this.props.move(this.props.index, this.props.index - 1);
  moveDown = () => this.props.move(this.props.index, this.props.index + 1);
  render() {
    return (
      <div style={{ fontSize: "1.25em" }}>
        {this.props.index > 0 && (
          <span key="up" onClick={this.moveUp} style={{ cursor: "pointer", marginRight: "0.45em" }}>
            <FontAwesomeIcon icon="arrow-up" />
          </span>
        )}
        {this.props.index < this.props.total - 1 && (
          <span
            key="down"
            onClick={this.moveDown}
            style={{ cursor: "pointer", marginRight: "0.45em" }}
          >
            <FontAwesomeIcon icon="arrow-down" />
          </span>
        )}
      </div>
    );
  }
}
