import * as React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import ExportConfig from "./config";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { sortBy } from "../../support/utils";

import { defaultClient as axios } from "@app/support/api_client";

type TItemData = { [key: string]: { dataKey: string; elapsed?: boolean; response?: number } };
type TFieldInfo = {
  name: string;
  title: string;
  dataKey?: string;
  elapsed?: boolean;
  response?: number;
};

function groupSelectionsToFields(groupSelections, itemData: TItemData) {
  const columnMap: { [key: string]: TFieldInfo } = {};

  // Generate a set of all the field names which aren't study data keys
  const nonStudyFields = new Set<string>(["B_C_1_1"]);
  Object.entries(groupSelections).forEach(([group, fields]) => {
    (fields as string[]).forEach((field) => {
      if (group !== "STUDY") nonStudyFields.add(field);
    });
  });

  Object.entries(groupSelections).forEach(([group, fields]) => {
    (fields as string[]).forEach((field) => {
      // If this is a study group, and the name we want is already taken, we'll have to rename it.
      const nameConflicts = group === "STUDY" && nonStudyFields.has(field);

      let name = field;
      if (nameConflicts) name = `${group}__${field}`;

      const p: TFieldInfo = { name, title: ExportConfig.fields[field] || name };
      // If we have extra info about this item (ie, it's a test item with a data key), augment the record with it
      if (field in itemData) {
        const d = itemData[field];
        p.dataKey = d.dataKey;
        if (d.elapsed) p.elapsed = true;
        if (d.response) p.response = d.response;
      }
      columnMap[name] = p;
    });
  });

  return Object.values(columnMap).sort(sortBy("name"));
}

export default function ExportFieldSelector({ payload, setPayload }) {
  const [selectedGroup, setSelectedGroup] = React.useState("");

  const [allGroups, setAllGroups] = React.useState(ExportConfig.groups);
  const [allMappings, setAllMappings] = React.useState(ExportConfig.fieldMappings);
  const [itemData, setItemData] = React.useState<TItemData>({});

  const toggleGroup = React.useCallback(
    (val) => {
      const newGS = { ...payload.groupSelections };
      const full = Object.keys(allMappings[val]).length === (newGS[val] || []).length;
      if (!full) {
        newGS[val] = Object.keys(allMappings[val]);
      } else {
        newGS[val] = [];
      }
      setPayload({
        ...payload,
        groupSelections: newGS,
        columns: groupSelectionsToFields(newGS, itemData),
      });
    },
    [payload, setPayload, allMappings, itemData]
  );

  const toggleField = React.useCallback(
    (e) => {
      const checked = e.target.checked;
      const val = e.target.value;
      const newGS = { ...payload.groupSelections };
      const gItems = newGS[selectedGroup] || [];
      if (checked) {
        newGS[selectedGroup] = [...gItems, val];
      } else {
        newGS[selectedGroup] = gItems.filter((v) => v !== val);
      }
      const newPayload = {
        ...payload,
        groupSelections: newGS,
        columns: groupSelectionsToFields(newGS, itemData),
      };
      setPayload(newPayload);
    },
    [selectedGroup, payload, setPayload, itemData]
  );

  const lastStudy = React.useRef("");

  React.useEffect(() => {
    if (payload.study === lastStudy.current) {
      return;
    }

    lastStudy.current = payload.study;
    const newItemData: TItemData = {};
    if (payload.study && payload.study !== "") {
      payload.groupSelections["STUDY"] = [];
      axios.get(`/studies/${payload.study}.json`).then((data) => {
        const keys = {};
        data.data.testItems.forEach((item) => {
          // If this is a force rank scale, we need N fields, one for each statement
          if (item.statements.length > 0 && item.presentationType == "force_rank") {
            item.statements.forEach((_, statementIndex) => {
              const paddedIndex = `${statementIndex + 1}`.padStart(2, "0");
              const k = `${item.dataKey}_R${paddedIndex}`;
              keys[k] = `${item.name}: Statement ${paddedIndex} Response`;
              newItemData[k] = { response: statementIndex + 1, dataKey: item.dataKey };
            });
            // Otherwise, just one response field will do
          } else {
            keys[item.dataKey] = `${item.name}: Response`;
            newItemData[item.dataKey] = { dataKey: item.dataKey, response: 1 };
          }

          // We also need an elapsed field per item
          const k = `${item.dataKey}_ELAPSED`;
          keys[k] = `${item.name}: Elapsed`;
          newItemData[k] = { elapsed: true, dataKey: item.dataKey };
        });

        // This stores info about which keys are elapsed, which response, etc. This is used by the payload updater to
        // ensure that the output payload has the right column information.
        setItemData(newItemData);

        const groups = { ...ExportConfig.groups, STUDY: "Study Item Responses" };
        setAllGroups(groups);

        const fieldMappings = { ...ExportConfig.fieldMappings, STUDY: keys };
        setAllMappings(fieldMappings);
      });
    } else {
      delete payload.groupSelections["STUDY"];
    }
  }, [
    payload.study,
    payload.groupSelections,
    lastStudy,
    setAllGroups,
    setAllMappings,
    setItemData,
  ]);

  return (
    <Row>
      <Col>
        <h5>Field Groups</h5>
        <Card style={{ overflowY: "auto", maxHeight: "50vh" }}>
          <ListGroup variant="flush">
            {Object.entries(allGroups)
              .sort(([_k1, v1], [_k2, v2]) => (v1 > v2 ? 1 : -1))
              .map(([k, v]) => {
                const full =
                  Object.keys(allMappings[k] || {}).length ===
                  (payload.groupSelections[k] || []).length;
                const empty = (payload.groupSelections[k] || []).length === 0;
                const icon: IconProp = full ? "check-square" : empty ? "square" : "minus-square";

                return (
                  <div
                    key={v}
                    style={{ cursor: "pointer" }}
                    className={classNames({
                      small: true,
                      "list-group-item": true,
                      active: selectedGroup === k,
                    })}
                    onClick={() => setSelectedGroup(k)}
                  >
                    <Row>
                      <Col xs="auto" style={{ fontSize: "1.2em" }}>
                        <span onClick={() => toggleGroup(k)}>
                          <FontAwesomeIcon icon={["far", icon]} />
                        </span>
                      </Col>
                      <Col>{v}</Col>
                    </Row>
                  </div>
                );
              })}
          </ListGroup>
        </Card>
      </Col>
      {selectedGroup && (
        <Col>
          <h5>
            <Row>
              <Col>Select Fields</Col>
              <Col xs="auto">
                <Button size="sm" onClick={() => toggleGroup(selectedGroup)}>
                  <FontAwesomeIcon icon={["far", "check-square"]} /> Toggle Group
                </Button>
              </Col>
            </Row>
          </h5>

          <Card style={{ overflowY: "auto", maxHeight: "50vh" }}>
            <ListGroup variant="flush">
              {Object.entries(allMappings[selectedGroup] || {})
                .sort()
                .map(([field, desc]) => {
                  const active = (payload.groupSelections[selectedGroup] || []).includes(field);
                  return (
                    <label
                      key={field}
                      className={classNames({
                        small: true,
                        "list-group-item": true,
                        active,
                      })}
                    >
                      <input
                        type="checkbox"
                        name="fields[]"
                        value={field}
                        checked={active}
                        onChange={toggleField}
                      />{" "}
                      {desc && desc !== "" ? desc : field}
                    </label>
                  );
                })}
            </ListGroup>
          </Card>
        </Col>
      )}
    </Row>
  );
}
