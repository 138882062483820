// Clipboard
export enum Action {
  ADD_FLASH_MESSAGE,
  CLEAR_ACCOUNTS,
  CLEAR_FLASH_MESSAGE,
  CLEAR_LINKS,
  CLEAR_REPORTS,
  PICKUP_ACCOUNT,
  PICKUP_LINK,
  PICKUP_REPORT,
  PUTDOWN_ACCOUNT,
  PUTDOWN_LINK,
  PUTDOWN_REPORT
}

export type ClipboardType = "account" | "link" | "report";

export const pickup = (
  type: ClipboardType,
  fields: any
): { type: Action; fields: any } => {
  switch (type) {
    case "account":
      return Object.assign({ type: Action.PICKUP_ACCOUNT }, fields);
    case "link":
      return Object.assign({ type: Action.PICKUP_LINK }, fields);
    case "report":
      return Object.assign({ type: Action.PICKUP_REPORT }, fields);
  }
};

export const putdown = (
  type: ClipboardType,
  id: number
): { type: Action; id: number } => {
  switch (type) {
    case "account":
      return { id, type: Action.PUTDOWN_ACCOUNT };
    case "link":
      return { id, type: Action.PUTDOWN_LINK };
    case "report":
      return { id, type: Action.PUTDOWN_REPORT };
  }
};

export const clearClipboard = (type: ClipboardType): { type: Action } => {
  switch (type) {
    case "account":
      return { type: Action.CLEAR_ACCOUNTS };
    case "link":
      return { type: Action.CLEAR_LINKS };
    case "report":
      return { type: Action.CLEAR_REPORTS };
  }
};
