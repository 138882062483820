import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function TreeButton({
  name,
  path,
  value,
  active,
  activated,
  populate
}: {
  name: string;
  path: string;
  value: any;
  active: boolean;
  activated: boolean;
  populate(path: string, value: "true" | "");
}) {
  const onSetAdd = React.useCallback(() => populate(path, value), [path, value, populate]);
  const onSetRemove = React.useCallback(() => populate(path, ""), [path, populate]);

  return (
    <Row key={path} className="mb-2">
      <Col>{name}</Col>
      <Col xs="auto">
        {active ? (
          <Button variant="success" block disabled={true}>
            <FontAwesomeIcon icon="check" />
          </Button>
        ) : (
          <Button variant={activated ? "success" : "secondary"} block onClick={onSetAdd}>
            <FontAwesomeIcon icon="plus" />
          </Button>
        )}
      </Col>
      <Col xs="auto">
        {active ? (
          <Button variant={activated ? "danger" : "secondary"} block onClick={onSetRemove}>
            <FontAwesomeIcon icon={"minus"} />
          </Button>
        ) : (
          <Button variant="outline-danger" block disabled={true}>
            <FontAwesomeIcon icon={"check"} />
          </Button>
        )}
      </Col>
    </Row>
  );
}

function TreeOptions(props: {
  options: any;
  planned: boolean;
  newTreePath: string;
  newTreeValue: string;
  deleteTreePath: string;
  plan: string;
  presets: {
    name: string;
    path: string;
    value: any;
    active: boolean;
  }[];
}) {
  const [newPath, setNewPath] = React.useState<string>(props.newTreePath);
  const [newValue, setNewValue] = React.useState<string>(props.newTreeValue);
  const [deletePath, setDeletePath] = React.useState<string>(props.deleteTreePath);
  const [expert, setExpert] = React.useState<boolean>(false);
  const [planned, setPlanned] = React.useState<boolean>(props.planned);

  const populate = React.useCallback(
    (k: string, v: any) => {
      setPlanned(false);
      if (v !== "") {
        setNewPath(k);
        setNewValue(v);
        setDeletePath("");
      } else {
        setNewPath("");
        setNewValue("");
        setDeletePath(k);
      }
    },
    [setNewPath, setNewValue, setDeletePath]
  );

  const buttons = props.presets.map((p, i) => (
    <TreeButton key={i} name={p.name} path={p.path} value={p.value || "true"} active={p.active} activated={p.path === newPath || p.path === deletePath} populate={populate} />
  ));

  const onSetNewPath = React.useCallback(
    e => {
      setNewPath(e.currentTarget.value);
      setDeletePath("");
      setPlanned(false);
    },
    [setNewPath, setDeletePath]
  );

  const onSetNewValue = React.useCallback(
    e => {
      setNewValue(e.currentTarget.value);
      setDeletePath("");
      setPlanned(false);
    },
    [setNewValue, setDeletePath]
  );

  const onSetDeletePath = React.useCallback(
    e => {
      setNewValue("");
      setNewPath("");
      setDeletePath(e.currentTarget.value);
      setPlanned(false);
    },
    [setDeletePath, setNewValue, setNewPath]
  );

  let jsonOptions;
  try {
    jsonOptions = JSON.stringify(JSON.parse(props.options), null, 2);
  } catch {
    jsonOptions = "{}";
  }

  return (
    <React.Fragment>
      <Row className="border-top py-3 my-3">
        <Col xs={{ offset: 2, span: 8 }}>{buttons}</Col>
      </Row>
      <Form.Group className="row">
        <Col>
          <Form.Label>Update Path</Form.Label>
          <Form.Control
            type="text"
            name="new_tree_path"
            value={newPath}
            onChange={onSetNewPath}
            autoComplete="none"
          />
        </Col>
        <Col>
          <Form.Label>Update Value</Form.Label>
          <Form.Control
            type="text"
            name="new_tree_value"
            value={newValue}
            onChange={onSetNewValue}
            autoComplete="none"
          />
        </Col>
      </Form.Group>
      <Form.Group className="row">
        <Col>
          <Form.Label>Delete Path</Form.Label>
          <Form.Control
            type="text"
            name="delete_tree_path"
            value={deletePath}
            onChange={onSetDeletePath}
            autoComplete="none"
          />
        </Col>
      </Form.Group>
      <Form.Group className="row">
        <Col>
          <Form.Check id="force" name="force" label="Force" disabled={!planned} />
        </Col>
      </Form.Group>
      <Form.Group className="row">
        <Col>
          <Form.Label>Plan</Form.Label>
          <pre>{props.plan}</pre>
        </Col>
      </Form.Group>
      <Form.Group className="row">
        <Col xs={{ offset: 2, span: 4 }}>
          <Button
            type="submit"
            name="plan"
            block
            disabled={planned || ((!newPath || !newValue) && !deletePath)}
          >
            Plan Update
          </Button>
        </Col>
        <Col xs={4}>
          <Button type="submit" name="update_push" block disabled={!planned}>
            Update & Push
          </Button>
        </Col>
      </Form.Group>
      <Row>
        <Col>
          <a href="#tree_options" onClick={() => setExpert(!expert)}>
            Expert Mode
          </a>
        </Col>
      </Row>
      {expert && (
        <Form.Group id="tree_options" className="row">
          <Col>
            <Form.Label>Tree Options</Form.Label>
            <Card
              style={{ fontFamily: "monospace", whiteSpace: "pre" }}
              className="bg-secondary text-white p-2"
            >
              {jsonOptions}
            </Card>
          </Col>
        </Form.Group>
      )}
    </React.Fragment>
  );
}

TreeOptions.defaultProps = {
  options: "{}",
  planned: false,
  newTreePath: "",
  newTreeValue: "",
  deleteTreePath: "",
  presets: [
    { name: "United States Demographic Bundle", path: "demo/bundles/us" },
    { name: "Canada Demographic Bundle", path: "demo/bundles/ca" },
    { name: "United Kingdom Demographic Bundle", path: "demo/bundles/uk" },
    { name: "Ireland Demographic Bundle", path: "demo/bundles/ie" },
    { name: "Australia Demographic Bundle", path: "demo/bundles/au" },
    { name: "New Zealand Demographic Bundle", path: "demo/bundles/nz" },
    { name: "South Africa Demographic Bundle", path: "demo/bundles/za" },

    { name: "Gender: Ze", path: "gender/Z" },
    { name: "Gender: They", path: "gender/T" },
    { name: "Gender: Prefer not to say", path: "gender/P" },

    { name: "Feature: Download Reports", path: "feature/download_reports" },
    { name: "Feature: Hide scores for free reports", path: "feature/hide_free_scores" },
    { name: "Feature: Export Motivators Ranking", path: "feature/mot_rank" },
    { name: "Feature: Export 8FE Responses", path: "feature/efe_responses" },
    { name: "Feature: Hartman Sanity Checks", path: "feature/sanity_checks" },

    { name: "Auto Operations: Create Gap Report", path: "auto/gap" },

    { name: "Report Style: 2013", path: "print_options/REPORT_STYLE", value: 2013 },
    { name: "Restricted Comp", path: "print_options/RESTRICTEDCOMP", value: '"TRUE"' },
    {
      name: "Driving Forces Graph: Legacy Labels",
      path: "print_options/DFGRAPHLEGACYLABELS",
      value: '"TRUE"',
    },
    { name: "Job Talent Comparison Wheel", path: "print_options/JTCWHEEL", value: true },
    {
      name: "Working From Home: Your Style Off",
      path: "print_options/WFHYOURSTYLEOFF",
      value: '"TRUE"',
    },
    { name: "Rotate DISC Wheels 90° Counterclockwise", path: "print_options/ROTATE_DISC_WHEEL_CC", value: true },
  ],
};
export default TreeOptions;
