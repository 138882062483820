import * as React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import Alert from "react-bootstrap/Alert";
import { defaultClient as axios } from "@app/support/api_client";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";

import { toastErrors } from "../Helpers/RestDataProvider";
import { ClipboardType } from "../Store/actions";
import { ClipboardTargets } from "./RecordClipboard";

import "react-toastify/dist/ReactToastify.css";
import styles from "./style.module.scss";

const pluralize = (str, count) => {
  return count === 1 ? str.replace(/s$/, "") : str;
};

type ClipboardPayload = {
  id: number;
};

interface ResourcePaneProps {
  putdown(type: ClipboardType, id: number): any;
  clearClipboard(type: ClipboardType);
  data: ClipboardPayload[];
  active: boolean;
  targets: ClipboardTargets;
  columns: any;
}

type ResourcePaneState = {
  data: any[];
  generalError: string | null;
  errors: { [key: string]: string };
  sort: string | null;
  sortDir: number;
  lastOp: "move" | "trash" | null;
  confirmTrash: boolean;
};

// This is a base class that handles most of the shared resource pane functionality. Individual panes subclass it.
abstract class ResourcePane extends React.PureComponent<ResourcePaneProps, ResourcePaneState> {
  controller: string;
  abstract clipboardType: ClipboardType;

  state: ResourcePaneState = {
    data: [],
    generalError: null,
    errors: {},
    sort: null,
    sortDir: 1,
    lastOp: null,
    confirmTrash: false,
  };

  url(s = "picked") {
    return `/${this.controller}/${s}.json`;
  }

  setResponseErrors(data: any) {
    let moved = 0;
    const stateUpdates = { errors: {}, generalError: data.error };
    const { putdown } = this.props;
    if (data.results) {
      Object.entries(data.results).forEach(([id, v]) => {
        if (typeof v == "object" && v && v["status"] === "error") {
          stateUpdates.errors[id] = v["message"];
        } else {
          moved++;
          putdown(this.clipboardType, parseInt(id));
        }
      });
    }
    this.setState(stateUpdates);
    return moved;
  }

  handleMoveResponse = (res) => {
    const success = this.setResponseErrors(res.data);
    this.setState({ lastOp: "move" });
    if (success > 0) {
      const noun = pluralize(this.controller, success);
      toast.success(`Moved ${success} ${noun}`);
    }
  };

  handleCopyResponse = (res) => {
    const success = this.setResponseErrors(res.data);
    this.setState({ lastOp: "move" });
    if (success > 0) {
      const noun = pluralize(this.controller, success);
      toast.success(`Copied ${success} ${noun}. Please refresh.`);
    }
  };

  handleTrashedResponse = (res) => {
    const success = this.setResponseErrors(res.data);
    this.setState({ lastOp: "trash" });
    if (success > 0) {
      const noun = pluralize(this.controller, success);
      toast.success(`Trashed ${success} ${noun}`);
    }
  };

  movePicked(ids: number[] = this.props.data.map((r) => r.id), extraParams = {}) {
    axios
      .put(this.url(), Object.assign(Object.assign({}, extraParams, this.moveParams(ids))))
      .then(this.handleMoveResponse)
      .catch(toastErrors);
  }

  copyPicked(ids: number[] = this.props.data.map((r) => r.id), extraParams = {}) {
    axios
      .put(
        this.url("paste_copy"),
        Object.assign(Object.assign({}, extraParams, this.copyParams(ids)))
      )
      .then(this.handleCopyResponse)
      .catch(toastErrors);
  }

  trashPicked(ids: number[] = this.props.data.map((r) => r.id)) {
    axios
      .delete(this.url(), { data: this.trashParams(ids) })
      .then(this.handleTrashedResponse)
      .catch(toastErrors);
  }

  sortBy(sort) {
    if (this.state.sort === sort) {
      this.setState({ sortDir: this.state.sortDir === 1 ? -1 : 1 });
    } else {
      this.setState({ sort });
    }
  }

  canMove() {
    return false;
  }
  canCopy() {
    return false;
  }
  canTrash() {
    return true;
  }

  abstract copyParams(ids: number[]);
  abstract moveParams(ids: number[]);
  abstract trashParams(ids: number[]);

  renderHeaders() {
    const { columns } = this.props;

    const sortIcon = this.state.sortDir === 1 ? "caret-up" : "caret-down";
    const headers = columns.map((c) => (
      <Col
        style={{ cursor: "pointer", userSelect: "none" }}
        onClick={() => this.sortBy(c.key)}
        key={c.key}
        xs={c.width}
      >
        {c.title} {this.state.sort === c.key && <FontAwesomeIcon icon={sortIcon} />}
      </Col>
    ));

    return (
      <ListGroup.Item className="bg-primary text-white font-weight-bold">
        <Row>
          {headers}
          <Col xs={1} className="text-right">
            &nbsp;
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }

  renderRowButtons(row): JSX.Element | null | false {
    return null;
  }

  renderField(row, column) {
    return row[column.key];
  }

  renderRow(r) {
    const { putdown, columns } = this.props;

    return (
      <ListGroup.Item key={r.id} className={styles.stripedGroup}>
        <Row>
          {columns.map((c) => (
            <Col xs={c.width} key={c.key}>
              {this.renderField(r, c)}
            </Col>
          ))}
          <Col xs={1} className="text-right">
            <span className={styles.clear} onClick={() => putdown(this.clipboardType, r.id)}>
              <FontAwesomeIcon icon="times" />
            </span>
          </Col>
        </Row>
        {this.state.errors[r.id] && (
          <Row>
            <Col className="small text-secondary">
              <FontAwesomeIcon icon="exclamation-triangle" /> {this.state.errors[r.id]}
            </Col>
          </Row>
        )}
        {this.renderRowButtons(r)}
      </ListGroup.Item>
    );
  }

  renderButtons() {
    const { clearClipboard } = this.props;
    return (
      <div className={`my-3 ${styles.squareButtons}`}>
        {this.canMove() && (
          <Button type="submit" variant="success" onClick={() => this.movePicked()}>
            <FontAwesomeIcon icon="arrow-right" /> Move Here
          </Button>
        )}
        {this.canCopy() && (
          <Button type="submit" variant="warning" onClick={() => this.copyPicked()}>
            <FontAwesomeIcon icon={["far", "copy"]} /> Copy Here
          </Button>
        )}
        <Button variant="secondary" onClick={() => clearClipboard(this.clipboardType)}>
          <FontAwesomeIcon icon="eraser" /> Clear
        </Button>
        {this.canTrash() && (
          <Button variant="danger" onClick={() => this.trashPicked()}>
            <FontAwesomeIcon icon="trash" /> Trash
          </Button>
        )}
      </div>
    );
  }

  render() {
    const { active, data } = this.props;

    const sorted = data.concat().sort((a, b) => {
      const { sort } = this.state;
      if (sort === null) {
        return 0;
      } else {
        if (a[sort] === b[sort]) {
          return 0;
        } else if (a[sort] > b[sort]) {
          return this.state.sortDir;
        } else {
          return -this.state.sortDir;
        }
      }
    });

    const table = (
      <ListGroup className={styles.listTable} variant="flush">
        {this.renderHeaders()}
        {sorted.map((r) => this.renderRow(r))}
      </ListGroup>
    );

    return (
      <div style={{ display: active ? "block" : "none" }}>
        <div className={styles.inner}>{table}</div>
        {this.renderButtons()}
        {this.state.generalError && (
          <Alert variant="warning" className="small">
            {this.state.generalError}
          </Alert>
        )}
      </div>
    );
  }
}

export default ResourcePane;
