export function uniq(array: any[]) {
  const set = new Set(array);
  return Array.from(set.values());
}

export function flatten(array) {
  return array.reduce((a, b) => a.concat(b), []);
}

export function flatUniq(array) {
  return uniq(flatten(array));
}

export const sortBy = key => {
  return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

export default {
  uniq,
  flatten,
  flatUniq,
  sortBy
};
