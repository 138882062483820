import * as React from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TQuestion } from ".";
import { TLanguageType } from ".";
import { Formik, FieldArray, Field } from "formik";
import { createSessionStore } from "../../support/sessionStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LabeledField from "../Forms/Widgets/LabeledField";
import LanguageSelector from "./LanguageSelector";
import Question from "./Question";
import { defaultClient as axios } from "@app/support/api_client";

export const LanguagesContext = React.createContext({
  selectedLanguages: [] as TLanguageType[],
});

const store = createSessionStore("demographicEditor");

interface DemographicComponentState {
  id?: string;
  name: string;
  questions: TQuestion[];
  selectedLanguages: TLanguageType[];
  errors: any;
  activeQuestion: number;
  saved: boolean;
  rehydrated: boolean;
}

interface DemographicComponentProps {
  id?: string;
  name?: string;
  accountId?: number;
  questions: TQuestion[];
  languages: TLanguageType[];
  selectedLanguages: TLanguageType[];
}

const newQuestion = (): TQuestion => ({
  key: "",
  label: "",
  kind: "short",
  required: false,
  prompts: {},
  items: [],
});

export class DemographicComponent extends React.PureComponent<
  DemographicComponentProps,
  DemographicComponentState
> {
  static defaultProps: DemographicComponentProps;

  constructor(props: DemographicComponentProps) {
    super(props);
    const hydratedState = Object.assign(store.read(), { rehydrated: true });

    const proposedState = {
      id: props.id,
      name: props.name,
      questions: props.questions,
      selectedLanguages: props.selectedLanguages,
      activeQuestion: 0,
      saved: false,
      rehydrated: false,
    };
    const selectedState = hydratedState.id === proposedState.id ? hydratedState : proposedState;
    if (!selectedState.id && selectedState.questions.length === 0) {
      selectedState.questions.push(newQuestion());
    }
    if (!selectedState.id && selectedState.selectedLanguages.length === 0) {
      selectedState.selectedLanguages.push(props.languages.find((l) => l.id === 0));
    }
    this.state = selectedState;
  }

  setQuestion(id: number, question: TQuestion) {
    const questions = [...this.state.questions];
    questions[id] = question;
    this.setState({ questions });
  }

  updateQuestion = (id: number, q: TQuestion) => {
    const questions = Array.from(this.state.questions);
    questions[id] = q;
    this.setState({ questions });
  };

  setLanguages(selectedLanguages: TLanguageType[]): void {
    this.setState({ selectedLanguages });
  }

  onNameChange = (e) => {
    this.setState({ name: e.currentTarget.value });
  };

  // Eventually this would talk to a GQL mutation
  save = (values) => {
    const res = window.confirm("Save demographics set? Keys will become uneditable.");
    if (!res) {
      return;
    }
    const method = this.state.id === null ? "post" : "put";
    this.setState({ errors: null }, () => {
      axios[method](`/accounts/${this.props.accountId}/demo_sets/${this.state.id || ""}`, {
        demoSet: values,
        selectedLanguages: this.state.selectedLanguages.map((l) => l.id),
        format: "json",
      })
        .catch((err) => {
          this.setState({ errors: err.response.data.errors, saved: false });
        })
        .then((res: any) => {
          if (!this.state.id) {
            window.location.href = res.data.href;
          }
          const demoSet = res.data.demoSet;
          this.setState({
            id: demoSet.id,
            // Updating the state with these causes dirty status to reset
            name: demoSet.name,
            questions: demoSet.questions,
            saved: true,
            rehydrated: false,
          });
          store.write({});
        });
    });
  };

  toggleCollapsed = (index) => {
    if (index === this.state.activeQuestion) {
      this.setState({ activeQuestion: -1 });
    } else {
      this.setState({ activeQuestion: index });
    }
  };

  addQuestion = (push, values: any[]) => {
    push(newQuestion());
    this.setState({ activeQuestion: values.length });
  };

  componentDidUpdate() {
    const { id, selectedLanguages, activeQuestion } = this.state;
    store.write(Object.assign(store.read(), { id, selectedLanguages, activeQuestion }));
  }

  validate = (values) => {
    const { name, questions } = values;
    store.write(Object.assign(store.read(), { name, questions }));

    const errors = {};
    if ((values.name || "") === "") {
      errors["name"] = "cannot be blank";
    }

    values.questions.forEach((q: TQuestion, qi: number) => {
      if (q.label === "") errors[`questions.${qi}.label`] = "cannot be blank";
      if (q.key === "") errors[`questions.${qi}.key`] = "cannot be blank";

      this.state.selectedLanguages.forEach((l) => {
        const lkey = `lang${l.id}`;
        if ((q.prompts[lkey] || "") === "") {
          errors[`questions.${qi}.prompts.${lkey}`] = "cannot be blank";
        }

        const values = q.items.map((i) => i.value);
        const translations = q.items.map((i) => i.translations[lkey]);
        q.items.forEach((item, index) => {
          if ((item.value || "") === "") {
            errors[`questions.${qi}.items.${index}.value`] = "cannot be blank";
          } else if (values.filter((v) => v === item.value).length > 1) {
            errors[`questions.${qi}.items.${index}.value`] = "must be unique";
          }

          if ((item.translations[lkey] || "") === "") {
            errors[`questions.${qi}.items.${index}.translations.${lkey}`] = "cannot be blank";
          } else if (translations.filter((v) => v === item.translations[lkey]).length > 1) {
            errors[`questions.${qi}.items.${index}.translations.${lkey}`] = "must be unique";
          }
        });
      });
    });

    return errors;
  };

  swap = (s, a: number, b: number) => {
    s(a, b);
    if (this.state.activeQuestion === a) {
      this.setState({ activeQuestion: b });
    }
  };

  render() {
    const initialValues = {
      name: this.state.name,
      questions: this.state.questions,
    };
    return (
      <React.Fragment>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={this.save}
          validate={this.validate}
        >
          {({ values, setFieldValue, handleSubmit, errors, dirty }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Field label="Demographic Set Name" name="name" component={LabeledField} />
                </Col>
              </Row>
              <FieldArray
                name="questions"
                render={({ swap, push, remove }) => (
                  <React.Fragment>
                    <Row>
                      <Col>
                        <LanguagesContext.Provider value={this.state}>
                          {values.questions.map((question, index) => (
                            <Row key={index}>
                              <Col>
                                <Question
                                  collapsed={
                                    this.state.activeQuestion !== index &&
                                    Object.keys(errors).filter((k) =>
                                      k.match(`questions.${index}.`)
                                    ).length == 0
                                  }
                                  name={`questions.${index}`}
                                  index={index}
                                  question={question}
                                  total={values.questions.length}
                                  move={(a, b) => this.swap(swap, a, b)}
                                  remove={remove}
                                  setFieldValue={setFieldValue}
                                  toggleCollapsed={this.toggleCollapsed}
                                />
                              </Col>
                            </Row>
                          ))}
                        </LanguagesContext.Provider>
                      </Col>
                    </Row>
                    {this.state.errors && (
                      <div className="alert-danger p-3">
                        <h5>Error while saving:</h5>
                        <ul>
                          {this.state.errors.map((e, i) => (
                            <li key={i}>{e}</li>
                          ))}
                        </ul>
                      </div>
                    )}

                    <Row className="mt-3 mb-5">
                      <Col xs="auto">
                        <Button onClick={() => this.addQuestion(push, values.questions)}>
                          <FontAwesomeIcon icon="plus" /> Add A Question
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          type="submit"
                          variant={dirty || this.state.rehydrated ? "success" : "secondary"}
                          disabled={!dirty && !this.state.rehydrated}
                        >
                          <FontAwesomeIcon icon={this.state.saved && !dirty ? "check" : "save"} />{" "}
                          Save Changes
                        </Button>
                      </Col>
                      <Col xs="auto">
                        <LanguageSelector
                          languages={this.props.languages}
                          selectedLanguages={this.state.selectedLanguages}
                          setLanguages={(langs) => this.setLanguages(langs)}
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              />
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

DemographicComponent.defaultProps = {
  selectedLanguages: [{ id: 0, name: "English (US)" }],
  languages: [],
  questions: [
    {
      key: "select",
      label: "",
      kind: "select",
      required: false,
      prompts: {},
      items: [{ value: "", translations: { 0: "" } }],
    },
  ],
};
