import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, FieldArray } from "formik";
import * as React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { TQuestionItem } from ".";
import LabeledField from "../Forms/Widgets/LabeledField";

interface Props {
  name: string;
  languageId: number;
  items: TQuestionItem[];
  canAdd: boolean;
}

class MultipleChoiceEditor extends React.PureComponent<Props> {
  render() {
    const { name, items, canAdd, languageId } = this.props;

    return (
      <FieldArray
        name={name}
        render={({ push, remove }) => (
          <React.Fragment>
            <div className="list-group">
              <div className="list-group-item">
                <Row>
                  <Col xs={6}>
                    <Form.Label className="font-weight-bold">Label</Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Label className="font-weight-bold">Value</Form.Label>
                  </Col>
                </Row>
              </div>

              {items.map((item, index) => {
                // const label = item.translations[this.props.languageId];

                return (
                  <div className="list-group-item" key={index} style={{ paddingBottom: 0 }}>
                    <Row>
                      <Col xs={6}>
                        <Field
                          component={LabeledField}
                          name={`${name}.${index}.translations.lang${languageId}`}
                          size="sm"
                        />
                      </Col>
                      <Col xs={3}>
                        <Field
                          component={LabeledField}
                          name={`${name}.${index}.value`}
                          size="sm"
                          readOnly={item.id !== undefined}
                        />
                      </Col>
                      <Col xs={1}>
                        {canAdd && (
                          <Button
                            variant="outline-light"
                            className="text-primary"
                            size="sm"
                            onClick={() => remove(index)}
                          >
                            <FontAwesomeIcon icon="minus" />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                );
              })}

              {canAdd && (
                <div className="list-group-item">
                  <Row>
                    <Col xs={9} className="text-right">
                      <Button
                        size="sm"
                        variant="outline-secondary"
                        onClick={() => push({ value: "", translations: {}, order: items.length })}
                      >
                        <FontAwesomeIcon icon="plus" /> Add New
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      />
    );
  }
}

export default MultipleChoiceEditor;
