import * as React from "react";
import { toast } from "react-toastify";
import { useDebounce } from "../../support/useDebounce";
import Ajv from "ajv";
import { defaultClient as axios } from "@app/support/api_client";

export function toastErrors(err) {
  const { response } = err;
  if (!response || !response.data) {
    console.log(err);
    return;
  }
  if (response?.data?.error) {
    toast.error(`"Request failed: ${response.data.error}`);
  } else if (response?.data?.errors) {
    toast.error(
      <div>
        <strong>Request failed:</strong>
        <ul>
          {response.data.errors.map((e, i) => (
            <li key={i}>{e}</li>
          ))}
        </ul>
      </div>
    );
  } else {
    toast.error(`${response.status} - ${response.statusText}`);
  }
}

const validator = new Ajv();
export default function RestDataProvider<T>(props: {
  url: string;
  params?: any;
  method?: "get" | "post";
  defaultValue: T[];
  path?: string;
  children({
    data,
    refresh,
    active,
    running,
  }: {
    data: T[];
    refresh();
    active: boolean;
    running: boolean;
  });
  active?: boolean;
  delayMs?: number;
  schema?: any;
  map?(data: any[]): T[];
}) {
  const { url, params, children, defaultValue, path, active, delayMs, map, schema } = props;
  const [data, setData] = React.useState<T[]>(defaultValue);
  const [refreshedAt, setRefreshedAt] = React.useState(0);
  const [running, setRunning] = React.useState(false);
  const refresh = React.useCallback(() => {
    setRefreshedAt(new Date().getTime());
  }, []);

  const paramsAsJson = JSON.stringify(params);

  React.useEffect(() => {
    if (active) {
      setRunning(true);
    }
  }, [active, setRunning, paramsAsJson]);

  const debouncedSearchParams = useDebounce(params, delayMs || 0);
  const debouncedSearchParamsAsJson = JSON.stringify(debouncedSearchParams);
  React.useEffect(() => {
    if (active) {
      setRunning(true);
      let promise;
      if (props.method === "get") {
        promise = axios.get(url, { params: debouncedSearchParams });
      } else if (props.method === "post") {
        promise = axios.post(url, debouncedSearchParams);
      }
      promise
        .then((res) => {
          setRunning(false);
          const cData = res.data;
          if (schema) {
            const result = validator.validate(schema, cData);
            if (result === undefined) {
              toast.error("Failed schema validation");
              return;
            }
          }
          const payload = path ? cData[path] : cData;
          const transformedPayload = map ? map(payload) : payload;
          setData(transformedPayload);
        })
        .catch(toastErrors);
    }
  }, [
    url,
    debouncedSearchParamsAsJson,
    path,
    refreshedAt,
    active,
    props.method,
    debouncedSearchParams,
    schema,
    map,
  ]);
  return children({ data, refresh, active: active || false, running });
}

RestDataProvider.defaultProps = {
  method: "get",
};
