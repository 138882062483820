import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import classnames from "classnames";
import { TQuestion } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LabeledField from "../Forms/Widgets/LabeledField";
import LabeledCheckbox from "../Forms/Widgets/LabeledCheckbox";
import { Field } from "formik";
import PositionHandle from "./PositionHandle";
import { LanguagesContext } from "./DemographicsEditor";
import LocalizedQuestion from "./LocalizedQuestion";

export default class Question extends React.PureComponent<{
  collapsed?: boolean;
  name: string;
  question: TQuestion;
  index: number;
  total: number;
  move(myIndex: number, delta: number): void;
  remove(index: number): void;
  setFieldValue(field: string, value: any): void;
  toggleCollapsed(index: number): void;
}> {
  onKindUpdate = (e) => {
    const kind = e.currentTarget.value;

    const question = this.props.question;
    const prevKind = question.kind;

    // If we're moving from a type with items to a type without items, null out the item values and labels
    if (
      (prevKind === "yes/no" || prevKind === "select" || prevKind === "select-all") &&
      !(kind === "yes/no" || kind === "select" || kind === "select-all")
    ) {
      question.items.forEach((item) => {
        item.value = "";
        item.translations = {};
      });
    }
    switch (kind) {
      case "yes/no":
        question.items = [
          { value: "yes", translations: { lang0: "Yes" } },
          { value: "no", translations: { lang0: "No" } },
        ];
        break;
    }
    this.props.setFieldValue(e.currentTarget.name, kind);
  };

  remove = (e) => {
    let confirmed = true;
    if (this.props.question.id) {
      confirmed = confirm(
        "Are you sure you want to delete this question? Existing respondent answers will be orphaned."
      );
    }
    if (confirmed) {
      this.props.remove(this.props.index);
    }
    e.stopPropagation();
    e.preventDefault();
  };

  toggleCollapsed = () => this.props.toggleCollapsed(this.props.index);

  render() {
    const { collapsed, name, question, index } = this.props;
    const { id, kind, required, label } = question;
    const frozen = id !== null && id !== undefined;

    return (
      <Card
        className={classnames({ "mb-4": !collapsed })}
        style={{ boxShadow: "rgba(0, 0, 0, 0.3) 5px 8px 10px" }}
      >
        <Card.Title
          className="bg-primary text-white"
          style={{ marginBottom: collapsed ? "0px" : "12px" }}
        >
          <Row>
            <Col onClick={this.toggleCollapsed} style={{ cursor: "pointer" }} className="py-2 px-3">
              <span style={{ marginRight: "0.5em", marginLeft: "0.5em" }}>
                <FontAwesomeIcon icon={collapsed ? "caret-right" : "caret-down"} />
              </span>
              {index + 1}.{label || "(No title)"} {required && "*"}
              <Button className="small ml-3 btn-secondary" onClick={this.remove}>
                Remove <FontAwesomeIcon icon="times" />
              </Button>
            </Col>
            <Col xs="auto" className="py-2 px-3">
              <PositionHandle
                index={this.props.index}
                total={this.props.total}
                move={this.props.move}
              />
            </Col>
          </Row>
        </Card.Title>
        {!collapsed && (
          <Card.Body>
            <Row>
              <Col>
                <div className="list-group list-group-flush">
                  <LanguagesContext.Consumer>
                    {({ selectedLanguages }) => (
                      <React.Fragment>
                        {selectedLanguages.map((l) => (
                          <div className="list-group-item" key={l.id}>
                            <LocalizedQuestion
                              name={`${this.props.name}`}
                              kind={kind}
                              language={l}
                              items={question.items}
                            />
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </LanguagesContext.Consumer>
                </div>
              </Col>

              <Col xs={{ span: 3 }} className="border-left">
                <Field component={LabeledField} name={`${name}.label`} label="Question Label" />

                <Form.Group>
                  <Form.Label className="font-weight-bold">Question Type</Form.Label>
                  <Field
                    component="select"
                    name={`${name}.kind`}
                    className="form-control form-control-sm"
                    onChange={this.onKindUpdate}
                    disabled={frozen}
                  >
                    <option value="select">Multiple Choice - One</option>
                    <option value="select-all">Multiple Choice - Several</option>
                    <option value="yes/no">Yes/No</option>
                    <option value="short">Short Response</option>
                    <option value="long">Long Response</option>
                    <option value="select-all">Select All</option>
                    <option value="intro">Introduction Text</option>
                  </Field>
                </Form.Group>
                <Field
                  component={LabeledField}
                  label="Data Key"
                  name={`${name}.key`}
                  readOnly={frozen}
                />
                <Field
                  component={LabeledCheckbox}
                  label="Required?"
                  name={`${name}.required`}
                  custom
                />
              </Col>
            </Row>
          </Card.Body>
        )}
      </Card>
    );
  }
}
