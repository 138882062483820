import * as React from "react";
import { useEffect } from "react";
import styles from "./style.module.scss";
import { Searchable, Scrollable } from "../../Helpers";
import RestDataProvider from "../../Helpers/RestDataProvider";
import AutoVisibleListItem from "../../Helpers/AutoVisibleListItem";
import classNames from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export function DropdownList<T>(props: {
  valueKey: string;
  labelKey: string;
  data: T[];
  highlighted: number | null;
  className?: string;
  onItemSelected?(index: number);
}) {
  const { valueKey, labelKey, className, data, highlighted, onItemSelected } = props;
  return (
    <div className="list-group list-group-flush">
      <Scrollable maxHeight="35vh" className={className}>
        {data.map((opt, index) => (
          <AutoVisibleListItem
            index={index}
            className={styles.selectListItem}
            key={opt[valueKey]}
            active={highlighted === index}
            value={opt[valueKey]}
            label={opt[labelKey]}
            onItemSelected={onItemSelected}
          />
        ))}
      </Scrollable>
    </div>
  );
}

export interface Props<T> {
  // Required
  url: string;
  valueKey: string;
  labelKey: string;

  // Optional
  dataPath?: string;
  includeBlank?: boolean;
  includeSearch?: boolean;
  delayMs?: number;
  direction?: "up" | "down";
  eager?: boolean;
  icon?: IconProp | null;
  iconPosition?: "before" | "after";
  isInvalid?: boolean;
  name?: string;
  params?: { [key: string]: any };
  placeholder?: string;
  schema?: any;
  queryParamName?: string;
  disabled?: boolean;

  // Callbacks
  filter?(record: T): boolean;
  map?(data: any[]): T[];
  onBlur?(e: any): void;
}

export interface SingleValueProps<T> {
  value?: T | null;
  onItemSelect?(value: T): void;
}

function TypeaheadSearchField<T>(props: Props<T> & SingleValueProps<T>) {
  return (
    <React.Fragment>
      <Searchable
        icon={props.icon}
        iconPosition={props.iconPosition || "after"}
        disabled={props.disabled}
        value={(props.value && props.value[props.labelKey]) || ""}
        blurValue={(props.value && props.value[props.labelKey]) || ""}
        placeholder={props.placeholder}
        includeBlank={props.includeBlank}
        selectOnBlur
      >
        {(searchable) => {
          const allParams = Object.assign({}, props.params || {}, {
            [props.queryParamName || "search"]: searchable.search,
          });

          return (
            <React.Fragment>
              {props.name && (
                <input
                  type="hidden"
                  name={props.name}
                  value={(props.value && props.value[props.valueKey]) || ""}
                />
              )}
              <RestDataProvider
                url={props.url}
                params={allParams}
                path={props.dataPath}
                map={props.map}
                defaultValue={[] as T[]}
                active={props.eager || (searchable.focused && searchable.search !== "")}
                delayMs={props.delayMs}
                schema={props.schema}
              >
                {(result) => <TypeableInner {...result} {...searchable} {...props} />}
              </RestDataProvider>
            </React.Fragment>
          );
        }}
      </Searchable>
    </React.Fragment>
  );
}

function TypeableInner<T>({
  data,
  search,
  active,
  highlighted,
  focused,
  setFocused,
  selected,
  ...props
}: Props<T> &
  SingleValueProps<T> & {
    search: string;
    active: boolean;
    highlighted: number | null;
    data: T[];
    focused: boolean;
    selected: number | null;
    setFocused(focused: boolean);
  }) {
  let filteredData = props.filter ? data.filter(props.filter) : data;
  if (props.includeBlank) {
    filteredData = [
      { [props.valueKey]: "", [props.labelKey]: "\u00A0" } as unknown as T,
      ...filteredData,
    ];
  }
  if (props.includeSearch) {
    filteredData = [
      {
        [props.valueKey]: search,
        [props.labelKey]: `${search}`,
      } as unknown as T,
      ...filteredData,
    ];
  }
  const highlightedIndex =
    highlighted !== null
      ? ((highlighted % filteredData.length) + filteredData.length) % filteredData.length
      : null;
  const onItemSelected = React.useCallback(
    (index) => {
      if (index !== null) {
        const nextValue = filteredData[index] || undefined;
        setFocused(false);
        nextValue && props.onItemSelect && props.onItemSelect(nextValue);
      }
    },
    [filteredData, setFocused, props]
  );
  useEffect(() => onItemSelected(selected), [onItemSelected, selected]);
  if (!(focused && active)) return null;

  return (
    <DropdownList
      className={classNames(styles.selectList, {
        [styles.up]: props.direction === "up",
      })}
      data={filteredData}
      valueKey={props.valueKey || ""}
      labelKey={props.labelKey || ""}
      highlighted={highlightedIndex}
      onItemSelected={onItemSelected}
    />
  );
}

TypeaheadSearchField.defaultProps = {
  icon: "search",
  iconPosition: "before",
  labelKey: "name",
  valueKey: "id",
  includeBlank: false,
};

export default TypeaheadSearchField;
