import axios, { AxiosRequestConfig } from "axios";
import applyCaseMiddleware from "axios-case-converter";
import qs from "qs";

function construct(config?: AxiosRequestConfig) {
  const csrfToken = (document.querySelector("meta[name=csrf-token]") as HTMLMetaElement | undefined)
    ?.content;

  const client = applyCaseMiddleware(
    axios.create({
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "brackets" }),
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      ...config,
    }),
    {
      ignoreHeaders: true,
    }
  );
  return client;
}

export const defaultClient = construct();
export default construct;
