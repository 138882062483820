import React from "react";
import ReportviewSelector from "../ReportviewSelector";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

function AddReportviews(props) {
  const confirm = "Checked reportviews will be removed. Continue?";
  const action = "Remove Reportviews";
  return (
    <>
      <input type="hidden" name="mode" value="remove" />
      <ReportviewSelector {...props} />
      <Row className="align-items-center my-3">
        <Col className="text-center" xs="auto">
          <Button
            type="submit"
            variant="primary"
            size="lg"
            name="child"
            onClick={e => this.confirm(e, confirm)}
          >
            {action}
          </Button>
        </Col>
        <Col>
          <Row>
            <Col>
              <label>
                <input type="checkbox" name="rv_remove_down" /> Remove reports
                from descendant accounts?
              </label>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default AddReportviews;
