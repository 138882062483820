import * as React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Searchable } from "../Helpers";
import { AutoVisible } from "../Helpers/AutoVisibleListItem";

type Reportview = {
  id: number;
  name: string;
  description: string;
  reportview_group_id: string;
};

function Item({
  id,
  name,
  description,
  checked,
  onClick,
}: {
  id: number;
  name: string;
  description: string;
  checked: boolean;
  onClick(e: React.MouseEvent);
}) {
  const icon = ["far", checked ? "check-square" : "square"];

  return (
    <ListGroup.Item onClick={onClick} style={{ cursor: "pointer" }}>
      <FontAwesomeIcon icon={icon as IconProp} />{" "}
      <span className="text-secondary small mx-2">{id}</span>
      <b>{name}</b> {description !== "" && description}
    </ListGroup.Item>
  );
}

function Group(props: {
  id: number;
  name: string;
  description: string;
  onClick(index: number): void;
  active: boolean;
}) {
  const { id, name, description, onClick, active } = props;
  const [main, sub] = name.split("(", 2);
  const handleOnClick = React.useCallback(() => {
    onClick && onClick(id);
  }, [onClick, id]);
  return (
    <ListGroup.Item
      className="small"
      onClick={handleOnClick}
      style={{ cursor: "pointer" }}
      active={active}
    >
      <b>{main}</b> {description}
      {sub && <div className="small">{sub.replace(/\)$/, "")}</div>}
    </ListGroup.Item>
  );
}

const filterReportviews = (reportviews: any[], group: any, filter: RegExp): any[] => {
  const rvs = reportviews.filter((rv) => rv.reportview_group_id === group);
  return rvs.filter((r) => {
    return (
      (r.id as number).toString().match(filter) !== null ||
      r.name.match(filter) !== null ||
      (r.description || "").match(filter) !== null ||
      (r.product_code || "").match(filter) !== null ||
      (r.short_name || "").match(filter) !== null
    );
  });
};

const selectedReportviewIDs = (all, reportviews, custom = false): number[] => {
  if (custom) {
    return all.filter(
      (i) => reportviews.find((r) => r.id == i && r.reportview_group_id === "custom") !== undefined
    );
  } else {
    return all.filter(
      (i) => reportviews.find((r) => r.id == i && r.reportview_group_id !== "custom") !== undefined
    );
  }
};

export default function ReportviewSelector(props: {
  reportviews: Reportview[];
  groups: any[];
  confirm?: string;
  action: string;
}) {
  const [selectedItems, setSelectedItems] = React.useState<number[]>([]);

  function toggleItem(item) {
    let mSelectedItems = [...selectedItems];
    const isSelected = mSelectedItems.find((i) => i === item);
    if (isSelected) {
      mSelectedItems = mSelectedItems.filter((i) => i !== item);
    } else {
      mSelectedItems.push(item);
    }
    setSelectedItems(mSelectedItems);
  }

  return (
    <div>
      <input
        type="hidden"
        name="arvs"
        value={selectedReportviewIDs(selectedItems, props.reportviews, false).join(",")}
      />
      <input
        type="hidden"
        name="custom_arvs"
        value={selectedReportviewIDs(selectedItems, props.reportviews, true).join(",")}
      />
      <Searchable placeholder="Filter Reportviews">
        {({ searchRegexp, highlighted, setHighlightedIndex }) => {
          const sortedGroups = props.groups
            .filter((g) => filterReportviews(props.reportviews, g.id, searchRegexp).length > 0)
            .sort((a, b) => (a.group_order < b.group_order ? -1 : 1));

          if (highlighted === null && sortedGroups.length > 0) {
            setHighlightedIndex(0);
          }

          const onSetSelectedGroup = (id) => {
            const index = sortedGroups.findIndex((g) => g.id === id);
            setHighlightedIndex(index);
          };

          const highlightedIndex =
            highlighted !== undefined &&
            highlighted !== null &&
            ((highlighted % sortedGroups.length) + sortedGroups.length) % sortedGroups.length;

          const activeGroup =
            (highlightedIndex || highlightedIndex === 0) &&
            sortedGroups[highlightedIndex] &&
            sortedGroups[highlightedIndex].id;

          return (
            <React.Fragment>
              <Row noGutters>
                <Col
                  xs={4}
                  className="border-right"
                  style={{ maxHeight: "40vh", overflowY: "auto" }}
                >
                  <ListGroup variant="flush">
                    {sortedGroups.map((g) => (
                      <AutoVisible active={g.id === activeGroup} key={g.id}>
                        <Group
                          id={g.id}
                          onClick={onSetSelectedGroup}
                          description={g.description}
                          active={g.id === activeGroup}
                          name={g.name}
                        />
                      </AutoVisible>
                    ))}
                  </ListGroup>
                </Col>
                <Col style={{ maxHeight: "40vh", overflowY: "auto" }}>
                  <ListGroup variant="flush">
                    {filterReportviews(props.reportviews, activeGroup, searchRegexp).map((i) => (
                      <Item
                        key={i.id}
                        {...i}
                        checked={selectedItems.find((item) => item === i.id)}
                        onClick={() => toggleItem(i.id)}
                      />
                    ))}
                  </ListGroup>
                </Col>
              </Row>
              {selectedItems.length > 0 && (
                <div>
                  <Row>
                    <Col style={{ maxHeight: "25em", overflowY: "auto" }}>
                      <h3 className="p-2 mb-0 mt-3 bg-primary text-white">Selected Reportviews</h3>
                      <ListGroup variant="flush">
                        {props.reportviews
                          .filter((r) => selectedItems.indexOf(r.id) !== -1)
                          .map((i) => (
                            <Item
                              key={i.id}
                              {...i}
                              checked={selectedItems.find((item) => item === i.id) !== undefined}
                              onClick={() => toggleItem(i.id)}
                            />
                          ))}
                      </ListGroup>
                    </Col>
                  </Row>
                </div>
              )}
            </React.Fragment>
          );
        }}
      </Searchable>
    </div>
  );
}
