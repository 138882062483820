import * as React from "react";
import { useCallback } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Button from "react-bootstrap/Button";
import TypeaheadSearchField from "./TypeaheadSearchField";
import { Props as TypeaheadSearchFieldProps } from "./TypeaheadSearchField";

export interface MultiValueProps<T> {
  values: T[];
  onItemsChanged?(value: T[]): void;
}

export default function SearchableMultiSelect<T>({
  url,
  direction,
  name,
  values,
  labelKey = "name",
  valueKey = "id",
  ...rest
}: Omit<TypeaheadSearchFieldProps<T>, "labelKey" | "valueKey"> &
  MultiValueProps<T> &
  Partial<Pick<TypeaheadSearchFieldProps<T>, "labelKey" | "valueKey">>) {
  // If onItemSelect isn't passed, just keep the state on this component
  const [stateValue, setValue] = React.useState<T[]>([]);
  const [stateValueKeys, setValueKeys] = React.useState<string[]>([] as string[]);
  const defaultOnItemSelect = React.useCallback(
    (v) => {
      const newValues = [...stateValue, v];
      setValue(newValues);
      if (rest.onItemsChanged) {
        rest.onItemsChanged(newValues);
      }
    },
    [stateValue, setValue]
  );

  React.useEffect(() => setValue(values), [setValue, values]);
  React.useEffect(
    () => setValueKeys(stateValue.map((e) => e[valueKey].toString())),
    [setValueKeys, stateValue]
  );

  // The select box variant should prevent Enter keypresses from bubbling up to the form.
  const ref = React.useRef<HTMLDivElement>(null);
  const onKeyDown = useCallback((e) => {
    switch (e.keyCode) {
      case 13:
        ref.current && ref.current.blur();
        e.preventDefault();
        e.stopPropagation();
        break;
    }
  }, []);

  const removeValue = useCallback(
    (v) => {
      const newValues = stateValue.filter((r) => r[valueKey].toString() !== v[valueKey].toString());
      if (newValues.length !== stateValue.length) {
        setValue(newValues);
        if (rest.onItemsChanged) {
          rest.onItemsChanged(newValues);
        }
      }
    },
    [stateValue, setValue]
  );

  const valueInListCallback = useCallback(
    (e) => !stateValueKeys.includes(e[valueKey].toString()),
    [stateValueKeys, valueKey]
  );

  return (
    <div onKeyDown={onKeyDown} tabIndex={1} ref={ref}>
      <TypeaheadSearchField
        eager={rest.eager === false ? false : true}
        url={url}
        filter={valueInListCallback}
        onItemSelect={defaultOnItemSelect}
        {...rest}
        direction={direction}
        icon={`caret-${direction || "down"}` as IconProp}
        iconPosition="after"
        value={null}
      />
      {stateValue.map((v) => (
        <React.Fragment key={v[valueKey]}>
          <Button
            size="sm"
            className="mr-2 mt-2"
            variant="secondary"
            onClick={(e) => removeValue(v)}
          >
            {v[labelKey]} &times;
          </Button>
          <input type="hidden" name={`${name}[]`} value={v[valueKey]} />
        </React.Fragment>
      ))}
    </div>
  );
}
