import * as React from "react";
import { connect } from "react-redux";
import ResourcePane from "./ResourcePane";
import { putdown, pickup, clearClipboard, ClipboardType } from "../Store/actions";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class Pane extends ResourcePane {
  controller = "links";
  clipboardType: ClipboardType = "link";

  moveParams = (ids) => ({
    accountId: this.props.targets.account,
    moveLinkIds: ids,
  });

  trashParams = (ids) => {
    return { link_ids: ids };
  };

  canCopy = () => false;
  copyParams = () => [];

  canMove = () => this.props.targets.account !== undefined && this.props.targets.account !== null;

  trashPicked() {
    this.setState({ confirmTrash: true });
  }

  confirmTrash = () => {
    this.setState({ confirmTrash: false });
    super.trashPicked();
  };

  onHide = () => {
    this.setState({ confirmTrash: false });
  };

  renderField(row, col) {
    switch (col.key) {
      case "login":
        return <a href={`/links/${row.login}`}>{row.login}</a>;
      default:
        return super.renderField(row, col);
    }
  }

  render() {
    return (
      <div>
        {super.render()}
        <Modal show={this.state.confirmTrash} onHide={this.onHide}>
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              This will delete any selected links without attached reports or respondents. Continue?
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.onHide}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.confirmTrash}>
              Trash Link(s)
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.linkClipboard,
});

export default connect(mapStateToProps, { putdown, pickup, clearClipboard })(Pane);
