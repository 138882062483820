import * as React from "react";
import RangeSlider from "react-rangeslider";
import "./RangeSlider.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Range(props) {
  const { initialValue, isPercentage, name, ...rest } = props;
  const [value, setVal] = React.useState(initialValue);
  const setValue = React.useCallback(v => setVal(v), [setVal]);
  const format = React.useCallback(
    v => {
      if (isPercentage) {
        return `${Math.floor(v * 100)}%`;
      } else {
        return v;
      }
    },
    [isPercentage]
  );
  return (
    <Row className="align-items-center">
      <Col xs={10}>
        <RangeSlider value={value} {...rest} onChange={setValue} format={format} />
      </Col>
      <Col className="font-weight-bold">{format(value)}</Col>
      <input type="hidden" name={name} value={value} />
    </Row>
  );
}
