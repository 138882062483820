import * as React from "react";

import { createStore } from "redux";
import reducers from "./reducers";
import { Provider as ReduxProvider } from "react-redux";

function loadState() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}

function saveState(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    // ignore write errors
  }
}

const store = createStore(
  reducers,
  loadState(),
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);
store.subscribe(() => saveState(store.getState()));
export default store;

export function Provider(props) {
  return <ReduxProvider store={store}>{props.children}</ReduxProvider>;
}
