import * as React from "react";
import { FieldProps, getIn } from "formik";
import Form from "react-bootstrap/Form";
import styles from "./style.module.scss";
import { Searchable, Scrollable } from "../../Helpers";
import AutoVisibleListItem from "../../Helpers/AutoVisibleListItem";

type SearchOption = {
  group?: string;
  name: string;
  value: string;
};

interface LabeledSearchSelectProps extends FieldProps {
  label?: string;
  options: SearchOption[];
}

function LabeledSearchSelect(props: LabeledSearchSelectProps) {
  const { options, label, field, form } = props;

  const selectedOption = React.useMemo(
    () => options.find(opt => field.value && opt.value.toString() === field.value.toString()),
    [field.value, options]
  );
  const blurValue = (selectedOption && selectedOption.name) || "";

  const name = field.name;
  const touched = getIn(form.touched, name);
  const error = form.errors[name];

  return (
    <Form.Group>
      <input type="hidden" name={name} value={field.value || ""} />
      {label && <Form.Label className="font-weight-bold">{label}</Form.Label>}
      <Searchable
        name={field.name}
        value={field.value}
        style={{ cursor: "default" }}
        blurValue={blurValue}
        placeholder="Search..."
        onBlur={field.onBlur}
        isInvalid={touched && error !== undefined}
        onChange={field.onChange}
      >
        {({ searchRegexp, highlighted, selected, setFocused, focused }) => {
          const matchingOptions = React.useMemo(
            () =>
              options.filter(
                o => (o.group && o.group.match(searchRegexp)) || o.name.match(searchRegexp)
              ),
            [searchRegexp, options]
          );

          const setValue = React.useCallback(
            index => {
              setFocused(false);
              form.setFieldValue(name, matchingOptions[index].value);
            },
            [form, field, setFocused]
          );

          React.useEffect(() => {
            if (selected !== null && matchingOptions[selected]) {
              setValue(matchingOptions[selected].value);
            }
          }, [selected]);

          const highlightedIndex =
            highlighted &&
            ((highlighted % matchingOptions.length) + matchingOptions.length) %
              matchingOptions.length;
          return (
            <div>
              {focused && (
                <Scrollable maxHeight="50vh" className={styles.selectList}>
                  <div className="list-group list-group-flush">
                    {matchingOptions.map((opt, index) => (
                      <AutoVisibleListItem
                        index={index}
                        className={styles.selectListItem}
                        key={opt.value}
                        active={highlightedIndex === index}
                        value={opt.value}
                        label={opt.name}
                        onItemSelected={setValue}
                      />
                    ))}
                  </div>
                </Scrollable>
              )}
            </div>
          );
        }}
      </Searchable>
      {touched && <div className="text-danger small">{error}</div>}
    </Form.Group>
  );
}

export default React.memo(LabeledSearchSelect);
