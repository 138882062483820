export function getSessionStorage(key: string, dflt = {}): any {
  const t = sessionStorage.getItem(key);
  if (t) {
    return JSON.parse(t);
  } else {
    return dflt;
  }
}

export function writeSessionStorage(key: string, value: any): void {
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function createSessionStore(key: string) {
  const read = (dflt = {}) => getSessionStorage(key, dflt);
  const write = (data: any) => writeSessionStorage(key, data);
  return { read, write };
}
