/* eslint no-console:0 */

import * as React from "react";
import * as ReactDOM from "react-dom";

import SearchableSelect from "../components/Forms/Widgets/SearchableSelect";

window.React = React;
window.ReactDOM = ReactDOM;

// This provides jquery-less support for Bootstrap outside of React.
import "bootstrap.native/dist/bootstrap-native-v4";
window.addEventListener("DOMContentLoaded", () => {
  window["BSN"].initCallback();
});

import setupCSRFToken from "../support/api_client";
window.addEventListener("DOMContentLoaded", setupCSRFToken);

import { toast } from "react-toastify";
toast.configure();

import ReactRailsUJS from "react_ujs";
const components = import.meta.glob("../components/**/index.{ts,tsx}", { eager: true });
const registry = new Map();
for (let path in components) {
  const name = path.replace("../components/", "").replace(/\/index\.tsx?/, "");
  registry.set(name, components[path].default);
}
registry.set("Forms/Widgets/SearchableSelect", SearchableSelect);

ReactRailsUJS.getConstructor = (className) => {
  return registry.get(className);
};

window.componentRegistry = registry;
window.mountComponent = function (name, props, node) {
  ReactDOM.render(React.createElement(registry.get(name), props), node);
};
window.document.dispatchEvent(new Event("ApplicationReady"));

ReactRailsUJS.detectEvents();
ReactRailsUJS.mountComponents();

// Setup components
import Rails from "rails-ujs";
try {
  if (!window._rails_loaded) {
    Rails.start();
  }
} catch (e) {
  console.error(e);
}
