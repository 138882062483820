import * as React from "react";
import { useState } from "react";
import { Col, Row, Card, Button, Tabs, Tab, FormLabel } from "react-bootstrap";

import ExportFieldSelector from "./ExportFieldSelector";
import ExportScopeBuilder from "./ExportScopeBuilder";
import ExportFieldMapping from "./ExportFieldMapping";
import ExportFinish from "./ExportFinish";
import ConfigLoader from "./ConfigLoader";
import RestDataProvider from "../Helpers/RestDataProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isEqual from "react-fast-compare";
import produce from "immer";

export type Record = {
  id: string;
  name: string;
};

function Buttons({
  prev,
  next,
  setActiveTab,
}: {
  prev?: string;
  next?: string;
  setActiveTab(string);
}) {
  return (
    <Row className="my-3">
      <Col>
        {prev && (
          <Button onClick={() => setActiveTab(prev)}>
            <FontAwesomeIcon icon="arrow-left" /> Previous
          </Button>
        )}
      </Col>
      {next && (
        <Col xs="auto">
          <Button onClick={() => setActiveTab(next)}>
            Next <FontAwesomeIcon icon="arrow-right" />
          </Button>
        </Col>
      )}
    </Row>
  );
}

export default function ExportBuilder(props) {
  const [activeTab, setActiveTab] = React.useState("scope");
  const [payload, setPayload] = useState({
    format: "csv",
    account: null,
    link: null,
    respondents: "",
    study: null,
    columns: [],
    groupSelections: {},
    startDate: "",
    endDate: "",
  });

  const updatePayload = React.useCallback(
    (newPayload) => {
      if (!isEqual(payload, newPayload)) {
        setPayload(newPayload);
      }
    },
    [payload, setPayload]
  );

  const setFormat = React.useCallback(
    (e) => {
      updatePayload(
        produce(payload, (next) => {
          next.format = e.target.value;
        })
      );
    },
    [payload, updatePayload]
  );

  return (
    <div>
      <div>
        <RestDataProvider<any>
          url="/exports/count.json"
          delayMs={250}
          method="post"
          params={{ payload: payload }}
          defaultValue={{ report_count: 0 } as any}
          active={true}
        >
          {({ data, running }) => {
            const countData = data["response"] || {};
            const serverEstimate = countData["reportCount"];
            let strEstimate = serverEstimate;
            if (data["estimated"]) {
              if (serverEstimate > 1000000) {
                strEstimate = `~${(serverEstimate / 1000000).toFixed(1)}m`;
              } else if (serverEstimate > 1000) {
                strEstimate = `~${(serverEstimate / 1000).toFixed(1)}k`;
              } else if (serverEstimate > 0) {
                strEstimate = "< 1000";
              } else {
                strEstimate = "0";
              }
            }
            if (!data["valid"]) {
              strEstimate = "(too expensive to count)";
            }
            return (
              <React.Fragment>
                <Tabs
                  id="export-tabs"
                  activeKey={activeTab}
                  onSelect={(e) => setActiveTab(e || "")}
                >
                  <Tab eventKey="scope" title="1. Filter" className="my-3">
                    <Row className="mb-2">
                      <Col xs={2}>Load Configuration:</Col>
                      <Col xs={4}>
                        <ConfigLoader setPayload={updatePayload} />
                      </Col>
                    </Row>
                    <hr />
                    <Row className="mb-2">
                      <Col xs={2}>
                        <FormLabel>Export Format:</FormLabel>
                      </Col>
                      <Col>
                        <select
                          className="form-control"
                          onChange={setFormat}
                          value={payload.format}
                        >
                          <option value="csv">CSV</option>
                          <option value="tsv">TSV</option>
                          <option value="json">JSON</option>
                        </select>
                      </Col>
                    </Row>
                    <ExportScopeBuilder payload={payload} setPayload={updatePayload} />
                    <Buttons next="fields" setActiveTab={setActiveTab} />
                  </Tab>
                  <Tab eventKey="fields" title="2. Select Fields" className="my-3">
                    <p>
                      Select a field group, then select the fields from the group that you wish to
                      include in your export.
                    </p>
                    <ExportFieldSelector payload={payload} setPayload={updatePayload} />
                    <Buttons next="mapping" prev="scope" setActiveTab={setActiveTab} />
                  </Tab>
                  <Tab eventKey="mapping" title="3. Field Mapping" className="my-3">
                    {payload.format !== "json" && (
                      <ExportFieldMapping payload={payload} setPayload={updatePayload} />
                    )}
                    {payload.format === "json" && (
                      <p>Unable to rename fields when exporting as JSON</p>
                    )}
                    <Buttons next="finish" prev="fields" setActiveTab={setActiveTab} />
                  </Tab>
                  <Tab eventKey="finish" title="4. Export" className="my-3">
                    <ExportFinish payload={payload} environment={props.environment} />
                    <Buttons prev="mapping" setActiveTab={setActiveTab} />
                  </Tab>
                </Tabs>
                <hr />
                <div className="alert alert-info">
                  <Row>
                    <Col>Export size: {strEstimate} records</Col>
                    <Col xs="auto">{running && <FontAwesomeIcon spin icon="spinner" />}</Col>
                  </Row>
                </div>
              </React.Fragment>
            );
          }}
        </RestDataProvider>

        <RestDataProvider<any>
          url="/exports/preview.json"
          delayMs={250}
          method="post"
          params={{ payload: payload }}
          defaultValue={{ report_count: 0 } as any}
          active={true}
        >
          {({ data, running }) => {
            return (
              <Card>
                <Card.Body>
                  {running && (
                    <FontAwesomeIcon
                      spin
                      icon="spinner"
                      style={{ position: "absolute", top: "10px", right: "10px" }}
                    />
                  )}
                  <pre>{data["response"]}</pre>
                </Card.Body>
              </Card>
            );
          }}
        </RestDataProvider>
      </div>
    </div>
  );
}
