const config = {
  groups: {
    B_ALL: "Behaviors - Scores (all)",
    B_LEGACY: "Behaviors - Scores (legacy only)",
    DEMO_ALL: "Demographics (all)",
    DEMO_AU: "Demographics (Australia)",
    DEMO_CA: "Demographics (Canada)",
    DEMO_IE: "Demographics (Ireland)",
    DEMO_NZ: "Demographics (New Zealand)",
    DEMO_UK: "Demographics (UK)",
    DEMO_US: "Demographics (US)",
    DEMO_ZA: "Demographics (South Africa)",
    D_ALL: "DNAPlus - Scores (all)",
    D_LEGACY: "DNAPlus - Scores (legacy only)",
    EQ_ALL: "EQ - Scores (all)",
    EQ_LEGACY: "EQ - Scores (legacy only)",
    EQ_RESP: "EQ - Responses",
    HA3_RESP: "Hartman - Responses",
    H_ALL: "Hartman - Scores (all)",
    H_CLARITY_PATTERNS: "Hartman - Clarity Patterns",
    H_DIMBAL: "Hartman - Dimensional Balance",
    H_DIMBAL_BIASES: "Hartman - Dimensional Balance Biases",
    H_DIMBAL_BIAS_COMPONENTS: "Hartman - Dimensional Balance Bias Components",
    H_LEGACY: "Hartman - Scores (legacy only)",
    H_RELIABILITY: "Hartman - Reliability",
    H_TRIMETRIX23: "Hartman - Trimetrix 23",
    H_TRIMETRIX23A: "Hartman - Trimetrix 23A",
    L_ALL: "Account, Login, \u0026 Link Type",
    PATTR_RESP: "Personal Attributes - Responses",
    PIAV2CAR_RESP: "Motivators (CAR) - Responses",
    PIAV2_RESP: "Motivators - Responses",
    PIAV_RESP: "Motivators (legacy) - Responses",
    PL_ALL: "Prioritized Leader - Scores",
    PL_RESP: "Prioritized Leader - Responses",
    PL2_RESP: "Prioritized Leader 2 - Responses",
    PSSI_ALL: "PSSI - Scores (all)",
    PSSI_LEGACY: "PSSI - Scores (legacy)",
    PSSI_RESP: "PSSI - Responses",
    RC_RESP: "RC - Responses",
    RPT_ALL: "Report Metadata",
    RPT_LEGACY: "Report Metadata (legacy)",
    R_ALL: "Respondent Metadata",
    R_LEGACY: "Respondent Metadata (legacy)",
    SA2CAR_RESP: "Style Analysis v2 CAR - Responses",
    SA2R4_RESP: "Style Analysis v2 (rank 4) - Responses",
    SA2_RESP: "Style Analysis v2 (most least) - Responses",
    SAR4_RESP: "Style Analysis v1 (rank 4) - Responses",
    SA_RESP: "Style Analysis v1 (most least) - Responses",
    SSI1_RESP: "SSI v1 - Responses",
    SSI2_RESP: "SSI v2 - Responses",
    SSI3_RESP: "SSI v3 - Responses",
    SSI_ALL: "SSI - Scores",
    SSI_LEGACY: "SSI - Scores (legacy)",
    SSI_TSI: "SSI_TSI - Scores",
    STRESS_RESP: "Stress v1 - Responses",
    STRESS2_RESP: "Stress v2 - Responses",
    STRESS_ALL: "Stress - Scores (all)",
    STRESS_LEGACY: "Stress - Scores (legacy)",
    STRESS_INDEX: "Stress - Scores (index)",
    STRESS_PERCEPT: "Stress - Scores (perceptions)",
    V_ALL: "Motivators - Scores",
    V_LEGACY: "Motivators - Scores (legacy)",
    WE1_ALL: "Work Environment (WE1)",
    WE1_RESP: "Work Environment (WE1) - Responses",
    WE2_ALL: "Work Environment - Ideal (WE2)",
    WE2_RESP: "Work Environment (WE2) - Responses",
    COOPER_RESP: "Cooper Responses",
    COOPER: "Cooper Scores",
    EFE_ALL: "8 Factors of Engagement",
  },
  fields: {},
  fieldNames: {},
  fieldMappings: {
    B_ALL: {
      B1: "URGENCY",
      B2: "FREQUENT INTERACTION WITH OTHERS",
      B3: "ORGANIZED WORKPLACE",
      B4: "ANALYSIS OF DATA",
      B5: "COMPETITIVENESS",
      B6: "VERSATILITY",
      B7: "PEOPLE ORIENTED",
      B8: "FREQUENT CHANGE",
      B9: "CONSISTENCY",
      B10: "CUSTOMER RELATIONS",
      B11: "FOLLOW UP AND FOLLOW THROUGH",
      B12: "FOLLOWING POLICY",
      B1A: "URGENCY (A)",
      B2A: "FREQUENT INTERACTION WITH OTHERS (A)",
      B3A: "ORGANIZED WORKPLACE (A)",
      B4A: "ANALYSIS OF DATA (A)",
      B5A: "COMPETITIVENESS (A)",
      B6A: "VERSATILITY (A)",
      B7A: "PEOPLE ORIENTED (A)",
      B8A: "FREQUENT CHANGE (A)",
      B9A: "CONSISTENCY (A)",
      B10A: "CUSTOMER RELATIONS (A)",
      B11A: "FOLLOW UP AND FOLLOW THROUGH (A)",
      B12A: "FOLLOWING POLICY (A)",
      B_ELAPSED: "",
      B_NORM: "",
      B_RESPONSES: "",
      C_ADAPTED: "C ADAPTED (%)",
      C_ADAPTED_RAW: "RESP_C",
      C_NATURAL: "C NATURAL (%)",
      C_NATURAL_RAW: "BASIC_C",
      D_ADAPTED: "D ADAPTED (%)",
      D_ADAPTED_RAW: "RESP_D",
      D_NATURAL: "D NATURAL (%)",
      D_NATURAL_RAW: "BASIC_D",
      I_ADAPTED: "I ADAPTED (%)",
      I_ADAPTED_RAW: "RESP_I",
      I_NATURAL: "I NATURAL (%)",
      I_NATURAL_RAW: "BASIC_I",
      S_ADAPTED: "S ADAPTED (%)",
      S_ADAPTED_RAW: "RESP_S",
      S_NATURAL: "S NATURAL (%)",
      S_NATURAL_RAW: "BASIC_S",
      WHEEL1: "WHEEL POSITION 1",
      WHEEL2: "WHEEL POSITION 2",
    },
    B_LEGACY: {
      B1: "URGENCY",
      B10: "CUSTOMER RELATIONS",
      B11: "FOLLOW UP AND FOLLOW THROUGH",
      B12: "FOLLOWING POLICY",
      B2: "FREQUENT INTERACTION WITH OTHERS",
      B3: "ORGANIZED WORKPLACE",
      B4: "ANALYSIS OF DATA",
      B5: "COMPETITIVENESS",
      B6: "VERSATILITY",
      B7: "PEOPLE ORIENTED",
      B8: "FREQUENT CHANGE",
      B9: "CONSISTENCY",
      C_ADAPTED: "C ADAPTED (%)",
      C_NATURAL: "C NATURAL (%)",
      D_ADAPTED: "D ADAPTED (%)",
      D_NATURAL: "D NATURAL (%)",
      I_ADAPTED: "I ADAPTED (%)",
      I_NATURAL: "I NATURAL (%)",
      S_ADAPTED: "S ADAPTED (%)",
      S_NATURAL: "S NATURAL (%)",
      WHEEL1: "WHEEL POSITION 1",
      WHEEL2: "WHEEL POSITION 2",
    },
    DEMO_ALL: {
      COLLEGE_MAJOR: "",
      COUNTRY: "",
      DEALER_CODE: "",
      DEALER_NAME: "",
      DISABLED: "",
      DISABLED_UK: "",
      DISABLED_AU: "",
      EDUCATION_AU: "",
      EDUCATION_IE: "",
      EDUCATION_NZ: "",
      EDUCATION_UK: "",
      EDUCATION_US: "",
      EDUCATION_ZA: "",
      EMPLOYMENT_STATUS: "",
      ETHNICITY: "",
      ETHNICITY_AU: "",
      ETHNICITY_IE: "",
      ETHNICITY_NZ: "",
      ETHNICITY_UK: "",
      ETHNICITY_ZA: "",
      EXPERIENCE: "",
      HIGH_SCHOOL_LEADERSHIP_ROLES: "",
      INCOME: "",
      LOCATION_NZ: "",
      INDUSTRY: "",
      JOB_DESCRIPTION: "",
      LOCATION_CODE: "",
      MAJOR_RELATED_JOBS: "",
      OCCUPATION: "",
      POSTAL_CODE: "",
      STATE: "",
      STATE_AU: "",
      VETERAN: "",
      YEAR_BORN: "",
    },
    DEMO_AU: {
      DISABLED_AU: "",
      EDUCATION_AU: "",
      EMPLOYMENT_STATUS: "",
      ETHNICITY_AU: "",
      INDUSTRY: "",
      JOB_DESCRIPTION: "",
      STATE_AU: "",
      YEAR_BORN: "",
    },
    DEMO_CA: {
      DISABLED: "",
      EDUCATION_US: "",
      EMPLOYMENT_STATUS: "",
      INDUSTRY: "",
      JOB_DESCRIPTION: "",
      POSTAL_CODE: "",
      VETERAN: "",
      YEAR_BORN: "",
    },
    DEMO_IE: {
      COUNTY_IE: "",
      DISABLED_UK: "",
      EDUCATION_IE: "",
      EMPLOYMENT_STATUS: "",
      ETHNICITY_IE: "",
      INDUSTRY: "",
      INDUSTRY_SECTOR: "",
      JOB_DESCRIPTION: "",
      YEAR_BORN: "",
    },
    DEMO_NZ: {
      DISABLED_AU: "",
      EDUCATION_NZ: "",
      EMPLOYMENT_STATUS: "",
      ETHNICITY_NZ: "",
      INDUSTRY: "",
      JOB_DESCRIPTION: "",
      LOCATION_NZ: "",
      YEAR_BORN: "",
    },
    DEMO_UK: {
      COUNTY_UK: "",
      DISABLED_UK: "",
      EDUCATION_UK: "",
      ETHNICITY_UK: "",
      EMPLOYMENT_STATUS: "",
      INDUSTRY: "",
      INDUSTRY_SECTOR: "",
      JOB_DESCRIPTION: "",
      YEAR_BORN: "",
    },
    DEMO_US: {
      DISABLED: "",
      EDUCATION_US: "",
      EMPLOYMENT_STATUS: "",
      ETHNICITY: "",
      INDUSTRY: "",
      JOB_DESCRIPTION: "",
      POSTAL_CODE: "",
      VETERAN: "",
      YEAR_BORN: "",
      GENDER_IDENTITY: "",
    },
    DEMO_ZA: {
      DISABLED_UK: "",
      EDUCATION_ZA: "",
      EMPLOYMENT_STATUS: "",
      ETHNICITY_ZA: "",
      INDUSTRY: "",
      JOB_DESCRIPTION: "",
      YEAR_BORN: "",
    },
    D_ALL: {
      DNAPLUS1: "APPRECIATING OTHERS",
      DNAPLUS10: "FUTURISTIC THINKING",
      DNAPLUS11: "GOAL ORIENTATION",
      DNAPLUS12: "INFLUENCING OTHERS",
      DNAPLUS13: "INTERPERSONAL SKILLS",
      DNAPLUS14: "LEADERSHIP",
      DNAPLUS15: "CONTINUOUS LEARNING",
      DNAPLUS16: "NEGOTIATION",
      DNAPLUS17: "PERSONAL ACCOUNTABILITY",
      DNAPLUS18: "PLANNING AND ORGANIZING",
      DNAPLUS19: "PROBLEM SOLVING",
      DNAPLUS2: "CONFLICT MANAGEMENT",
      DNAPLUS20: "PROJECT MANAGEMENT",
      DNAPLUS21: "RESILIENCY",
      DNAPLUS22: "TIME AND PRIORITY MANAGEMENT",
      DNAPLUS23: "SELF STARTING",
      DNAPLUS24: "TEAMWORK",
      DNAPLUS25: "UNDERSTANDING OTHERS",
      DNAPLUS3: "CREATIVITY AND INNOVATION",
      DNAPLUS4: "CONCEPTUAL THINKING",
      DNAPLUS5: "CUSTOMER FOCUS",
      DNAPLUS6: "EMPLOYEE DEVELOPMENT/COACHING",
      DNAPLUS7: "DIPLOMACY",
      DNAPLUS8: "DECISION MAKING",
      DNAPLUS9: "FLEXIBILITY",
      DNAPLUS_ELAPSED: "Secs to complete DNA Plus instrument",
      DNAPLUS_NORM: "DNA 25 norm",
      DNAPLUS_RESPONSES: "",
    },
    D_LEGACY: {
      DNAPLUS1: "APPRECIATING OTHERS",
      DNAPLUS10: "FUTURISTIC THINKING",
      DNAPLUS11: "GOAL ORIENTATION",
      DNAPLUS12: "INFLUENCING OTHERS",
      DNAPLUS13: "INTERPERSONAL SKILLS",
      DNAPLUS14: "LEADERSHIP",
      DNAPLUS15: "CONTINUOUS LEARNING",
      DNAPLUS16: "NEGOTIATION",
      DNAPLUS17: "PERSONAL ACCOUNTABILITY",
      DNAPLUS18: "PLANNING AND ORGANIZING",
      DNAPLUS19: "PROBLEM SOLVING",
      DNAPLUS2: "CONFLICT MANAGEMENT",
      DNAPLUS20: "PROJECT MANAGEMENT",
      DNAPLUS21: "RESILIENCY",
      DNAPLUS22: "TIME AND PRIORITY MANAGEMENT",
      DNAPLUS23: "SELF STARTING",
      DNAPLUS24: "TEAMWORK",
      DNAPLUS25: "UNDERSTANDING OTHERS",
      DNAPLUS3: "CREATIVITY AND INNOVATION",
      DNAPLUS4: "CONCEPTUAL THINKING",
      DNAPLUS5: "CUSTOMER FOCUS",
      DNAPLUS6: "EMPLOYEE DEVELOPMENT/COACHING",
      DNAPLUS7: "DIPLOMACY",
      DNAPLUS8: "DECISION MAKING",
      DNAPLUS9: "FLEXIBILITY",
    },
    EQ_ALL: {
      EQ_C1: "RAW SELF AWARENESS",
      EQ_C1_SCALED: "SELF AWARENESS",
      EQ_C2: "RAW SELF REGULATION",
      EQ_C2_SCALED: "SELF REGULATION",
      EQ_C3: "RAW MOTIVATION",
      EQ_C3_SCALED: "MOTIVATION",
      EQ_C4: "RAW EMPATHY",
      EQ_C4_SCALED: "EMPATHY",
      EQ_C5: "RAW SOCIAL SKILLS",
      EQ_C5_SCALED: "SOCIAL SKILLS",
      EQ_ELAPSED: "SECS TO COMPLETE EQ INSTRUMENT",
      EQ_INTER: "RAW INTERPERSONAL",
      EQ_INTER_SCALED: "INTERPERSONAL",
      EQ_INTRA: "RAW INTRAPERSONAL",
      EQ_INTRA_SCALED: "INTRAPERSONAL",
      EQ_NORM: "EQ NORM",
      EQ_OVER: "RAW EQ_TOTAL",
      EQ_OVER_SCALED: "EQ_TOTAL",
      EQ_RESPONSES: "",
      EQ_VERSION: "",
    },
    EQ_LEGACY: {
      EQ_C1_SCALED: "SELF AWARENESS",
      EQ_C2_SCALED: "SELF REGULATION",
      EQ_C3_SCALED: "MOTIVATION",
      EQ_C4_SCALED: "EMPATHY",
      EQ_C5_SCALED: "SOCIAL SKILLS",
      EQ_INTER_SCALED: "INTERPERSONAL",
      EQ_INTRA_SCALED: "INTRAPERSONAL",
      EQ_OVER_SCALED: "EQ_TOTAL",
    },
    EQ_RESP: {
      EQ_F01_R01: "",
      EQ_F02_R01: "",
      EQ_F03_R01: "",
      EQ_F04_R01: "",
      EQ_F05_R01: "",
      EQ_F06_R01: "",
      EQ_F07_R01: "",
      EQ_F08_R01: "",
      EQ_F09_R01: "",
      EQ_F10_R01: "",
      EQ_F11_R01: "",
      EQ_F12_R01: "",
      EQ_F13_R01: "",
      EQ_F14_R01: "",
      EQ_F15_R01: "",
      EQ_F16_R01: "",
      EQ_F17_R01: "",
      EQ_F18_R01: "",
      EQ_F19_R01: "",
      EQ_F20_R01: "",
      EQ_F21_R01: "",
      EQ_F22_R01: "",
      EQ_F23_R01: "",
      EQ_F24_R01: "",
      EQ_F25_R01: "",
      EQ_F26_R01: "",
      EQ_F27_R01: "",
      EQ_F28_R01: "",
      EQ_F29_R01: "",
      EQ_F30_R01: "",
      EQ_F31_R01: "",
      EQ_F32_R01: "",
      EQ_F33_R01: "",
      EQ_F34_R01: "",
      EQ_F35_R01: "",
      EQ_F36_R01: "",
      EQ_F37_R01: "",
      EQ_F38_R01: "",
      EQ_F39_R01: "",
      EQ_F40_R01: "",
      EQ_F41_R01: "",
      EQ_F42_R01: "",
      EQ_F43_R01: "",
      EQ_F44_R01: "",
      EQ_F45_R01: "",
      EQ_F46_R01: "",
      EQ_F47_R01: "",
      EQ_F48_R01: "",
      EQ_F49_R01: "",
      EQ_F50_R01: "",
      EQ_F51_R01: "",
      EQ_F52_R01: "",
      EQ_F53_R01: "",
      EQ_F54_R01: "",
      EQ_F55_R01: "",
      EQ_F56_R01: "",
      EQ_F57_R01: "",
    },
    HA3_RESP: {
      HA3_F01_R01: "",
      HA3_F01_R02: "",
      HA3_F01_R03: "",
      HA3_F01_R04: "",
      HA3_F01_R05: "",
      HA3_F01_R06: "",
      HA3_F01_R07: "",
      HA3_F01_R08: "",
      HA3_F01_R09: "",
      HA3_F01_R10: "",
      HA3_F01_R11: "",
      HA3_F01_R12: "",
      HA3_F01_R13: "",
      HA3_F01_R14: "",
      HA3_F01_R15: "",
      HA3_F01_R16: "",
      HA3_F01_R17: "",
      HA3_F01_R18: "",
      HA3_F02_R01: "",
      HA3_F02_R02: "",
      HA3_F02_R03: "",
      HA3_F02_R04: "",
      HA3_F02_R05: "",
      HA3_F02_R06: "",
      HA3_F02_R07: "",
      HA3_F02_R08: "",
      HA3_F02_R09: "",
      HA3_F02_R10: "",
      HA3_F02_R11: "",
      HA3_F02_R12: "",
      HA3_F02_R13: "",
      HA3_F02_R14: "",
      HA3_F02_R15: "",
      HA3_F02_R16: "",
      HA3_F02_R17: "",
      HA3_F02_R18: "",
    },
    H_ALL: {
      AI1: "Raw External AI",
      AI2: "Raw Internal AI",
      BC1: "External Bias Code",
      BC2: "Internal Bias Code",
      CAP1: "FOLLOWING DIRECTIONS",
      CAP11: "PROJECT AND GOAL FOCUS",
      CAP14: "SURRENDERING CONTROL",
      CAP15: "",
      CAP16: "",
      CAP17: "BALANCED DECISION MAKING",
      CAP18: "ATTITUDE TOWARD OTHERS",
      CAP19: "FREEDOM FROM PREJUDICES",
      CAP2: "THEORETICAL PROBLEM SOLVING",
      CAP20: "REALISTIC EXPECTATIONS",
      CAP22: "SELF CONFIDENCE",
      CAP23: "SELF ASSESSMENT",
      CAP24: "PERSONAL DRIVE",
      CAP26: "HANDLING STRESS",
      CAP27: "",
      CAP28: "REALISTIC PERSONAL GOAL SETTING",
      CAP29: "EVALUATING OTHERS",
      CAP30: "CORRECTING OTHERS",
      CAP34: "REALISTIC GOAL SETTING FOR OTHERS",
      CAP35: "PROJECT SCHEDULING",
      CAP36: "",
      CAP38: "QUALITY ORIENTATION",
      CAP4: "CONCRETE ORGANIZATION",
      CAP40: "",
      CAP41: "CONVEYING ROLE VALUE",
      CAP42: "GAINING COMMITMENT",
      CAP43: "UNDERSTANDING MOTIVATIONAL NEEDS",
      CAP44: "LONG RANGE PLANNING",
      CAP45: "PROACTIVE THINKING",
      CAP47: "HANDLING REJECTION",
      CAP48: "MEETING STANDARDS",
      CAP50: "RESPECT FOR POLICIES",
      CAP51: "JOB ETHIC",
      CAP52: "INTEGRATIVE ABILITY",
      CAP53: "USING COMMON SENSE",
      CAP56: "EMOTIONAL CONTROL",
      CAP58: "PERSUADING OTHERS",
      CAP59: "RELATING TO OTHERS",
      CAP6: "INTUITIVE DECISION MAKING",
      CAP61: "ROLE CONFIDENCE",
      CAP62: "INTERNAL SELF CONTROL",
      CAP63: "SENSITIVITY TO OTHERS",
      CAP64: "INITIATIVE",
      CAP66: "EVALUATING WHAT IS SAID",
      CAP67: "SENSE OF TIMING",
      CAP68: "",
      CAP7: "ATTENTION TO DETAIL",
      CAP70: "PERSISTENCE",
      CAP73: "MONITORING OTHERS",
      CAP76: "STATUS AND RECOGNITION",
      CAP77: "SENSE OF MISSION",
      CAP78: "SENSE OF BELONGING",
      CAP79: "SELF IMPROVEMENT",
      CAP8: "CONSISTENCY AND RELIABILITY",
      CAP80: "PERSONAL RELATIONSHIPS",
      CAP81: "MATERIAL POSSESSIONS",
      CAP84: "ENJOYMENT OF THE JOB",
      CAP9: "PERSONAL ACCOUNTABILITY",
      DI1: "Raw External DI",
      DI1_S: "EXTERNAL CONCENTRATION INDEX",
      DI2: "Raw Internal DI",
      DI2_S: "INTERNAL CONCENTRATION INDEX",
      DIF1: "Raw External DIF",
      DIF2: "Raw Internal DIF",
      DIM1: "Raw External DIM",
      DIM1_PCT: "Raw External DIM %",
      DIM2: "Raw Internal DIM",
      DIM2_PCT: "Raw Internal DIM %",
      DIME1: "Raw Practical Thinking",
      DIME2: "Raw Role Awareness",
      DIMI1: "Raw Empathetic Outlook",
      DIMI2: "Raw Sense Of Self",
      DIMS1: "Raw Systems Judgement",
      DIMS2: "Raw Self Direction",
      DIS1: "Raw External DIS",
      DIS2: "Raw Internal DIS",
      E1: "PRACTICAL THINKING",
      E1B: "PRACTICAL THINKING BIAS",
      E1DNB: "Practical Thinking deficiency negative bias",
      E1DPB: "Practical Thinking deficiency positive bias",
      E1SNB: "Practical Thinking sufficiency negative bias",
      E1SPB: "Practical Thinking sufficiency positive bias",
      E2: "ROLE AWARENESS",
      E2B: "ROLE AWARENESS BIAS",
      E2DNB: "Role Awareness deficiency negative bias",
      E2DPB: "Role Awareness deficiency positive bias",
      E2SNB: "Role Awareness sufficiency negative bias",
      E2SPB: "Role Awareness sufficiency positive bias",
      EPAT: "External Clarity Pattern",
      H1: "SELF MANAGEMENT",
      H10: "DECISION MAKING",
      H11: "UNDERSTANDING OTHERS",
      H12: "PERSONAL ACCOUNTABILITY",
      H13: "DEVELOPING OTHERS",
      H14: "OBJECTIVE LISTENING",
      H15: "INFLUENCING OTHERS",
      H16: "LEADING OTHERS",
      H17: "RESILIENCY",
      H18: "TEAMWORK",
      H19: "ACCOUNTABILITY FOR OTHERS",
      H2: "GOAL ACHIEVEMENT",
      H20: "CONTINUOUS LEARNING",
      H21: "SELF STARTING ABILITY",
      H22: "CONFLICT MANAGEMENT",
      H23: "FLEXIBILITY",
      H3: "RESULTS ORIENTATION",
      H4: "PROBLEM SOLVING",
      H5: "CUSTOMER FOCUS",
      H6: "PLANNING AND ORGANIZING",
      H7: "INTERPERSONAL SKILLS",
      H8: "DIPLOMACY AND TACT",
      H9: "CONCEPTUAL THINKING",
      HA3_ELAPSED: "Seconds to complete Hartman instrument",
      H_NORM: "Hartman Norm",
      H_RESPONSES: "",
      I1: "UNDERSTANDING OTHERS",
      I1B: "UNDERSTANDING OTHERS BIAS",
      I1DNB: "Empathetic Outlook deficiency negative bias",
      I1DPB: "Empathetic Outlook deficiency positive bias",
      I1SNB: "Empathetic Outlook sufficiency negative bias",
      I1SPB: "Empathetic Outlook sufficiency positive bias",
      I2: "SENSE OF SELF",
      I2B: "SENSE OF SELF BIAS",
      I2DNB: "Sense of Self deficiency negative bias",
      I2DPB: "Sense of Self deficiency positive bias",
      I2SNB: "Sense of Self sufficiency negative bias",
      I2SPB: "Sense of Self sufficiency positive bias",
      INT1: "Raw External INT",
      INT1_PCT: "Raw External INT %",
      INT1_S: "CAPACITY FOR PROBLEM SOLVING AND DECISION MAKING IN THE OUTSIDE WORLD",
      INT2: "Raw Internal INT",
      INT2_PCT: "Raw Internal INT %",
      INT2_S: "CAPACITY FOR PROBLEM SOLVING AND DECISION MAKING WITHIN ONE'S SELF",
      INTE1: "Raw External IntE",
      INTE1_S: "CAPACITY FOR SOLVING PRACTICAL PROBLEMS",
      INTE2: "Raw Internal IntE",
      INTE2_S: "PROBLEM SOLVING ABILITY WITHIN ONE'S ROLES",
      INTI1: "Raw External IntI",
      INTI1_S: "CAPACITY FOR SOLVING PROBLEMS INVOLVING PEOPLE",
      INTI2: "Raw Internal IntI",
      INTI2_S: "INTERNAL PROBLEM SOLVING ABILITY",
      INTS1: "Raw External IntS",
      INTS1_S: "CAPACITY FOR SOLVING THEORETICAL PROBLEMS",
      INTS2: "Raw Internal IntS",
      INTS2_S: "PROBLEM SOLVING ABILITY REGARDING ONE'S FUTURE",
      IPAT: "Internal Clarity Pattern",
      RHO1: "EXTERNAL RELIABILITY",
      RHO2: "INTERNAL RELIABILITY",
      S1: "SYSTEMS JUDGMENT",
      S1B: "SYSTEMS JUDGMENT BIAS",
      S1DNB: "Systems Judgement deficiency negative bias",
      S1DPB: "Systems Judgement deficiency positive bias",
      S1SNB: "Systems Judgement sufficiency negative bias",
      S1SPB: "Systems Judgement sufficiency positive bias",
      S2: "SELF DIRECTION",
      S2B: "SELF DIRECTION BIAS",
      S2DNB: "Self Direction deficiency negative bias",
      S2DPB: "Self Direction deficiency positive bias",
      S2SNB: "Self Direction sufficiency negative bias",
      S2SPB: "Self Direction sufficiency positive bias",
    },
    H_CLARITY_PATTERNS: {
      BC1: "External Bias Code",
      BC2: "Internal Bias Code",
      EPAT: "External Clarity Pattern",
      IPAT: "Internal Clarity Pattern",
    },
    H_DIMBAL: {
      E1: "PRACTICAL THINKING",
      E2: "ROLE AWARENESS",
      I1: "UNDERSTANDING OTHERS",
      I2: "SENSE OF SELF",
      S1: "SYSTEMS JUDGMENT",
      S2: "SELF DIRECTION",
    },
    H_DIMBAL_BIASES: {
      E1B: "PRACTICAL THINKING BIAS",
      E2B: "ROLE AWARENESS BIAS",
      I1B: "UNDERSTANDING OTHERS BIAS",
      I2B: "SENSE OF SELF BIAS",
      S1B: "SYSTEMS JUDGMENT BIAS",
      S2B: "SELF DIRECTION BIAS",
    },
    H_DIMBAL_BIAS_COMPONENTS: {
      E1DNB: "Practical Thinking deficiency negative bias",
      E1DPB: "Practical Thinking deficiency positive bias",
      E1SNB: "Practical Thinking sufficiency negative bias",
      E1SPB: "Practical Thinking sufficiency positive bias",
      E2DNB: "Role Awareness deficiency negative bias",
      E2DPB: "Role Awareness deficiency positive bias",
      E2SNB: "Role Awareness sufficiency negative bias",
      E2SPB: "Role Awareness sufficiency positive bias",
      I1DNB: "Empathetic Outlook deficiency negative bias",
      I1DPB: "Empathetic Outlook deficiency positive bias",
      I1SNB: "Empathetic Outlook sufficiency negative bias",
      I1SPB: "Empathetic Outlook sufficiency positive bias",
      I2DNB: "Sense of Self deficiency negative bias",
      I2DPB: "Sense of Self deficiency positive bias",
      I2SNB: "Sense of Self sufficiency negative bias",
      I2SPB: "Sense of Self sufficiency positive bias",
      S1DNB: "Systems Judgement deficiency negative bias",
      S1DPB: "Systems Judgement deficiency positive bias",
      S1SNB: "Systems Judgement sufficiency negative bias",
      S1SPB: "Systems Judgement sufficiency positive bias",
      S2DNB: "Self Direction deficiency negative bias",
      S2DPB: "Self Direction deficiency positive bias",
      S2SNB: "Self Direction sufficiency negative bias",
      S2SPB: "Self Direction sufficiency positive bias",
    },
    H_LEGACY: {
      CAP1: "FOLLOWING DIRECTIONS",
      CAP11: "PROJECT AND GOAL FOCUS",
      CAP14: "SURRENDERING CONTROL",
      CAP17: "BALANCED DECISION MAKING",
      CAP18: "ATTITUDE TOWARD OTHERS",
      CAP19: "FREEDOM FROM PREJUDICES",
      CAP2: "THEORETICAL PROBLEM SOLVING",
      CAP20: "REALISTIC EXPECTATIONS",
      CAP22: "SELF CONFIDENCE",
      CAP23: "SELF ASSESSMENT",
      CAP24: "PERSONAL DRIVE",
      CAP26: "HANDLING STRESS",
      CAP28: "REALISTIC PERSONAL GOAL SETTING",
      CAP29: "EVALUATING OTHERS",
      CAP30: "CORRECTING OTHERS",
      CAP34: "REALISTIC GOAL SETTING FOR OTHERS",
      CAP35: "PROJECT SCHEDULING",
      CAP38: "QUALITY ORIENTATION",
      CAP4: "CONCRETE ORGANIZATION",
      CAP41: "CONVEYING ROLE VALUE",
      CAP42: "GAINING COMMITMENT",
      CAP43: "UNDERSTANDING MOTIVATIONAL NEEDS",
      CAP44: "LONG RANGE PLANNING",
      CAP45: "PROACTIVE THINKING",
      CAP47: "HANDLING REJECTION",
      CAP48: "MEETING STANDARDS",
      CAP50: "RESPECT FOR POLICIES",
      CAP51: "JOB ETHIC",
      CAP52: "INTEGRATIVE ABILITY",
      CAP53: "USING COMMON SENSE",
      CAP56: "EMOTIONAL CONTROL",
      CAP58: "PERSUADING OTHERS",
      CAP59: "RELATING TO OTHERS",
      CAP6: "INTUITIVE DECISION MAKING",
      CAP61: "ROLE CONFIDENCE",
      CAP62: "INTERNAL SELF CONTROL",
      CAP63: "SENSITIVITY TO OTHERS",
      CAP64: "INITIATIVE",
      CAP66: "EVALUATING WHAT IS SAID",
      CAP67: "SENSE OF TIMING",
      CAP7: "ATTENTION TO DETAIL",
      CAP70: "PERSISTENCE",
      CAP73: "MONITORING OTHERS",
      CAP76: "STATUS AND RECOGNITION",
      CAP77: "SENSE OF MISSION",
      CAP78: "SENSE OF BELONGING",
      CAP79: "SELF IMPROVEMENT",
      CAP8: "CONSISTENCY AND RELIABILITY",
      CAP80: "PERSONAL RELATIONSHIPS",
      CAP81: "MATERIAL POSSESSIONS",
      CAP84: "ENJOYMENT OF THE JOB",
      E1: "PRACTICAL THINKING",
      E2: "ROLE AWARENESS",
      H1: "SELF MANAGEMENT",
      H11: "UNDERSTANDING OTHERS",
      H12: "PERSONAL ACCOUNTABILITY",
      H13: "DEVELOPING OTHERS",
      H16: "LEADING OTHERS",
      H19: "ACCOUNTABILITY FOR OTHERS",
      H21: "SELF STARTING ABILITY",
      H3: "RESULTS ORIENTATION",
      H4: "PROBLEM SOLVING",
      H9: "CONCEPTUAL THINKING",
      I2: "SENSE OF SELF",
      S1: "SYSTEMS JUDGMENT",
      S2: "SELF DIRECTION",
    },
    H_RELIABILITY: {
      RHO1: "EXTERNAL RELIABILITY",
      RHO2: "INTERNAL RELIABILITY",
    },
    H_TRIMETRIX23: {
      H1: "SELF MANAGEMENT",
      H10: "DECISION MAKING",
      H11: "UNDERSTANDING OTHERS",
      H12: "PERSONAL ACCOUNTABILITY",
      H13: "DEVELOPING OTHERS",
      H14: "OBJECTIVE LISTENING",
      H15: "INFLUENCING OTHERS",
      H16: "LEADING OTHERS",
      H17: "RESILIENCY",
      H18: "TEAMWORK",
      H19: "ACCOUNTABILITY FOR OTHERS",
      H2: "GOAL ACHIEVEMENT",
      H20: "CONTINUOUS LEARNING",
      H21: "SELF STARTING ABILITY",
      H22: "CONFLICT MANAGEMENT",
      H23: "FLEXIBILITY",
      H3: "RESULTS ORIENTATION",
      H4: "PROBLEM SOLVING",
      H5: "CUSTOMER FOCUS",
      H6: "PLANNING AND ORGANIZING",
      H7: "INTERPERSONAL SKILLS",
      H8: "DIPLOMACY AND TACT",
      H9: "CONCEPTUAL THINKING",
    },
    H_TRIMETRIX23A: {
      H1: "SELF MANAGEMENT",
      H10: "DECISION MAKING",
      H11: "UNDERSTANDING OTHERS",
      H12: "PERSONAL ACCOUNTABILITY",
      H13: "DEVELOPING OTHERS",
      H14: "OBJECTIVE LISTENING",
      H15: "INFLUENCING OTHERS",
      H16: "LEADING OTHERS",
      H17: "RESILIENCY",
      H18: "TEAMWORK",
      H19: "ACCOUNTABILITY FOR OTHERS",
      H2: "GOAL ACHIEVEMENT",
      H20: "CONTINUOUS LEARNING",
      H21: "SELF STARTING ABILITY",
      H22: "CONFLICT MANAGEMENT",
      H23: "FLEXIBILITY",
      H3: "RESULTS ORIENTATION",
      H4: "PROBLEM SOLVING",
      H5: "CUSTOMER FOCUS",
      H6: "PLANNING AND ORGANIZING",
      H7: "INTERPERSONAL SKILLS",
      H8: "DIPLOMACY AND TACT",
      H9: "CONCEPTUAL THINKING",
    },
    L_ALL: {
      ACCOUNT_ID: "ACCOUNT_ID",
      LINK_TYPE: "LINK_TYPE",
      LOGIN: "LINK",
    },
    PATTR_RESP: {
      PATTR_F01_R01: "",
      PATTR_F02_R01: "",
      PATTR_F03_R01: "",
      PATTR_F04_R01: "",
      PATTR_F05_R01: "",
      PATTR_F06_R01: "",
      PATTR_F07_R01: "",
      PATTR_F08_R01: "",
      PATTR_F09_R01: "",
      PATTR_F10_R01: "",
      PATTR_F11_R01: "",
      PATTR_F12_R01: "",
      PATTR_F13_R01: "",
      PATTR_F14_R01: "",
      PATTR_F15_R01: "",
      PATTR_F16_R01: "",
      PATTR_F17_R01: "",
      PATTR_F18_R01: "",
      PATTR_F19_R01: "",
      PATTR_F20_R01: "",
      PATTR_F21_R01: "",
      PATTR_F22_R01: "",
      PATTR_F23_R01: "",
      PATTR_F24_R01: "",
      PATTR_F25_R01: "",
      PATTR_F26_R01: "",
      PATTR_F27_R01: "",
      PATTR_F28_R01: "",
      PATTR_F29_R01: "",
      PATTR_F30_R01: "",
      PATTR_F31_R01: "",
      PATTR_F32_R01: "",
      PATTR_F33_R01: "",
      PATTR_F34_R01: "",
      PATTR_F35_R01: "",
      PATTR_F36_R01: "",
      PATTR_F37_R01: "",
      PATTR_F38_R01: "",
      PATTR_F39_R01: "",
      PATTR_F40_R01: "",
      PATTR_F41_R01: "",
      PATTR_F42_R01: "",
      PATTR_F43_R01: "",
      PATTR_F44_R01: "",
      PATTR_F45_R01: "",
      PATTR_F46_R01: "",
      PATTR_F47_R01: "",
      PATTR_F48_R01: "",
      PATTR_F49_R01: "",
      PATTR_F50_R01: "",
      PATTR_F51_R01: "",
      PATTR_F52_R01: "",
      PATTR_F53_R01: "",
      PATTR_F54_R01: "",
      PATTR_F55_R01: "",
      PATTR_F56_R01: "",
      PATTR_F57_R01: "",
      PATTR_F58_R01: "",
      PATTR_F59_R01: "",
      PATTR_F60_R01: "",
      PATTR_F61_R01: "",
      PATTR_F62_R01: "",
      PATTR_F63_R01: "",
      PATTR_F64_R01: "",
      PATTR_F65_R01: "",
      PATTR_F66_R01: "",
      PATTR_F67_R01: "",
      PATTR_F68_R01: "",
      PATTR_F69_R01: "",
      PATTR_F70_R01: "",
      PATTR_F71_R01: "",
      PATTR_F72_R01: "",
      PATTR_F73_R01: "",
      PATTR_F74_R01: "",
      PATTR_F75_R01: "",
      PATTR_F76_R01: "",
      PATTR_F77_R01: "",
      PATTR_F78_R01: "",
      PATTR_F79_R01: "",
      PATTR_F80_R01: "",
      PATTR_F81_R01: "",
      PATTR_F82_R01: "",
      PATTR_F83_R01: "",
      PATTR_F84_R01: "",
      PATTR_F85_R01: "",
      PATTR_F86_R01: "",
      PATTR_F87_R01: "",
      PATTR_F88_R01: "",
      PATTR_F89_R01: "",
      PATTR_F90_R01: "",
      PATTR_F91_R01: "",
      PATTR_F92_R01: "",
    },
    PIAV2CAR_RESP: {
      PIAV2CAR_F01_R01: "",
      PIAV2CAR_F01_R02: "",
      PIAV2CAR_F01_R03: "",
      PIAV2CAR_F01_R04: "",
      PIAV2CAR_F01_R05: "",
      PIAV2CAR_F01_R06: "",
      PIAV2CAR_F02_R01: "",
      PIAV2CAR_F02_R02: "",
      PIAV2CAR_F02_R03: "",
      PIAV2CAR_F02_R04: "",
      PIAV2CAR_F02_R05: "",
      PIAV2CAR_F02_R06: "",
      PIAV2CAR_F03_R01: "",
      PIAV2CAR_F03_R02: "",
      PIAV2CAR_F03_R03: "",
      PIAV2CAR_F03_R04: "",
      PIAV2CAR_F03_R05: "",
      PIAV2CAR_F03_R06: "",
      PIAV2CAR_F04_R01: "",
      PIAV2CAR_F04_R02: "",
      PIAV2CAR_F04_R03: "",
      PIAV2CAR_F04_R04: "",
      PIAV2CAR_F04_R05: "",
      PIAV2CAR_F04_R06: "",
      PIAV2CAR_F05_R01: "",
      PIAV2CAR_F05_R02: "",
      PIAV2CAR_F05_R03: "",
      PIAV2CAR_F05_R04: "",
      PIAV2CAR_F05_R05: "",
      PIAV2CAR_F05_R06: "",
      PIAV2CAR_F06_R01: "",
      PIAV2CAR_F06_R02: "",
      PIAV2CAR_F06_R03: "",
      PIAV2CAR_F06_R04: "",
      PIAV2CAR_F06_R05: "",
      PIAV2CAR_F06_R06: "",
      PIAV2CAR_F07_R01: "",
      PIAV2CAR_F07_R02: "",
      PIAV2CAR_F07_R03: "",
      PIAV2CAR_F07_R04: "",
      PIAV2CAR_F07_R05: "",
      PIAV2CAR_F07_R06: "",
      PIAV2CAR_F08_R01: "",
      PIAV2CAR_F08_R02: "",
      PIAV2CAR_F08_R03: "",
      PIAV2CAR_F08_R04: "",
      PIAV2CAR_F08_R05: "",
      PIAV2CAR_F08_R06: "",
      PIAV2CAR_F09_R01: "",
      PIAV2CAR_F09_R02: "",
      PIAV2CAR_F09_R03: "",
      PIAV2CAR_F09_R04: "",
      PIAV2CAR_F09_R05: "",
      PIAV2CAR_F09_R06: "",
      PIAV2CAR_F10_R01: "",
      PIAV2CAR_F10_R02: "",
      PIAV2CAR_F10_R03: "",
      PIAV2CAR_F10_R04: "",
      PIAV2CAR_F10_R05: "",
      PIAV2CAR_F10_R06: "",
      PIAV2CAR_F11_R01: "",
      PIAV2CAR_F11_R02: "",
      PIAV2CAR_F11_R03: "",
      PIAV2CAR_F11_R04: "",
      PIAV2CAR_F11_R05: "",
      PIAV2CAR_F11_R06: "",
      PIAV2CAR_F12_R01: "",
      PIAV2CAR_F12_R02: "",
      PIAV2CAR_F12_R03: "",
      PIAV2CAR_F12_R04: "",
      PIAV2CAR_F12_R05: "",
      PIAV2CAR_F12_R06: "",
    },
    PIAV2_RESP: {
      PIAV2_F01_R01: "",
      PIAV2_F01_R02: "",
      PIAV2_F01_R03: "",
      PIAV2_F01_R04: "",
      PIAV2_F01_R05: "",
      PIAV2_F01_R06: "",
      PIAV2_F02_R01: "",
      PIAV2_F02_R02: "",
      PIAV2_F02_R03: "",
      PIAV2_F02_R04: "",
      PIAV2_F02_R05: "",
      PIAV2_F02_R06: "",
      PIAV2_F03_R01: "",
      PIAV2_F03_R02: "",
      PIAV2_F03_R03: "",
      PIAV2_F03_R04: "",
      PIAV2_F03_R05: "",
      PIAV2_F03_R06: "",
      PIAV2_F04_R01: "",
      PIAV2_F04_R02: "",
      PIAV2_F04_R03: "",
      PIAV2_F04_R04: "",
      PIAV2_F04_R05: "",
      PIAV2_F04_R06: "",
      PIAV2_F05_R01: "",
      PIAV2_F05_R02: "",
      PIAV2_F05_R03: "",
      PIAV2_F05_R04: "",
      PIAV2_F05_R05: "",
      PIAV2_F05_R06: "",
      PIAV2_F06_R01: "",
      PIAV2_F06_R02: "",
      PIAV2_F06_R03: "",
      PIAV2_F06_R04: "",
      PIAV2_F06_R05: "",
      PIAV2_F06_R06: "",
      PIAV2_F07_R01: "",
      PIAV2_F07_R02: "",
      PIAV2_F07_R03: "",
      PIAV2_F07_R04: "",
      PIAV2_F07_R05: "",
      PIAV2_F07_R06: "",
      PIAV2_F08_R01: "",
      PIAV2_F08_R02: "",
      PIAV2_F08_R03: "",
      PIAV2_F08_R04: "",
      PIAV2_F08_R05: "",
      PIAV2_F08_R06: "",
      PIAV2_F09_R01: "",
      PIAV2_F09_R02: "",
      PIAV2_F09_R03: "",
      PIAV2_F09_R04: "",
      PIAV2_F09_R05: "",
      PIAV2_F09_R06: "",
      PIAV2_F10_R01: "",
      PIAV2_F10_R02: "",
      PIAV2_F10_R03: "",
      PIAV2_F10_R04: "",
      PIAV2_F10_R05: "",
      PIAV2_F10_R06: "",
      PIAV2_F11_R01: "",
      PIAV2_F11_R02: "",
      PIAV2_F11_R03: "",
      PIAV2_F11_R04: "",
      PIAV2_F11_R05: "",
      PIAV2_F11_R06: "",
      PIAV2_F12_R01: "",
      PIAV2_F12_R02: "",
      PIAV2_F12_R03: "",
      PIAV2_F12_R04: "",
      PIAV2_F12_R05: "",
      PIAV2_F12_R06: "",
    },
    PIAV_RESP: {
      PIAV_F01_R01: "",
      PIAV_F01_R02: "",
      PIAV_F01_R03: "",
      PIAV_F01_R04: "",
      PIAV_F01_R05: "",
      PIAV_F01_R06: "",
      PIAV_F02_R01: "",
      PIAV_F02_R02: "",
      PIAV_F02_R03: "",
      PIAV_F02_R04: "",
      PIAV_F02_R05: "",
      PIAV_F02_R06: "",
      PIAV_F03_R01: "",
      PIAV_F03_R02: "",
      PIAV_F03_R03: "",
      PIAV_F03_R04: "",
      PIAV_F03_R05: "",
      PIAV_F03_R06: "",
      PIAV_F04_R01: "",
      PIAV_F04_R02: "",
      PIAV_F04_R03: "",
      PIAV_F04_R04: "",
      PIAV_F04_R05: "",
      PIAV_F04_R06: "",
      PIAV_F05_R01: "",
      PIAV_F05_R02: "",
      PIAV_F05_R03: "",
      PIAV_F05_R04: "",
      PIAV_F05_R05: "",
      PIAV_F05_R06: "",
      PIAV_F06_R01: "",
      PIAV_F06_R02: "",
      PIAV_F06_R03: "",
      PIAV_F06_R04: "",
      PIAV_F06_R05: "",
      PIAV_F06_R06: "",
      PIAV_F07_R01: "",
      PIAV_F07_R02: "",
      PIAV_F07_R03: "",
      PIAV_F07_R04: "",
      PIAV_F07_R05: "",
      PIAV_F07_R06: "",
      PIAV_F08_R01: "",
      PIAV_F08_R02: "",
      PIAV_F08_R03: "",
      PIAV_F08_R04: "",
      PIAV_F08_R05: "",
      PIAV_F08_R06: "",
      PIAV_F09_R01: "",
      PIAV_F09_R02: "",
      PIAV_F09_R03: "",
      PIAV_F09_R04: "",
      PIAV_F09_R05: "",
      PIAV_F09_R06: "",
      PIAV_F10_R01: "",
      PIAV_F10_R02: "",
      PIAV_F10_R03: "",
      PIAV_F10_R04: "",
      PIAV_F10_R05: "",
      PIAV_F10_R06: "",
      PIAV_F11_R01: "",
      PIAV_F11_R02: "",
      PIAV_F11_R03: "",
      PIAV_F11_R04: "",
      PIAV_F11_R05: "",
      PIAV_F11_R06: "",
      PIAV_F12_R01: "",
      PIAV_F12_R02: "",
      PIAV_F12_R03: "",
      PIAV_F12_R04: "",
      PIAV_F12_R05: "",
      PIAV_F12_R06: "",
    },
    PL_ALL: {
      PL1T: "",
      PL1MX: "",
      PL1MY: "",
      PL1C: "",
      PL1I: "",
      PL1S: "",
      PL2T: "",
      PL2MX: "",
      PL2MY: "",
      PL2C: "",
      PL2I: "",
      PL2S: "",
      PL3T: "",
      PL3MX: "",
      PL3MY: "",
      PL3C: "",
      PL3I: "",
      PL3S: "",
      PL4T: "",
      PL4MX: "",
      PL4MY: "",
      PL4C: "",
      PL4I: "",
      PL4S: "",
      PL5T: "",
      PL5MX: "",
      PL5MY: "",
      PL5C: "",
      PL5I: "",
      PL5S: "",
      PL_NORM: "",
      PL_ELAPSED: "",
      PL2_NORM: "",
      PL2_ELAPSED: "",
      PL_RESPONSES: "",
      PL2_RESPONSES: "",
    },
    PL_RESP: {
      PRIORITIZEDLEADER_F01_R01: "",
      PRIORITIZEDLEADER_F02_R01: "",
      PRIORITIZEDLEADER_F03_R01: "",
      PRIORITIZEDLEADER_F04_R01: "",
      PRIORITIZEDLEADER_F05_R01: "",
      PRIORITIZEDLEADER_F06_R01: "",
      PRIORITIZEDLEADER_F07_R01: "",
      PRIORITIZEDLEADER_F08_R01: "",
      PRIORITIZEDLEADER_F09_R01: "",
      PRIORITIZEDLEADER_F10_R01: "",
      PRIORITIZEDLEADER_F11_R01: "",
      PRIORITIZEDLEADER_F12_R01: "",
      PRIORITIZEDLEADER_F13_R01: "",
      PRIORITIZEDLEADER_F14_R01: "",
      PRIORITIZEDLEADER_F15_R01: "",
      PRIORITIZEDLEADER_F16_R01: "",
      PRIORITIZEDLEADER_F17_R01: "",
      PRIORITIZEDLEADER_F18_R01: "",
      PRIORITIZEDLEADER_F19_R01: "",
      PRIORITIZEDLEADER_F20_R01: "",
      PRIORITIZEDLEADER_F21_R01: "",
      PRIORITIZEDLEADER_F22_R01: "",
      PRIORITIZEDLEADER_F23_R01: "",
      PRIORITIZEDLEADER_F24_R01: "",
      PRIORITIZEDLEADER_F25_R01: "",
      PRIORITIZEDLEADER_F26_R01: "",
      PRIORITIZEDLEADER_F27_R01: "",
      PRIORITIZEDLEADER_F28_R01: "",
      PRIORITIZEDLEADER_F29_R01: "",
      PRIORITIZEDLEADER_F30_R01: "",
      PRIORITIZEDLEADER_F31_R01: "",
      PRIORITIZEDLEADER_F32_R01: "",
      PRIORITIZEDLEADER_F33_R01: "",
      PRIORITIZEDLEADER_F34_R01: "",
      PRIORITIZEDLEADER_F35_R01: "",
      PRIORITIZEDLEADER_F36_R01: "",
      PRIORITIZEDLEADER_F37_R01: "",
      PRIORITIZEDLEADER_F38_R01: "",
      PRIORITIZEDLEADER_F39_R01: "",
      PRIORITIZEDLEADER_F40_R01: "",
      PRIORITIZEDLEADER_F41_R01: "",
      PRIORITIZEDLEADER_F42_R01: "",
      PRIORITIZEDLEADER_F43_R01: "",
      PRIORITIZEDLEADER_F44_R01: "",
      PRIORITIZEDLEADER_F45_R01: "",
      PRIORITIZEDLEADER_F46_R01: "",
      PRIORITIZEDLEADER_F47_R01: "",
      PRIORITIZEDLEADER_F48_R01: "",
      PRIORITIZEDLEADER_F49_R01: "",
      PRIORITIZEDLEADER_F50_R01: "",
    },
    PL2_RESP: {
      PRIORITIZEDLEADER2_F01_R01: "",
      PRIORITIZEDLEADER2_F01_R02: "",
      PRIORITIZEDLEADER2_F01_R03: "",
      PRIORITIZEDLEADER2_F01_R04: "",
      PRIORITIZEDLEADER2_F01_R05: "",
    },
    PSSI_ALL: {
      PSSI1: "ANALYTICAL PROBLEM SOLVING",
      PSSI10: "FLEXIBILITY",
      PSSI11: "FUTURISTIC THINKING",
      PSSI12: "GOAL ORIENTATION",
      PSSI13: "INTERPERSONAL SKILLS",
      PSSI14: "LEADERSHIP",
      PSSI15: "MANAGEMENT",
      PSSI16: "NEGOTIATION",
      PSSI17: "PERSONAL EFFECTIVENESS",
      PSSI18: "PERSUASION",
      PSSI19: "PLANNING/ORGANIZING",
      PSSI2: "CONFLICT MANAGEMENT",
      PSSI20: "PRESENTING",
      PSSI21: "SELF-MANAGEMENT (TIME AND PRIORITIES)",
      PSSI22: "TEAMWORK",
      PSSI23: "WRITTEN COMMUNICATION",
      PSSI3: "CONTINUOUS LEARNING",
      PSSI4: "CREATIVITY/INNOVATION",
      PSSI5: "CUSTOMER SERVICE",
      PSSI6: "DECISION MAKING",
      PSSI7: "DIPLOMACY",
      PSSI8: "EMPATHY",
      PSSI9: "EMPLOYEE DEVELOPMENT/COACHING",
      SEC1_ELAPSED: "",
      SEC1_NORM: "",
      SEC1_RESPONSES: "",
      SEC2_ELAPSED: "",
      SEC2_NORM: "",
      SEC2_RESPONSES: "",
      SEC3_ELAPSED: "",
      SEC3_NORM: "",
      SEC3_RESPONSES: "",
    },
    PSSI_LEGACY: {
      PSSI1: "ANALYTICAL PROBLEM SOLVING",
      PSSI10: "FLEXIBILITY",
      PSSI11: "FUTURISTIC THINKING",
      PSSI12: "GOAL ORIENTATION",
      PSSI13: "INTERPERSONAL SKILLS",
      PSSI14: "LEADERSHIP",
      PSSI15: "MANAGEMENT",
      PSSI16: "NEGOTIATION",
      PSSI17: "PERSONAL EFFECTIVENESS",
      PSSI18: "PERSUASION",
      PSSI19: "PLANNING/ORGANIZING",
      PSSI2: "CONFLICT MANAGEMENT",
      PSSI20: "PRESENTING",
      PSSI21: "SELF-MANAGEMENT (TIME AND PRIORITIES)",
      PSSI22: "TEAMWORK",
      PSSI23: "WRITTEN COMMUNICATION",
      PSSI3: "CONTINUOUS LEARNING",
      PSSI4: "CREATIVITY/INNOVATION",
      PSSI5: "CUSTOMER SERVICE",
      PSSI6: "DECISION MAKING",
      PSSI7: "DIPLOMACY",
      PSSI8: "EMPATHY",
      PSSI9: "EMPLOYEE DEVELOPMENT/COACHING",
    },
    PSSI_RESP: {
      SEC1_PSSI_F01_R01: "",
      SEC1_PSSI_F02_R01: "",
      SEC1_PSSI_F03_R01: "",
      SEC1_PSSI_F04_R01: "",
      SEC1_PSSI_F05_R01: "",
      SEC1_PSSI_F06_R01: "",
      SEC1_PSSI_F07_R01: "",
      SEC1_PSSI_F08_R01: "",
      SEC1_PSSI_F09_R01: "",
      SEC1_PSSI_F10_R01: "",
      SEC1_PSSI_F11_R01: "",
      SEC1_PSSI_F12_R01: "",
      SEC1_PSSI_F13_R01: "",
      SEC1_PSSI_F14_R01: "",
      SEC1_PSSI_F15_R01: "",
      SEC1_PSSI_F16_R01: "",
      SEC1_PSSI_F17_R01: "",
      SEC1_PSSI_F18_R01: "",
      SEC1_PSSI_F19_R01: "",
      SEC1_PSSI_F20_R01: "",
      SEC1_PSSI_F21_R01: "",
      SEC1_PSSI_F22_R01: "",
      SEC1_PSSI_F23_R01: "",
      SEC2_PSSI_F01_R01: "",
      SEC2_PSSI_F02_R01: "",
      SEC2_PSSI_F03_R01: "",
      SEC2_PSSI_F04_R01: "",
      SEC2_PSSI_F05_R01: "",
      SEC2_PSSI_F06_R01: "",
      SEC2_PSSI_F07_R01: "",
      SEC2_PSSI_F08_R01: "",
      SEC2_PSSI_F09_R01: "",
      SEC2_PSSI_F10_R01: "",
      SEC2_PSSI_F11_R01: "",
      SEC2_PSSI_F12_R01: "",
      SEC2_PSSI_F13_R01: "",
      SEC2_PSSI_F14_R01: "",
      SEC2_PSSI_F15_R01: "",
      SEC2_PSSI_F16_R01: "",
      SEC2_PSSI_F17_R01: "",
      SEC2_PSSI_F18_R01: "",
      SEC2_PSSI_F19_R01: "",
      SEC2_PSSI_F20_R01: "",
      SEC2_PSSI_F21_R01: "",
      SEC2_PSSI_F22_R01: "",
      SEC2_PSSI_F23_R01: "",
      SEC2_PSSI_F24_R01: "",
      SEC2_PSSI_F25_R01: "",
      SEC2_PSSI_F26_R01: "",
      SEC2_PSSI_F27_R01: "",
      SEC2_PSSI_F28_R01: "",
      SEC2_PSSI_F29_R01: "",
      SEC2_PSSI_F30_R01: "",
      SEC2_PSSI_F31_R01: "",
      SEC2_PSSI_F32_R01: "",
      SEC2_PSSI_F33_R01: "",
      SEC2_PSSI_F34_R01: "",
      SEC2_PSSI_F35_R01: "",
      SEC2_PSSI_F36_R01: "",
      SEC2_PSSI_F37_R01: "",
      SEC2_PSSI_F38_R01: "",
      SEC2_PSSI_F39_R01: "",
      SEC2_PSSI_F40_R01: "",
      SEC2_PSSI_F41_R01: "",
      SEC2_PSSI_F42_R01: "",
      SEC3_PSSI_F01_R01: "",
      SEC3_PSSI_F02_R01: "",
      SEC3_PSSI_F03_R01: "",
      SEC3_PSSI_F04_R01: "",
      SEC3_PSSI_F05_R01: "",
      SEC3_PSSI_F06_R01: "",
      SEC3_PSSI_F07_R01: "",
      SEC3_PSSI_F08_R01: "",
      SEC3_PSSI_F09_R01: "",
      SEC3_PSSI_F10_R01: "",
      SEC3_PSSI_F11_R01: "",
      SEC3_PSSI_F12_R01: "",
      SEC3_PSSI_F13_R01: "",
      SEC3_PSSI_F14_R01: "",
      SEC3_PSSI_F15_R01: "",
      SEC3_PSSI_F16_R01: "",
      SEC3_PSSI_F17_R01: "",
      SEC3_PSSI_F18_R01: "",
      SEC3_PSSI_F19_R01: "",
      SEC3_PSSI_F20_R01: "",
      SEC3_PSSI_F21_R01: "",
      SEC3_PSSI_F22_R01: "",
      SEC3_PSSI_F23_R01: "",
      SEC3_PSSI_F24_R01: "",
      SEC3_PSSI_F25_R01: "",
      SEC3_PSSI_F26_R01: "",
      SEC3_PSSI_F27_R01: "",
      SEC3_PSSI_F28_R01: "",
      SEC3_PSSI_F29_R01: "",
      SEC3_PSSI_F30_R01: "",
      SEC3_PSSI_F31_R01: "",
      SEC3_PSSI_F32_R01: "",
      SEC3_PSSI_F33_R01: "",
      SEC3_PSSI_F34_R01: "",
      SEC3_PSSI_F35_R01: "",
      SEC3_PSSI_F36_R01: "",
      SEC3_PSSI_F37_R01: "",
      SEC3_PSSI_F38_R01: "",
      SEC3_PSSI_F39_R01: "",
      SEC3_PSSI_F40_R01: "",
      SEC3_PSSI_F41_R01: "",
      SEC3_PSSI_F42_R01: "",
      SEC3_PSSI_F43_R01: "",
      SEC3_PSSI_F44_R01: "",
      SEC3_PSSI_F45_R01: "",
      SEC3_PSSI_F46_R01: "",
      SEC3_PSSI_F47_R01: "",
      SEC3_PSSI_F48_R01: "",
      SEC3_PSSI_F49_R01: "",
      SEC3_PSSI_F50_R01: "",
    },
    RC_RESP: {
      RC_F01_R01: "",
      RC_F02_R01: "",
      RC_F03_R01: "",
      RC_F04_R01: "",
      RC_F05_R01: "",
      RC_F06_R01: "",
      RC_F07_R01: "",
      RC_F08_R01: "",
      RC_F09_R01: "",
      RC_F10_R01: "",
      RC_F11_R01: "",
      RC_F12_R01: "",
      RC_F13_R01: "",
      RC_F14_R01: "",
      RC_F15_R01: "",
      RC_F16_R01: "",
      RC_F17_R01: "",
      RC_F18_R01: "",
      RC_F19_R01: "",
      RC_F20_R01: "",
      RC_F21_R01: "",
      RC_F22_R01: "",
      RC_F23_R01: "",
      RC_F24_R01: "",
    },
    RPT_ALL: {
      CREATED_AT: "CREATED",
      DATA_ENTRY: "",
      EXTENDED_FIELDS: "",
      IP_ADDR: "",
      COUNTRY_CODE: "",
      MOBILE: "",
      REPORTVIEW_ID: "",
      REPORT_DATE: "",
      REPORT_ID: "",
      RESPONSE_LANGUAGE: "",
      RESPONSE_ISO_LANGUAGE: "",
      SCORES_DOC: "",
      STUDY_ID: "",
      UPDATED_AT: "MODIFIED",
      USER_AGENT: "",
      SEEDS: "",
    },
    RPT_LEGACY: { REPORT_ID: "" },
    R_ALL: {
      ANONYMOUS: "",
      COMPANY: "COMPANY",
      DIGEST: "DIGEST",
      EMAIL: "EMAIL",
      EMAIL_DIGEST: "EMAIL",
      FIRST_NAME: "FIRST NAME",
      FIRST_NAME_DIGEST: "",
      FOREIGN_ID: "FOREIGN ID",
      GENDER: "",
      LAST_NAME: "LAST NAME",
      LAST_NAME_DIGEST: "",
      PASSWD: "PASSWORD",
      POSITION_JOB: "POSITION",
      RESPONDENT_ID: "",
      FINGERPRINT: "",
    },
    R_LEGACY: {
      COMPANY: "COMPANY",
      CREATED_AT: "CREATED",
      EMAIL: "",
      FIRST_NAME: "FIRST NAME",
      GENDER: "",
      LAST_NAME: "LAST NAME",
      LOGIN: "LINK",
      PASSWD: "PASSWORD",
      POSITION_JOB: "POSITION",
    },
    SA2CAR_RESP: {
      SA2CAR_F01_R01: "",
      SA2CAR_F01_R02: "",
      SA2CAR_F02_R01: "",
      SA2CAR_F02_R02: "",
      SA2CAR_F03_R01: "",
      SA2CAR_F03_R02: "",
      SA2CAR_F04_R01: "",
      SA2CAR_F04_R02: "",
      SA2CAR_F05_R01: "",
      SA2CAR_F05_R02: "",
      SA2CAR_F06_R01: "",
      SA2CAR_F06_R02: "",
      SA2CAR_F07_R01: "",
      SA2CAR_F07_R02: "",
      SA2CAR_F08_R01: "",
      SA2CAR_F08_R02: "",
      SA2CAR_F09_R01: "",
      SA2CAR_F09_R02: "",
      SA2CAR_F10_R01: "",
      SA2CAR_F10_R02: "",
      SA2CAR_F11_R01: "",
      SA2CAR_F11_R02: "",
      SA2CAR_F12_R01: "",
      SA2CAR_F12_R02: "",
      SA2CAR_F13_R01: "",
      SA2CAR_F13_R02: "",
      SA2CAR_F14_R01: "",
      SA2CAR_F14_R02: "",
      SA2CAR_F15_R01: "",
      SA2CAR_F15_R02: "",
      SA2CAR_F16_R01: "",
      SA2CAR_F16_R02: "",
      SA2CAR_F17_R01: "",
      SA2CAR_F17_R02: "",
      SA2CAR_F18_R01: "",
      SA2CAR_F18_R02: "",
      SA2CAR_F19_R01: "",
      SA2CAR_F19_R02: "",
      SA2CAR_F20_R01: "",
      SA2CAR_F20_R02: "",
      SA2CAR_F21_R01: "",
      SA2CAR_F21_R02: "",
      SA2CAR_F22_R01: "",
      SA2CAR_F22_R02: "",
      SA2CAR_F23_R01: "",
      SA2CAR_F23_R02: "",
      SA2CAR_F24_R01: "",
      SA2CAR_F24_R02: "",
    },
    SA2R4_RESP: {
      SA2R4_F01_R01: "",
      SA2R4_F01_R02: "",
      SA2R4_F01_R03: "",
      SA2R4_F01_R04: "",
      SA2R4_F02_R01: "",
      SA2R4_F02_R02: "",
      SA2R4_F02_R03: "",
      SA2R4_F02_R04: "",
      SA2R4_F03_R01: "",
      SA2R4_F03_R02: "",
      SA2R4_F03_R03: "",
      SA2R4_F03_R04: "",
      SA2R4_F04_R01: "",
      SA2R4_F04_R02: "",
      SA2R4_F04_R03: "",
      SA2R4_F04_R04: "",
      SA2R4_F05_R01: "",
      SA2R4_F05_R02: "",
      SA2R4_F05_R03: "",
      SA2R4_F05_R04: "",
      SA2R4_F06_R01: "",
      SA2R4_F06_R02: "",
      SA2R4_F06_R03: "",
      SA2R4_F06_R04: "",
      SA2R4_F07_R01: "",
      SA2R4_F07_R02: "",
      SA2R4_F07_R03: "",
      SA2R4_F07_R04: "",
      SA2R4_F08_R01: "",
      SA2R4_F08_R02: "",
      SA2R4_F08_R03: "",
      SA2R4_F08_R04: "",
      SA2R4_F09_R01: "",
      SA2R4_F09_R02: "",
      SA2R4_F09_R03: "",
      SA2R4_F09_R04: "",
      SA2R4_F10_R01: "",
      SA2R4_F10_R02: "",
      SA2R4_F10_R03: "",
      SA2R4_F10_R04: "",
      SA2R4_F11_R01: "",
      SA2R4_F11_R02: "",
      SA2R4_F11_R03: "",
      SA2R4_F11_R04: "",
      SA2R4_F12_R01: "",
      SA2R4_F12_R02: "",
      SA2R4_F12_R03: "",
      SA2R4_F12_R04: "",
      SA2R4_F13_R01: "",
      SA2R4_F13_R02: "",
      SA2R4_F13_R03: "",
      SA2R4_F13_R04: "",
      SA2R4_F14_R01: "",
      SA2R4_F14_R02: "",
      SA2R4_F14_R03: "",
      SA2R4_F14_R04: "",
      SA2R4_F15_R01: "",
      SA2R4_F15_R02: "",
      SA2R4_F15_R03: "",
      SA2R4_F15_R04: "",
      SA2R4_F16_R01: "",
      SA2R4_F16_R02: "",
      SA2R4_F16_R03: "",
      SA2R4_F16_R04: "",
      SA2R4_F17_R01: "",
      SA2R4_F17_R02: "",
      SA2R4_F17_R03: "",
      SA2R4_F17_R04: "",
      SA2R4_F18_R01: "",
      SA2R4_F18_R02: "",
      SA2R4_F18_R03: "",
      SA2R4_F18_R04: "",
      SA2R4_F19_R01: "",
      SA2R4_F19_R02: "",
      SA2R4_F19_R03: "",
      SA2R4_F19_R04: "",
      SA2R4_F20_R01: "",
      SA2R4_F20_R02: "",
      SA2R4_F20_R03: "",
      SA2R4_F20_R04: "",
      SA2R4_F21_R01: "",
      SA2R4_F21_R02: "",
      SA2R4_F21_R03: "",
      SA2R4_F21_R04: "",
      SA2R4_F22_R01: "",
      SA2R4_F22_R02: "",
      SA2R4_F22_R03: "",
      SA2R4_F22_R04: "",
      SA2R4_F23_R01: "",
      SA2R4_F23_R02: "",
      SA2R4_F23_R03: "",
      SA2R4_F23_R04: "",
      SA2R4_F24_R01: "",
      SA2R4_F24_R02: "",
      SA2R4_F24_R03: "",
      SA2R4_F24_R04: "",
    },
    SA2_RESP: {
      SA2_F01_R01: "",
      SA2_F01_R02: "",
      SA2_F02_R01: "",
      SA2_F02_R02: "",
      SA2_F03_R01: "",
      SA2_F03_R02: "",
      SA2_F04_R01: "",
      SA2_F04_R02: "",
      SA2_F05_R01: "",
      SA2_F05_R02: "",
      SA2_F06_R01: "",
      SA2_F06_R02: "",
      SA2_F07_R01: "",
      SA2_F07_R02: "",
      SA2_F08_R01: "",
      SA2_F08_R02: "",
      SA2_F09_R01: "",
      SA2_F09_R02: "",
      SA2_F10_R01: "",
      SA2_F10_R02: "",
      SA2_F11_R01: "",
      SA2_F11_R02: "",
      SA2_F12_R01: "",
      SA2_F12_R02: "",
      SA2_F13_R01: "",
      SA2_F13_R02: "",
      SA2_F14_R01: "",
      SA2_F14_R02: "",
      SA2_F15_R01: "",
      SA2_F15_R02: "",
      SA2_F16_R01: "",
      SA2_F16_R02: "",
      SA2_F17_R01: "",
      SA2_F17_R02: "",
      SA2_F18_R01: "",
      SA2_F18_R02: "",
      SA2_F19_R01: "",
      SA2_F19_R02: "",
      SA2_F20_R01: "",
      SA2_F20_R02: "",
      SA2_F21_R01: "",
      SA2_F21_R02: "",
      SA2_F22_R01: "",
      SA2_F22_R02: "",
      SA2_F23_R01: "",
      SA2_F23_R02: "",
      SA2_F24_R01: "",
      SA2_F24_R02: "",
    },
    SAR4_RESP: {
      SAR4_F01_R01: "",
      SAR4_F01_R02: "",
      SAR4_F01_R03: "",
      SAR4_F01_R04: "",
      SAR4_F02_R01: "",
      SAR4_F02_R02: "",
      SAR4_F02_R03: "",
      SAR4_F02_R04: "",
      SAR4_F03_R01: "",
      SAR4_F03_R02: "",
      SAR4_F03_R03: "",
      SAR4_F03_R04: "",
      SAR4_F04_R01: "",
      SAR4_F04_R02: "",
      SAR4_F04_R03: "",
      SAR4_F04_R04: "",
      SAR4_F05_R01: "",
      SAR4_F05_R02: "",
      SAR4_F05_R03: "",
      SAR4_F05_R04: "",
      SAR4_F06_R01: "",
      SAR4_F06_R02: "",
      SAR4_F06_R03: "",
      SAR4_F06_R04: "",
      SAR4_F07_R01: "",
      SAR4_F07_R02: "",
      SAR4_F07_R03: "",
      SAR4_F07_R04: "",
      SAR4_F08_R01: "",
      SAR4_F08_R02: "",
      SAR4_F08_R03: "",
      SAR4_F08_R04: "",
      SAR4_F09_R01: "",
      SAR4_F09_R02: "",
      SAR4_F09_R03: "",
      SAR4_F09_R04: "",
      SAR4_F10_R01: "",
      SAR4_F10_R02: "",
      SAR4_F10_R03: "",
      SAR4_F10_R04: "",
      SAR4_F11_R01: "",
      SAR4_F11_R02: "",
      SAR4_F11_R03: "",
      SAR4_F11_R04: "",
      SAR4_F12_R01: "",
      SAR4_F12_R02: "",
      SAR4_F12_R03: "",
      SAR4_F12_R04: "",
      SAR4_F13_R01: "",
      SAR4_F13_R02: "",
      SAR4_F13_R03: "",
      SAR4_F13_R04: "",
      SAR4_F14_R01: "",
      SAR4_F14_R02: "",
      SAR4_F14_R03: "",
      SAR4_F14_R04: "",
      SAR4_F15_R01: "",
      SAR4_F15_R02: "",
      SAR4_F15_R03: "",
      SAR4_F15_R04: "",
      SAR4_F16_R01: "",
      SAR4_F16_R02: "",
      SAR4_F16_R03: "",
      SAR4_F16_R04: "",
      SAR4_F17_R01: "",
      SAR4_F17_R02: "",
      SAR4_F17_R03: "",
      SAR4_F17_R04: "",
      SAR4_F18_R01: "",
      SAR4_F18_R02: "",
      SAR4_F18_R03: "",
      SAR4_F18_R04: "",
      SAR4_F19_R01: "",
      SAR4_F19_R02: "",
      SAR4_F19_R03: "",
      SAR4_F19_R04: "",
      SAR4_F20_R01: "",
      SAR4_F20_R02: "",
      SAR4_F20_R03: "",
      SAR4_F20_R04: "",
      SAR4_F21_R01: "",
      SAR4_F21_R02: "",
      SAR4_F21_R03: "",
      SAR4_F21_R04: "",
      SAR4_F22_R01: "",
      SAR4_F22_R02: "",
      SAR4_F22_R03: "",
      SAR4_F22_R04: "",
      SAR4_F23_R01: "",
      SAR4_F23_R02: "",
      SAR4_F23_R03: "",
      SAR4_F23_R04: "",
      SAR4_F24_R01: "",
      SAR4_F24_R02: "",
      SAR4_F24_R03: "",
      SAR4_F24_R04: "",
    },
    SA_RESP: {
      SA_F01_R01: "",
      SA_F01_R02: "",
      SA_F02_R01: "",
      SA_F02_R02: "",
      SA_F03_R01: "",
      SA_F03_R02: "",
      SA_F04_R01: "",
      SA_F04_R02: "",
      SA_F05_R01: "",
      SA_F05_R02: "",
      SA_F06_R01: "",
      SA_F06_R02: "",
      SA_F07_R01: "",
      SA_F07_R02: "",
      SA_F08_R01: "",
      SA_F08_R02: "",
      SA_F09_R01: "",
      SA_F09_R02: "",
      SA_F10_R01: "",
      SA_F10_R02: "",
      SA_F11_R01: "",
      SA_F11_R02: "",
      SA_F12_R01: "",
      SA_F12_R02: "",
      SA_F13_R01: "",
      SA_F13_R02: "",
      SA_F14_R01: "",
      SA_F14_R02: "",
      SA_F15_R01: "",
      SA_F15_R02: "",
      SA_F16_R01: "",
      SA_F16_R02: "",
      SA_F17_R01: "",
      SA_F17_R02: "",
      SA_F18_R01: "",
      SA_F18_R02: "",
      SA_F19_R01: "",
      SA_F19_R02: "",
      SA_F20_R01: "",
      SA_F20_R02: "",
      SA_F21_R01: "",
      SA_F21_R02: "",
      SA_F22_R01: "",
      SA_F22_R02: "",
      SA_F23_R01: "",
      SA_F23_R02: "",
      SA_F24_R01: "",
      SA_F24_R02: "",
    },
    SSI1_RESP: {
      SSI_F01_R01: "",
      SSI_F01_R02: "",
      SSI_F01_R03: "",
      SSI_F01_R04: "",
      SSI_F02_R01: "",
      SSI_F02_R02: "",
      SSI_F02_R03: "",
      SSI_F02_R04: "",
      SSI_F03_R01: "",
      SSI_F03_R02: "",
      SSI_F03_R03: "",
      SSI_F03_R04: "",
      SSI_F04_R01: "",
      SSI_F04_R02: "",
      SSI_F04_R03: "",
      SSI_F04_R04: "",
      SSI_F05_R01: "",
      SSI_F05_R02: "",
      SSI_F05_R03: "",
      SSI_F05_R04: "",
      SSI_F06_R01: "",
      SSI_F06_R02: "",
      SSI_F06_R03: "",
      SSI_F06_R04: "",
      SSI_F07_R01: "",
      SSI_F07_R02: "",
      SSI_F07_R03: "",
      SSI_F07_R04: "",
      SSI_F08_R01: "",
      SSI_F08_R02: "",
      SSI_F08_R03: "",
      SSI_F08_R04: "",
      SSI_F09_R01: "",
      SSI_F09_R02: "",
      SSI_F09_R03: "",
      SSI_F09_R04: "",
      SSI_F10_R01: "",
      SSI_F10_R02: "",
      SSI_F10_R03: "",
      SSI_F10_R04: "",
      SSI_F11_R01: "",
      SSI_F11_R02: "",
      SSI_F11_R03: "",
      SSI_F11_R04: "",
      SSI_F12_R01: "",
      SSI_F12_R02: "",
      SSI_F12_R03: "",
      SSI_F12_R04: "",
      SSI_F13_R01: "",
      SSI_F13_R02: "",
      SSI_F13_R03: "",
      SSI_F13_R04: "",
      SSI_F14_R01: "",
      SSI_F14_R02: "",
      SSI_F14_R03: "",
      SSI_F14_R04: "",
      SSI_F15_R01: "",
      SSI_F15_R02: "",
      SSI_F15_R03: "",
      SSI_F15_R04: "",
      SSI_F16_R01: "",
      SSI_F16_R02: "",
      SSI_F16_R03: "",
      SSI_F16_R04: "",
      SSI_F17_R01: "",
      SSI_F17_R02: "",
      SSI_F17_R03: "",
      SSI_F17_R04: "",
      SSI_F18_R01: "",
      SSI_F18_R02: "",
      SSI_F18_R03: "",
      SSI_F18_R04: "",
      SSI_F19_R01: "",
      SSI_F19_R02: "",
      SSI_F19_R03: "",
      SSI_F19_R04: "",
      SSI_F20_R01: "",
      SSI_F20_R02: "",
      SSI_F20_R03: "",
      SSI_F20_R04: "",
      SSI_F21_R01: "",
      SSI_F21_R02: "",
      SSI_F21_R03: "",
      SSI_F21_R04: "",
      SSI_F22_R01: "",
      SSI_F22_R02: "",
      SSI_F22_R03: "",
      SSI_F22_R04: "",
      SSI_F23_R01: "",
      SSI_F23_R02: "",
      SSI_F23_R03: "",
      SSI_F23_R04: "",
      SSI_F24_R01: "",
      SSI_F24_R02: "",
      SSI_F24_R03: "",
      SSI_F24_R04: "",
      SSI_F25_R01: "",
      SSI_F25_R02: "",
      SSI_F25_R03: "",
      SSI_F25_R04: "",
      SSI_F26_R01: "",
      SSI_F26_R02: "",
      SSI_F26_R03: "",
      SSI_F26_R04: "",
      SSI_F27_R01: "",
      SSI_F27_R02: "",
      SSI_F27_R03: "",
      SSI_F27_R04: "",
      SSI_F28_R01: "",
      SSI_F28_R02: "",
      SSI_F28_R03: "",
      SSI_F28_R04: "",
      SSI_F29_R01: "",
      SSI_F29_R02: "",
      SSI_F29_R03: "",
      SSI_F29_R04: "",
      SSI_F30_R01: "",
      SSI_F30_R02: "",
      SSI_F30_R03: "",
      SSI_F30_R04: "",
      SSI_F31_R01: "",
      SSI_F31_R02: "",
      SSI_F31_R03: "",
      SSI_F31_R04: "",
      SSI_F32_R01: "",
      SSI_F32_R02: "",
      SSI_F32_R03: "",
      SSI_F32_R04: "",
      SSI_F33_R01: "",
      SSI_F33_R02: "",
      SSI_F33_R03: "",
      SSI_F33_R04: "",
      SSI_F34_R01: "",
      SSI_F34_R02: "",
      SSI_F34_R03: "",
      SSI_F34_R04: "",
      SSI_F35_R01: "",
      SSI_F35_R02: "",
      SSI_F35_R03: "",
      SSI_F35_R04: "",
      SSI_F36_R01: "",
      SSI_F36_R02: "",
      SSI_F36_R03: "",
      SSI_F36_R04: "",
      SSI_F37_R01: "",
      SSI_F37_R02: "",
      SSI_F37_R03: "",
      SSI_F37_R04: "",
      SSI_F38_R01: "",
      SSI_F38_R02: "",
      SSI_F38_R03: "",
      SSI_F38_R04: "",
      SSI_F39_R01: "",
      SSI_F39_R02: "",
      SSI_F39_R03: "",
      SSI_F39_R04: "",
      SSI_F40_R01: "",
      SSI_F40_R02: "",
      SSI_F40_R03: "",
      SSI_F40_R04: "",
      SSI_F41_R01: "",
      SSI_F41_R02: "",
      SSI_F41_R03: "",
      SSI_F41_R04: "",
      SSI_F42_R01: "",
      SSI_F42_R02: "",
      SSI_F42_R03: "",
      SSI_F42_R04: "",
      SSI_F43_R01: "",
      SSI_F43_R02: "",
      SSI_F43_R03: "",
      SSI_F43_R04: "",
      SSI_F44_R01: "",
      SSI_F44_R02: "",
      SSI_F44_R03: "",
      SSI_F44_R04: "",
      SSI_F45_R01: "",
      SSI_F45_R02: "",
      SSI_F45_R03: "",
      SSI_F45_R04: "",
      SSI_F46_R01: "",
      SSI_F46_R02: "",
      SSI_F46_R03: "",
      SSI_F46_R04: "",
      SSI_F47_R01: "",
      SSI_F47_R02: "",
      SSI_F47_R03: "",
      SSI_F47_R04: "",
      SSI_F48_R01: "",
      SSI_F48_R02: "",
      SSI_F48_R03: "",
      SSI_F48_R04: "",
      SSI_F49_R01: "",
      SSI_F49_R02: "",
      SSI_F49_R03: "",
      SSI_F49_R04: "",
      SSI_F50_R01: "",
      SSI_F50_R02: "",
      SSI_F50_R03: "",
      SSI_F50_R04: "",
      SSI_F51_R01: "",
      SSI_F51_R02: "",
      SSI_F51_R03: "",
      SSI_F51_R04: "",
      SSI_F52_R01: "",
      SSI_F52_R02: "",
      SSI_F52_R03: "",
      SSI_F52_R04: "",
      SSI_F53_R01: "",
      SSI_F53_R02: "",
      SSI_F53_R03: "",
      SSI_F53_R04: "",
      SSI_F54_R01: "",
      SSI_F54_R02: "",
      SSI_F54_R03: "",
      SSI_F54_R04: "",
    },
    SSI2_RESP: {
      SSI2_F01_R01: "",
      SSI2_F01_R02: "",
      SSI2_F01_R03: "",
      SSI2_F01_R04: "",
      SSI2_F02_R01: "",
      SSI2_F02_R02: "",
      SSI2_F02_R03: "",
      SSI2_F02_R04: "",
      SSI2_F03_R01: "",
      SSI2_F03_R02: "",
      SSI2_F03_R03: "",
      SSI2_F03_R04: "",
      SSI2_F04_R01: "",
      SSI2_F04_R02: "",
      SSI2_F04_R03: "",
      SSI2_F04_R04: "",
      SSI2_F05_R01: "",
      SSI2_F05_R02: "",
      SSI2_F05_R03: "",
      SSI2_F05_R04: "",
      SSI2_F06_R01: "",
      SSI2_F06_R02: "",
      SSI2_F06_R03: "",
      SSI2_F06_R04: "",
      SSI2_F07_R01: "",
      SSI2_F07_R02: "",
      SSI2_F07_R03: "",
      SSI2_F07_R04: "",
      SSI2_F08_R01: "",
      SSI2_F08_R02: "",
      SSI2_F08_R03: "",
      SSI2_F08_R04: "",
      SSI2_F09_R01: "",
      SSI2_F09_R02: "",
      SSI2_F09_R03: "",
      SSI2_F09_R04: "",
      SSI2_F10_R01: "",
      SSI2_F10_R02: "",
      SSI2_F10_R03: "",
      SSI2_F10_R04: "",
      SSI2_F11_R01: "",
      SSI2_F11_R02: "",
      SSI2_F11_R03: "",
      SSI2_F11_R04: "",
      SSI2_F12_R01: "",
      SSI2_F12_R02: "",
      SSI2_F12_R03: "",
      SSI2_F12_R04: "",
      SSI2_F13_R01: "",
      SSI2_F13_R02: "",
      SSI2_F13_R03: "",
      SSI2_F13_R04: "",
      SSI2_F14_R01: "",
      SSI2_F14_R02: "",
      SSI2_F14_R03: "",
      SSI2_F14_R04: "",
      SSI2_F15_R01: "",
      SSI2_F15_R02: "",
      SSI2_F15_R03: "",
      SSI2_F15_R04: "",
      SSI2_F16_R01: "",
      SSI2_F16_R02: "",
      SSI2_F16_R03: "",
      SSI2_F16_R04: "",
      SSI2_F17_R01: "",
      SSI2_F17_R02: "",
      SSI2_F17_R03: "",
      SSI2_F17_R04: "",
      SSI2_F18_R01: "",
      SSI2_F18_R02: "",
      SSI2_F18_R03: "",
      SSI2_F18_R04: "",
      SSI2_F19_R01: "",
      SSI2_F19_R02: "",
      SSI2_F19_R03: "",
      SSI2_F19_R04: "",
      SSI2_F20_R01: "",
      SSI2_F20_R02: "",
      SSI2_F20_R03: "",
      SSI2_F20_R04: "",
      SSI2_F21_R01: "",
      SSI2_F21_R02: "",
      SSI2_F21_R03: "",
      SSI2_F21_R04: "",
      SSI2_F22_R01: "",
      SSI2_F22_R02: "",
      SSI2_F22_R03: "",
      SSI2_F22_R04: "",
      SSI2_F23_R01: "",
      SSI2_F23_R02: "",
      SSI2_F23_R03: "",
      SSI2_F23_R04: "",
      SSI2_F24_R01: "",
      SSI2_F24_R02: "",
      SSI2_F24_R03: "",
      SSI2_F24_R04: "",
      SSI2_F25_R01: "",
      SSI2_F25_R02: "",
      SSI2_F25_R03: "",
      SSI2_F25_R04: "",
      SSI2_F26_R01: "",
      SSI2_F26_R02: "",
      SSI2_F26_R03: "",
      SSI2_F26_R04: "",
      SSI2_F27_R01: "",
      SSI2_F27_R02: "",
      SSI2_F27_R03: "",
      SSI2_F27_R04: "",
      SSI2_F28_R01: "",
      SSI2_F28_R02: "",
      SSI2_F28_R03: "",
      SSI2_F28_R04: "",
      SSI2_F29_R01: "",
      SSI2_F29_R02: "",
      SSI2_F29_R03: "",
      SSI2_F29_R04: "",
      SSI2_F30_R01: "",
      SSI2_F30_R02: "",
      SSI2_F30_R03: "",
      SSI2_F30_R04: "",
      SSI2_F31_R01: "",
      SSI2_F31_R02: "",
      SSI2_F31_R03: "",
      SSI2_F31_R04: "",
      SSI2_F32_R01: "",
      SSI2_F32_R02: "",
      SSI2_F32_R03: "",
      SSI2_F32_R04: "",
      SSI2_F33_R01: "",
      SSI2_F33_R02: "",
      SSI2_F33_R03: "",
      SSI2_F33_R04: "",
      SSI2_F34_R01: "",
      SSI2_F34_R02: "",
      SSI2_F34_R03: "",
      SSI2_F34_R04: "",
      SSI2_F35_R01: "",
      SSI2_F35_R02: "",
      SSI2_F35_R03: "",
      SSI2_F35_R04: "",
      SSI2_F36_R01: "",
      SSI2_F36_R02: "",
      SSI2_F36_R03: "",
      SSI2_F36_R04: "",
      SSI2_F37_R01: "",
      SSI2_F37_R02: "",
      SSI2_F37_R03: "",
      SSI2_F37_R04: "",
      SSI2_F38_R01: "",
      SSI2_F38_R02: "",
      SSI2_F38_R03: "",
      SSI2_F38_R04: "",
      SSI2_F39_R01: "",
      SSI2_F39_R02: "",
      SSI2_F39_R03: "",
      SSI2_F39_R04: "",
      SSI2_F40_R01: "",
      SSI2_F40_R02: "",
      SSI2_F40_R03: "",
      SSI2_F40_R04: "",
      SSI2_F41_R01: "",
      SSI2_F41_R02: "",
      SSI2_F41_R03: "",
      SSI2_F41_R04: "",
      SSI2_F42_R01: "",
      SSI2_F42_R02: "",
      SSI2_F42_R03: "",
      SSI2_F42_R04: "",
      SSI2_F43_R01: "",
      SSI2_F43_R02: "",
      SSI2_F43_R03: "",
      SSI2_F43_R04: "",
      SSI2_F44_R01: "",
      SSI2_F44_R02: "",
      SSI2_F44_R03: "",
      SSI2_F44_R04: "",
      SSI2_F45_R01: "",
      SSI2_F45_R02: "",
      SSI2_F45_R03: "",
      SSI2_F45_R04: "",
      SSI2_F46_R01: "",
      SSI2_F46_R02: "",
      SSI2_F46_R03: "",
      SSI2_F46_R04: "",
      SSI2_F47_R01: "",
      SSI2_F47_R02: "",
      SSI2_F47_R03: "",
      SSI2_F47_R04: "",
      SSI2_F48_R01: "",
      SSI2_F48_R02: "",
      SSI2_F48_R03: "",
      SSI2_F48_R04: "",
      SSI2_F49_R01: "",
      SSI2_F49_R02: "",
      SSI2_F49_R03: "",
      SSI2_F49_R04: "",
      SSI2_F50_R01: "",
      SSI2_F50_R02: "",
      SSI2_F50_R03: "",
      SSI2_F50_R04: "",
      SSI2_F51_R01: "",
      SSI2_F51_R02: "",
      SSI2_F51_R03: "",
      SSI2_F51_R04: "",
      SSI2_F52_R01: "",
      SSI2_F52_R02: "",
      SSI2_F52_R03: "",
      SSI2_F52_R04: "",
      SSI2_F53_R01: "",
      SSI2_F53_R02: "",
      SSI2_F53_R03: "",
      SSI2_F53_R04: "",
      SSI2_F54_R01: "",
      SSI2_F54_R02: "",
      SSI2_F54_R03: "",
      SSI2_F54_R04: "",
      SSI2_F55_R01: "",
      SSI2_F55_R02: "",
      SSI2_F55_R03: "",
      SSI2_F55_R04: "",
      SSI2_F56_R01: "",
      SSI2_F56_R02: "",
      SSI2_F56_R03: "",
      SSI2_F56_R04: "",
      SSI2_F57_R01: "",
      SSI2_F57_R02: "",
      SSI2_F57_R03: "",
      SSI2_F57_R04: "",
      SSI2_F58_R01: "",
      SSI2_F58_R02: "",
      SSI2_F58_R03: "",
      SSI2_F58_R04: "",
      SSI2_F59_R01: "",
      SSI2_F59_R02: "",
      SSI2_F59_R03: "",
      SSI2_F59_R04: "",
      SSI2_F60_R01: "",
      SSI2_F60_R02: "",
      SSI2_F60_R03: "",
      SSI2_F60_R04: "",
      SSI2_F61_R01: "",
      SSI2_F61_R02: "",
      SSI2_F61_R03: "",
      SSI2_F61_R04: "",
      SSI2_F62_R01: "",
      SSI2_F62_R02: "",
      SSI2_F62_R03: "",
      SSI2_F62_R04: "",
      SSI2_F63_R01: "",
      SSI2_F63_R02: "",
      SSI2_F63_R03: "",
      SSI2_F63_R04: "",
      SSI2_F64_R01: "",
      SSI2_F64_R02: "",
      SSI2_F64_R03: "",
      SSI2_F64_R04: "",
      SSI2_F65_R01: "",
      SSI2_F65_R02: "",
      SSI2_F65_R03: "",
      SSI2_F65_R04: "",
      SSI2_F66_R01: "",
      SSI2_F66_R02: "",
      SSI2_F66_R03: "",
      SSI2_F66_R04: "",
      SSI2_F67_R01: "",
      SSI2_F67_R02: "",
      SSI2_F67_R03: "",
      SSI2_F67_R04: "",
    },
    SSI3_RESP: {
      SSI3_F01_R01: "",
      SSI3_F01_R02: "",
      SSI3_F01_R03: "",
      SSI3_F01_R04: "",
      SSI3_F02_R01: "",
      SSI3_F02_R02: "",
      SSI3_F02_R03: "",
      SSI3_F02_R04: "",
      SSI3_F03_R01: "",
      SSI3_F03_R02: "",
      SSI3_F03_R03: "",
      SSI3_F03_R04: "",
      SSI3_F04_R01: "",
      SSI3_F04_R02: "",
      SSI3_F04_R03: "",
      SSI3_F04_R04: "",
      SSI3_F05_R01: "",
      SSI3_F05_R02: "",
      SSI3_F05_R03: "",
      SSI3_F05_R04: "",
      SSI3_F06_R01: "",
      SSI3_F06_R02: "",
      SSI3_F06_R03: "",
      SSI3_F06_R04: "",
      SSI3_F07_R01: "",
      SSI3_F07_R02: "",
      SSI3_F07_R03: "",
      SSI3_F07_R04: "",
      SSI3_F08_R01: "",
      SSI3_F08_R02: "",
      SSI3_F08_R03: "",
      SSI3_F08_R04: "",
      SSI3_F09_R01: "",
      SSI3_F09_R02: "",
      SSI3_F09_R03: "",
      SSI3_F09_R04: "",
      SSI3_F10_R01: "",
      SSI3_F10_R02: "",
      SSI3_F10_R03: "",
      SSI3_F10_R04: "",
      SSI3_F11_R01: "",
      SSI3_F11_R02: "",
      SSI3_F11_R03: "",
      SSI3_F11_R04: "",
      SSI3_F12_R01: "",
      SSI3_F12_R02: "",
      SSI3_F12_R03: "",
      SSI3_F12_R04: "",
      SSI3_F13_R01: "",
      SSI3_F13_R02: "",
      SSI3_F13_R03: "",
      SSI3_F13_R04: "",
      SSI3_F14_R01: "",
      SSI3_F14_R02: "",
      SSI3_F14_R03: "",
      SSI3_F14_R04: "",
      SSI3_F15_R01: "",
      SSI3_F15_R02: "",
      SSI3_F15_R03: "",
      SSI3_F15_R04: "",
      SSI3_F16_R01: "",
      SSI3_F16_R02: "",
      SSI3_F16_R03: "",
      SSI3_F16_R04: "",
      SSI3_F17_R01: "",
      SSI3_F17_R02: "",
      SSI3_F17_R03: "",
      SSI3_F17_R04: "",
      SSI3_F18_R01: "",
      SSI3_F18_R02: "",
      SSI3_F18_R03: "",
      SSI3_F18_R04: "",
      SSI3_F19_R01: "",
      SSI3_F19_R02: "",
      SSI3_F19_R03: "",
      SSI3_F19_R04: "",
      SSI3_F20_R01: "",
      SSI3_F20_R02: "",
      SSI3_F20_R03: "",
      SSI3_F20_R04: "",
      SSI3_F21_R01: "",
      SSI3_F21_R02: "",
      SSI3_F21_R03: "",
      SSI3_F21_R04: "",
      SSI3_F22_R01: "",
      SSI3_F22_R02: "",
      SSI3_F22_R03: "",
      SSI3_F22_R04: "",
      SSI3_F23_R01: "",
      SSI3_F23_R02: "",
      SSI3_F23_R03: "",
      SSI3_F23_R04: "",
      SSI3_F24_R01: "",
      SSI3_F24_R02: "",
      SSI3_F24_R03: "",
      SSI3_F24_R04: "",
      SSI3_F25_R01: "",
      SSI3_F25_R02: "",
      SSI3_F25_R03: "",
      SSI3_F25_R04: "",
      SSI3_F26_R01: "",
      SSI3_F26_R02: "",
      SSI3_F26_R03: "",
      SSI3_F26_R04: "",
      SSI3_F27_R01: "",
      SSI3_F27_R02: "",
      SSI3_F27_R03: "",
      SSI3_F27_R04: "",
      SSI3_F28_R01: "",
      SSI3_F28_R02: "",
      SSI3_F28_R03: "",
      SSI3_F28_R04: "",
      SSI3_F29_R01: "",
      SSI3_F29_R02: "",
      SSI3_F29_R03: "",
      SSI3_F29_R04: "",
      SSI3_F30_R01: "",
      SSI3_F30_R02: "",
      SSI3_F30_R03: "",
      SSI3_F30_R04: "",
      SSI3_F31_R01: "",
      SSI3_F31_R02: "",
      SSI3_F31_R03: "",
      SSI3_F31_R04: "",
      SSI3_F32_R01: "",
      SSI3_F32_R02: "",
      SSI3_F32_R03: "",
      SSI3_F32_R04: "",
      SSI3_F33_R01: "",
      SSI3_F33_R02: "",
      SSI3_F33_R03: "",
      SSI3_F33_R04: "",
      SSI3_F34_R01: "",
      SSI3_F34_R02: "",
      SSI3_F34_R03: "",
      SSI3_F34_R04: "",
      SSI3_F35_R01: "",
      SSI3_F35_R02: "",
      SSI3_F35_R03: "",
      SSI3_F35_R04: "",
      SSI3_F36_R01: "",
      SSI3_F36_R02: "",
      SSI3_F36_R03: "",
      SSI3_F36_R04: "",
      SSI3_F37_R01: "",
      SSI3_F37_R02: "",
      SSI3_F37_R03: "",
      SSI3_F37_R04: "",
      SSI3_F38_R01: "",
      SSI3_F38_R02: "",
      SSI3_F38_R03: "",
      SSI3_F38_R04: "",
      SSI3_F39_R01: "",
      SSI3_F39_R02: "",
      SSI3_F39_R03: "",
      SSI3_F39_R04: "",
      SSI3_F40_R01: "",
      SSI3_F40_R02: "",
      SSI3_F40_R03: "",
      SSI3_F40_R04: "",
      SSI3_F41_R01: "",
      SSI3_F41_R02: "",
      SSI3_F41_R03: "",
      SSI3_F41_R04: "",
      SSI3_F42_R01: "",
      SSI3_F42_R02: "",
      SSI3_F42_R03: "",
      SSI3_F42_R04: "",
      SSI3_F43_R01: "",
      SSI3_F43_R02: "",
      SSI3_F43_R03: "",
      SSI3_F43_R04: "",
      SSI3_F44_R01: "",
      SSI3_F44_R02: "",
      SSI3_F44_R03: "",
      SSI3_F44_R04: "",
      SSI3_F45_R01: "",
      SSI3_F45_R02: "",
      SSI3_F45_R03: "",
      SSI3_F45_R04: "",
      SSI3_F46_R01: "",
      SSI3_F46_R02: "",
      SSI3_F46_R03: "",
      SSI3_F46_R04: "",
      SSI3_F47_R01: "",
      SSI3_F47_R02: "",
      SSI3_F47_R03: "",
      SSI3_F47_R04: "",
      SSI3_F48_R01: "",
      SSI3_F48_R02: "",
      SSI3_F48_R03: "",
      SSI3_F48_R04: "",
    },
    SSI_ALL: {
      SSI_1ST_S1: "TARGET 1ST",
      SSI_1ST_S2: "ADAPT 1ST",
      SSI_1ST_S3: "RESEARCH 1ST",
      SSI_1ST_S4: "GUIDE 1ST",
      SSI_1ST_S5: "EXPLAIN 1ST",
      SSI_1ST_S6: "TRANSITION 1ST",
      SSI_1ST_S7: "GENERAL 1ST",
      SSI_1ST_S8: "TOTAL 1ST",
      SSI_2ND_S1: "TARGET 2ND",
      SSI_2ND_S2: "ADAPT 2ND",
      SSI_2ND_S3: "RESEARCH 2ND",
      SSI_2ND_S4: "GUIDE 2ND",
      SSI_2ND_S5: "EXPLAIN 2ND",
      SSI_2ND_S6: "TRANSITION 2ND",
      SSI_2ND_S7: "GENERAL 2ND",
      SSI_2ND_S8: "TOTAL 2ND",
      SSI_3RD_S1: "TARGET 3RD",
      SSI_3RD_S2: "ADAPT 3RD",
      SSI_3RD_S3: "RESEARCH 3RD",
      SSI_3RD_S4: "GUIDE 3RD",
      SSI_3RD_S5: "EXPLAIN 3RD",
      SSI_3RD_S6: "TRANSITION 3RD",
      SSI_3RD_S7: "GENERAL 3RD",
      SSI_3RD_S8: "TOTAL 3RD",
      SSI_4TH_S1: "TARGET 4TH",
      SSI_4TH_S2: "ADAPT 4TH",
      SSI_4TH_S3: "RESEARCH 4TH",
      SSI_4TH_S4: "GUIDE 4TH",
      SSI_4TH_S5: "EXPLAIN 4TH",
      SSI_4TH_S6: "TRANSITION 4TH",
      SSI_4TH_S7: "GENERAL 4TH",
      SSI_4TH_S8: "TOTAL 4TH",
      SSI_ACUMEN: "SALES ACUMEN",
      SSI_ELAPSED: "",
      SSI_FIRST_S1: "FIRST PROSPECTING",
      SSI_FIRST_S2: "FIRST FIRST IMPRESSION",
      SSI_FIRST_S3: "FIRST QUALIFYING",
      SSI_FIRST_S4: "FIRST DEMONSTRATION",
      SSI_FIRST_S5: "FIRST INFLUENCE",
      SSI_FIRST_S6: "FIRST CLOSE",
      SSI_FIRST_S7: "FIRST GENERAL",
      SSI_FIRST_S8: "FIRST TOTAL",
      SSI_FOURTH_S1: "FOURTH PROSPECTING",
      SSI_FOURTH_S2: "FOURTH FIRST IMPRESSION",
      SSI_FOURTH_S3: "FOURTH QUALIFYING",
      SSI_FOURTH_S4: "FOURTH DEMONSTRATION",
      SSI_FOURTH_S5: "FOURTH INFLUENCE",
      SSI_FOURTH_S6: "FOURTH CLOSE",
      SSI_FOURTH_S7: "FOURTH GENERAL",
      SSI_FOURTH_S8: "FOURTH TOTAL",
      SSI_LEAST_S1: "LEAST PROSPECTING",
      SSI_LEAST_S2: "LEAST FIRST IMPRESSION",
      SSI_LEAST_S3: "LEAST QUALIFYING",
      SSI_LEAST_S4: "LEAST DEMONSTRATION",
      SSI_LEAST_S5: "LEAST INFLUENCE",
      SSI_LEAST_S6: "LEAST CLOSE",
      SSI_LEAST_S7: "LEAST GENERAL",
      SSI_LEAST_S8: "LEAST TOTAL",
      SSI_NORM: "",
      SSI_PRISEC_S1: "PRISEC PROSPECTING",
      SSI_PRISEC_S2: "PRISEC FIRST IMPRESSION",
      SSI_PRISEC_S3: "PRISEC QUALIFYING",
      SSI_PRISEC_S4: "PRISEC DEMONSTRATION",
      SSI_PRISEC_S5: "PRISEC INFLUENCE",
      SSI_PRISEC_S6: "PRISEC CLOSE",
      SSI_PRISEC_S7: "PRISEC GENERAL",
      SSI_PRISEC_S8: "PRISEC TOTAL",
      SSI_PRI_S1: "PRIMARY PROSPECTING",
      SSI_PRI_S2: "PRIMARY FIRST IMPRESSION",
      SSI_PRI_S3: "PRIMARY QUALIFYING",
      SSI_PRI_S4: "PRIMARY DEMONSTRATION",
      SSI_PRI_S5: "PRIMARY INFLUENCE",
      SSI_PRI_S6: "PRIMARY CLOSE",
      SSI_PRI_S7: "PRIMARY GENERAL",
      SSI_PRI_S8: "PRIMARY TOTAL",
      SSI_RESPONSES: "",
      SSI_SECOND_S1: "SECOND PROSPECTING",
      SSI_SECOND_S2: "SECOND FIRST IMPRESSION",
      SSI_SECOND_S3: "SECOND QUALIFYING",
      SSI_SECOND_S4: "SECOND DEMONSTRATION",
      SSI_SECOND_S5: "SECOND INFLUENCE",
      SSI_SECOND_S6: "SECOND CLOSE",
      SSI_SECOND_S7: "SECOND GENERAL",
      SSI_SECOND_S8: "SECOND TOTAL",
      SSI_THIRD_S1: "THIRD PROSPECTING",
      SSI_THIRD_S2: "THIRD FIRST IMPRESSION",
      SSI_THIRD_S3: "THIRD QUALIFYING",
      SSI_THIRD_S4: "THIRD DEMONSTRATION",
      SSI_THIRD_S5: "THIRD INFLUENCE",
      SSI_THIRD_S6: "THIRD CLOSE",
      SSI_THIRD_S7: "THIRD GENERAL",
      SSI_THIRD_S8: "THIRD TOTAL",
      SSI_VERSION: "",
    },
    SSI_LEGACY: {
      SSI_ELAPSED: "",
      SSI_FIRST_S1: "FIRST PROSPECTING",
      SSI_FIRST_S2: "FIRST FIRST IMPRESSION",
      SSI_FIRST_S3: "FIRST QUALIFYING",
      SSI_FIRST_S4: "FIRST DEMONSTRATION",
      SSI_FIRST_S5: "FIRST INFLUENCE",
      SSI_FIRST_S6: "FIRST CLOSE",
      SSI_FIRST_S7: "FIRST GENERAL",
      SSI_FIRST_S8: "FIRST TOTAL",
      SSI_FOURTH_S1: "FOURTH PROSPECTING",
      SSI_FOURTH_S2: "FOURTH FIRST IMPRESSION",
      SSI_FOURTH_S3: "FOURTH QUALIFYING",
      SSI_FOURTH_S4: "FOURTH DEMONSTRATION",
      SSI_FOURTH_S5: "FOURTH INFLUENCE",
      SSI_FOURTH_S6: "FOURTH CLOSE",
      SSI_FOURTH_S7: "FOURTH GENERAL",
      SSI_FOURTH_S8: "FOURTH TOTAL",
      SSI_LEAST_S1: "LEAST PROSPECTING",
      SSI_LEAST_S2: "LEAST FIRST IMPRESSION",
      SSI_LEAST_S3: "LEAST QUALIFYING",
      SSI_LEAST_S4: "LEAST DEMONSTRATION",
      SSI_LEAST_S5: "LEAST INFLUENCE",
      SSI_LEAST_S6: "LEAST CLOSE",
      SSI_LEAST_S7: "LEAST GENERAL",
      SSI_LEAST_S8: "LEAST TOTAL",
      SSI_NORM: "",
      SSI_PRISEC_S1: "PRISEC PROSPECTING",
      SSI_PRISEC_S2: "PRISEC FIRST IMPRESSION",
      SSI_PRISEC_S3: "PRISEC QUALIFYING",
      SSI_PRISEC_S4: "PRISEC DEMONSTRATION",
      SSI_PRISEC_S5: "PRISEC INFLUENCE",
      SSI_PRISEC_S6: "PRISEC CLOSE",
      SSI_PRISEC_S7: "PRISEC GENERAL",
      SSI_PRISEC_S8: "PRISEC TOTAL",
      SSI_PRI_S1: "PRIMARY PROSPECTING",
      SSI_PRI_S2: "PRIMARY FIRST IMPRESSION",
      SSI_PRI_S3: "PRIMARY QUALIFYING",
      SSI_PRI_S4: "PRIMARY DEMONSTRATION",
      SSI_PRI_S5: "PRIMARY INFLUENCE",
      SSI_PRI_S6: "PRIMARY CLOSE",
      SSI_PRI_S7: "PRIMARY GENERAL",
      SSI_PRI_S8: "PRIMARY TOTAL",
      SSI_SECOND_S1: "SECOND PROSPECTING",
      SSI_SECOND_S2: "SECOND FIRST IMPRESSION",
      SSI_SECOND_S3: "SECOND QUALIFYING",
      SSI_SECOND_S4: "SECOND DEMONSTRATION",
      SSI_SECOND_S5: "SECOND INFLUENCE",
      SSI_SECOND_S6: "SECOND CLOSE",
      SSI_SECOND_S7: "SECOND GENERAL",
      SSI_SECOND_S8: "SECOND TOTAL",
      SSI_THIRD_S1: "THIRD PROSPECTING",
      SSI_THIRD_S2: "THIRD FIRST IMPRESSION",
      SSI_THIRD_S3: "THIRD QUALIFYING",
      SSI_THIRD_S4: "THIRD DEMONSTRATION",
      SSI_THIRD_S5: "THIRD INFLUENCE",
      SSI_THIRD_S6: "THIRD CLOSE",
      SSI_THIRD_S7: "THIRD GENERAL",
      SSI_THIRD_S8: "THIRD TOTAL",
      SSI_VERSION: "",
    },
    SSI_TSI: {
      SSI_1ST_S1: "TARGET 1ST",
      SSI_1ST_S2: "ADAPT 1ST",
      SSI_1ST_S3: "RESEARCH 1ST",
      SSI_1ST_S4: "GUIDE 1ST",
      SSI_1ST_S5: "EXPLAIN 1ST",
      SSI_1ST_S6: "TRANSITION 1ST",
      SSI_1ST_S7: "GENERAL 1ST",
      SSI_1ST_S8: "TOTAL 1ST",
      SSI_2ND_S1: "TARGET 2ND",
      SSI_2ND_S2: "ADAPT 2ND",
      SSI_2ND_S3: "RESEARCH 2ND",
      SSI_2ND_S4: "GUIDE 2ND",
      SSI_2ND_S5: "EXPLAIN 2ND",
      SSI_2ND_S6: "TRANSITION 2ND",
      SSI_2ND_S7: "GENERAL 2ND",
      SSI_2ND_S8: "TOTAL 2ND",
      SSI_3RD_S1: "TARGET 3RD",
      SSI_3RD_S2: "ADAPT 3RD",
      SSI_3RD_S3: "RESEARCH 3RD",
      SSI_3RD_S4: "GUIDE 3RD",
      SSI_3RD_S5: "EXPLAIN 3RD",
      SSI_3RD_S6: "TRANSITION 3RD",
      SSI_3RD_S7: "GENERAL 3RD",
      SSI_3RD_S8: "TOTAL 3RD",
      SSI_4TH_S1: "TARGET 4TH",
      SSI_4TH_S2: "ADAPT 4TH",
      SSI_4TH_S3: "RESEARCH 4TH",
      SSI_4TH_S4: "GUIDE 4TH",
      SSI_4TH_S5: "EXPLAIN 4TH",
      SSI_4TH_S6: "TRANSITION 4TH",
      SSI_4TH_S7: "GENERAL 4TH",
      SSI_4TH_S8: "TOTAL 4TH",
      SSI_ACUMEN: "SALES ACUMEN",
      SSI_ELAPSED: "",
      SSI_NORM: "",
      SSI_VERSION: "",
    },
    STRESS_RESP: {
      STRESS_F01_R01: "",
      STRESS_F02_R01: "",
      STRESS_F03_R01: "",
      STRESS_F04_R01: "",
      STRESS_F05_R01: "",
      STRESS_F06_R01: "",
      STRESS_F07_R01: "",
      STRESS_F08_R01: "",
      STRESS_F09_R01: "",
      STRESS_F10_R01: "",
      STRESS_F11_R01: "",
      STRESS_F12_R01: "",
      STRESS_F13_R01: "",
      STRESS_F14_R01: "",
      STRESS_F15_R01: "",
      STRESS_F16_R01: "",
      STRESS_F17_R01: "",
      STRESS_F18_R01: "",
      STRESS_F19_R01: "",
      STRESS_F20_R01: "",
      STRESS_F21_R01: "",
      STRESS_F22_R01: "",
      STRESS_F23_R01: "",
      STRESS_F24_R01: "",
      STRESS_F25_R01: "",
      STRESS_F26_R01: "",
      STRESS_F27_R01: "",
      STRESS_F28_R01: "",
      STRESS_F29_R01: "",
      STRESS_F30_R01: "",
      STRESS_F31_R01: "",
      STRESS_F32_R01: "",
      STRESS_F33_R01: "",
      STRESS_F34_R01: "",
      STRESS_F35_R01: "",
      STRESS_F36_R01: "",
      STRESS_F37_R01: "",
      STRESS_F38_R01: "",
      STRESS_F39_R01: "",
      STRESS_F40_R01: "",
      STRESS_F41_R01: "",
      STRESS_F42_R01: "",
      STRESS_F43_R01: "",
      STRESS_F44_R01: "",
      STRESS_F45_R01: "",
      STRESS_F46_R01: "",
      STRESS_F47_R01: "",
      STRESS_F48_R01: "",
      STRESS_F49_R01: "",
      STRESS_F50_R01: "",
      STRESS_F51_R01: "",
      STRESS_F52_R01: "",
      STRESS_F53_R01: "",
      STRESS_F54_R01: "",
      STRESS_F55_R01: "",
      STRESS_F56_R01: "",
      STRESS_F57_R01: "",
      STRESS_F58_R01: "",
      STRESS_F59_R01: "",
      STRESS_F60_R01: "",
      STRESS_F61_R01: "",
      STRESS_F62_R01: "",
      STRESS_F63_R01: "",
      STRESS_F64_R01: "",
      STRESS_F65_R01: "",
      STRESS_F66_R01: "",
      STRESS_F67_R01: "",
      STRESS_F68_R01: "",
    },
    STRESS2_RESP: {
      STRESS2_F01_R01: "",
      STRESS2_F02_R01: "",
      STRESS2_F03_R01: "",
      STRESS2_F04_R01: "",
      STRESS2_F05_R01: "",
      STRESS2_F06_R01: "",
      STRESS2_F07_R01: "",
      STRESS2_F08_R01: "",
      STRESS2_F09_R01: "",
    },
    STRESS_ALL: {
      STRESS1_T: "Manager / Supervisor (Net Effect)",
      STRESS1_N: "Manager / Supervisor (Stress Effect)",
      STRESS1_P: "Manager / Supervisor (Positive Effect)",
      STRESS1_Z: "",
      STRESS1_I: "Manager/ Supervisor",
      STRESS1_PERCEPT: "Manager / Supervisor Perception",
      STRESS1_AWARENESS: "Management Awareness",
      STRESS1_STYLE: "Management Style",
      STRESS2_T: "Control (Net Effect)",
      STRESS2_N: "Control (Stress Effect)",
      STRESS2_P: "Control (Positive Effect)",
      STRESS2_Z: "",
      STRESS2_I: "Control",
      STRESS2_PERCEPT: "Control Perception",
      STRESS2_INVOLVEMENT: "Workplace Involvement",
      STRESS2_EMPOWERMENT: "Workplace Empowerment",
      STRESS3_T: "Demands (Net Effect)",
      STRESS3_N: "Demands (Stress Effect)",
      STRESS3_P: "Demands (Positive Effect)",
      STRESS3_Z: "",
      STRESS3_I: "Demands",
      STRESS3_PERCEPT: "Demands Perception",
      STRESS3_TIME_MAN: "Time Management",
      STRESS3_JOB_MATCH: "Competency Job Match",
      STRESS4_T: "Effort / Reward Balance (Net Effect)",
      STRESS4_N: "Effort / Reward Balance (Stress Effect)",
      STRESS4_P: "Effort / Reward Balance (Positive Effect)",
      STRESS4_Z: "",
      STRESS4_I: "Effort / Reward Balance",
      STRESS4_PERCEPT: "Effort / Reward Balance Perception",
      STRESS4_MEANINGFULNESS: "Workplace Meaningfulness",
      STRESS4_RECOGNITION: "Workplace Recognition and Reward",
      STRESS5_T: "Job Security (Net Effect)",
      STRESS5_N: "Job Security (Stress Effect)",
      STRESS5_P: "Job Security (Positive Effect)",
      STRESS5_Z: "",
      STRESS5_I: "Job Security",
      STRESS5_PERCEPT: "Job Security Perception",
      STRESS5_OPPORTUNITY: "Opportunity",
      STRESS5_STABILITY: "Job Stability",
      STRESS5_TRUST: "Workplace Trust",
      STRESS6_T: "Organizational Change (Net Effect)",
      STRESS6_N: "Organizational Change (Stress Effect)",
      STRESS6_P: "Organizational Change (Positive Effect)",
      STRESS6_Z: "",
      STRESS6_I: "Organizational Change",
      STRESS6_PERCEPT: "Organizational Change Perception",
      STRESS6_COMMUNICATION: "Organizational Communication",
      STRESS6_EVOLUTION: "Organizational Evolution",
      STRESS6_VISION: "Organizational Vision",
      STRESS7_T: "Social Support (Net Effect)",
      STRESS7_N: "Social Support (Stress Effect)",
      STRESS7_P: "Social Support (Positive Effect)",
      STRESS7_Z: "",
      STRESS7_I: "Social Support",
      STRESS7_PERCEPT: "Social Support Perception",
      STRESS7_AVOIDANCE: "Avoidance",
      STRESS7_COOPERATION: "Cooperation",
      STRESS7_FRUSTRATION: "Frustration",
      STRESS8_T: "Stress Symptoms (Net Effect)",
      STRESS8_N: "Stress Symptoms (Stress Effect)",
      STRESS8_P: "Stress Symptoms (Positive Effect)",
      STRESS8_Z: "",
      STRESS8_I: "Stress Symptoms",
      STRESS8_PERCEPT: "Stress Symptoms Perception",
      STRESS8_PHYSICAL: "Physical",
      STRESS8_EMOTIONAL: "Emotional",
      STRESS8_COGNITIVE: "Cognitive",
      STRESS8_BEHAVIORAL: "Behavioral",
      STRESS_TOTAL_T: "Total (Net Effect)",
      STRESS_TOTAL_N: "Total (Stress Effect)",
      STRESS_TOTAL_P: "Total (Positive Effect)",
      STRESS_TOTAL_Z: "",
      STRESS_TOTAL_I: "Total",
      STRESS_TOTAL_PERCEPT: "Total Perception",
      STRESS_NORM: "",
      STRESS_ELAPSED: "",
      STRESS2_NORM: "",
      STRESS2_ELAPSED: "",
      STRESS_RESPONSES: "",
      STRESS2_RESPONSES: "",
    },
    STRESS_LEGACY: {
      STRESS3_I: "Demands",
      STRESS3_TIME_MAN: "Time Management",
      STRESS3_JOB_MATCH: "Competency Job Match",
      STRESS3_P: "Demands (Positive Effect)",
      STRESS3_N: "Demands (Stress Effect)",
      STRESS4_I: "Effort/Reward Balance",
      STRESS4_MEANINGFULNESS: "Workplace Meaningfulness",
      STRESS4_RECOGNITION: "Workplace Recognition and Reward",
      STRESS4_P: "Effort/Reward Balance (Positive Effect)",
      STRESS4_N: "Effort/Reward Balance (Stress Effect)",
      STRESS2_I: "Control",
      STRESS2_INVOLVEMENT: "Workplace Involvement",
      STRESS2_EMPOWERMENT: "Workplace Empowerment",
      STRESS2_P: "Control (Positive Effect)",
      STRESS2_N: "Control (Stress Effect)",
      STRESS6_I: "Organizational Change",
      STRESS6_COMMUNICATION: "Organizational Communication",
      STRESS6_EVOLUTION: "Organizational Evolution",
      STRESS6_VISION: "Organizational Vision",
      STRESS6_P: "Organizational Change (Positive Effect)",
      STRESS6_N: "Organizational Change (Stress Effect)",
      STRESS1_I: "Manager/Supervisor",
      STRESS1_AWARENESS: "Management Awareness",
      STRESS1_STYLE: "Management Style",
      STRESS1_P: "Manager/Supervisor (Positive Effect)",
      STRESS1_N: "Manager/Supervisor (Stress Effect)",
      STRESS7_I: "Social Support",
      STRESS7_AVOIDANCE: "Avoidance",
      STRESS7_COOPERATION: "Cooperation",
      STRESS7_FRUSTRATION: "Frustration",
      STRESS7_P: "Social Support (Positive Effect)",
      STRESS7_N: "Social Support (Stress Effect)",
      STRESS5_I: "Job Security",
      STRESS5_OPPORTUNITY: "Opportunity",
      STRESS5_STABILITY: "Job Stability",
      STRESS5_TRUST: "Workplace Trust",
      STRESS5_P: "Job Security (Positive Effect)",
      STRESS5_N: "Job Security (Stress Effect)",
      STRESS8_I: "Stress Symptoms",
      STRESS8_PHYSICAL: "Physical",
      STRESS8_EMOTIONAL: "Emotional",
      STRESS8_COGNITIVE: "Cognitive",
      STRESS8_BEHAVIORAL: "Behavioral",
      STRESS_TOTAL_I: "Total",
      STRESS_TOTAL_P: "Total (Positive Effect)",
      STRESS_TOTAL_N: "Total (Stress Effect)",
    },
    STRESS_INDEX: {
      STRESS1_I: "Manager/Supervisor",
      STRESS2_I: "Control",
      STRESS3_I: "Demands",
      STRESS4_I: "Effort/Reward Balance",
      STRESS5_I: "Job Security",
      STRESS6_I: "Organizational Change",
      STRESS7_I: "Social Support",
      STRESS8_I: "Stress Symptoms",
      STRESS_TOTAL_I: "Total",
    },
    STRESS_PERCEPT: {
      STRESS1_PERCEPT: "Manager/Supervisor Perception",
      STRESS2_PERCEPT: "Control Perception",
      STRESS3_PERCEPT: "Demands Perception",
      STRESS4_PERCEPT: "Effort/Reward Balance Perception",
      STRESS5_PERCEPT: "Job Security Perception",
      STRESS6_PERCEPT: "Organizational Change Perception",
      STRESS7_PERCEPT: "Social Support Perception",
      STRESS8_PERCEPT: "Stress Symptoms Perception",
      STRESS_TOTAL_PERCEPT: "Total Perception",
    },
    V_ALL: {
      V1: "THE",
      V2: "UTI",
      V3: "AES",
      V4: "SOC",
      V5: "IND",
      V6: "TRA",
      V7: "INTELLECTUAL",
      V8: "RESOURCEFUL",
      V9: "HARMONIOUS",
      V10: "ALTRUISTIC",
      V11: "COMMANDING",
      V12: "STRUCTURED",
      V13: "INSTINCTIVE",
      V14: "SELFLESS",
      V15: "OBJECTIVE",
      V16: "INTENTIONAL",
      V17: "COLLABORATIVE",
      V18: "RECEPTIVE",
      V1_RAW: "RAW_THE",
      V2_RAW: "RAW_UTI",
      V3_RAW: "RAW_AES",
      V4_RAW: "RAW_SOC",
      V5_RAW: "RAW_IND",
      V6_RAW: "RAW_TRA",
      V7_RAW: "",
      V8_RAW: "",
      V9_RAW: "",
      V10_RAW: "",
      V11_RAW: "",
      V12_RAW: "",
      V13_RAW: "",
      V14_RAW: "",
      V15_RAW: "",
      V16_RAW: "",
      V17_RAW: "",
      V18_RAW: "",
      V1_ZONE: "THEORETICAL_RANKING",
      V2_ZONE: "UTILITARIAN_RANKING",
      V3_ZONE: "AESTHETIC_RANKING",
      V4_ZONE: "SOCIAL_RANKING",
      V5_ZONE: "INDIVIDUALISTIC_RANKING",
      V6_ZONE: "TRADITIONAL_RANKING",
      V1_PLACE: "THEORETICAL_PLACE",
      V2_PLACE: "UTILITARIAN_PLACE",
      V3_PLACE: "AESTHETIC_PLACE",
      V4_PLACE: "SOCIAL_PLACE",
      V5_PLACE: "INDIVIDUALISTIC_PLACE",
      V6_PLACE: "TRADITIONAL_PLACE",
      V_ELAPSED: "",
      V_NORM: "",
      V_RESPONSES: "",
    },
    V_LEGACY: {
      V1: "THE",
      V2: "UTI",
      V3: "AES",
      V4: "SOC",
      V5: "IND",
      V6: "TRA",
      V7: "INTELLECTUAL",
      V8: "RESOURCEFUL",
      V9: "HARMONIOUS",
      V10: "ALTRUISTIC",
      V11: "COMMANDING",
      V12: "STRUCTURED",
      V13: "INSTINCTIVE",
      V14: "SELFLESS",
      V15: "OBJECTIVE",
      V16: "INTENTIONAL",
      V17: "COLLABORATIVE",
      V18: "RECEPTIVE",
      V1_RAW: "RAW_THE",
      V2_RAW: "RAW_UTI",
      V3_RAW: "RAW_AES",
      V4_RAW: "RAW_SOC",
      V5_RAW: "RAW_IND",
      V6_RAW: "RAW_TRA",
    },
    WE1_ALL: {
      WE1_D_RAW: "D WE",
      WE1_I_RAW: "I WE",
      WE1_S_RAW: "S WE",
      WE1_C_RAW: "C WE",
      WE1_D: "D WE (%)",
      WE1_I: "I WE (%)",
      WE1_S: "S WE (%)",
      WE1_C: "C WE (%)",
      WE1_W: "WHEEL POSITION WE",
      WE1_ELAPSED: "SECS TO COMPLETE WE INSTRUMENT",
      WE1_RESPONSES: "",
    },
    WE1_RESP: {
      WE1_F01_R01: "",
      WE1_F01_R02: "",
      WE1_F01_R03: "",
      WE1_F01_R04: "",
      WE1_F02_R01: "",
      WE1_F02_R02: "",
      WE1_F02_R03: "",
      WE1_F02_R04: "",
      WE1_F03_R01: "",
      WE1_F03_R02: "",
      WE1_F03_R03: "",
      WE1_F03_R04: "",
      WE1_F04_R01: "",
      WE1_F04_R02: "",
      WE1_F04_R03: "",
      WE1_F04_R04: "",
      WE1_F05_R01: "",
      WE1_F05_R02: "",
      WE1_F05_R03: "",
      WE1_F05_R04: "",
      WE1_F06_R01: "",
      WE1_F06_R02: "",
      WE1_F06_R03: "",
      WE1_F06_R04: "",
      WE1_F07_R01: "",
      WE1_F07_R02: "",
      WE1_F07_R03: "",
      WE1_F07_R04: "",
      WE1_F08_R01: "",
      WE1_F08_R02: "",
      WE1_F08_R03: "",
      WE1_F08_R04: "",
      WE1_F09_R01: "",
      WE1_F09_R02: "",
      WE1_F09_R03: "",
      WE1_F09_R04: "",
      WE1_F10_R01: "",
      WE1_F10_R02: "",
      WE1_F10_R03: "",
      WE1_F10_R04: "",
      WE1_F11_R01: "",
      WE1_F11_R02: "",
      WE1_F11_R03: "",
      WE1_F11_R04: "",
      WE1_F12_R01: "",
      WE1_F12_R02: "",
      WE1_F12_R03: "",
      WE1_F12_R04: "",
      WE1_F13_R01: "",
      WE1_F13_R02: "",
      WE1_F13_R03: "",
      WE1_F13_R04: "",
      WE1_F14_R01: "",
      WE1_F14_R02: "",
      WE1_F14_R03: "",
      WE1_F14_R04: "",
    },
    WE2_ALL: {
      WE2_D_RAW: "D IDEAL WE",
      WE2_I_RAW: "I IDEAL WE",
      WE2_S_RAW: "S IDEAL WE",
      WE2_C_RAW: "C IDEAL WE",
      WE2_D: "D IDEAL WE (%)",
      WE2_I: "I IDEAL WE (%)",
      WE2_S: "S IDEAL WE (%)",
      WE2_C: "C IDEAL WE (%)",
      WE2_W: "WHEEL POSITION IDEAL WE",
      WE2_ELAPSED: "SECS TO COMPLETE IDEAL WE INSTRUMENT",
      WE2_RESPONSES: "",
    },
    WE2_RESP: {
      WE2_F01_R01: "",
      WE2_F01_R02: "",
      WE2_F01_R03: "",
      WE2_F01_R04: "",
      WE2_F02_R01: "",
      WE2_F02_R02: "",
      WE2_F02_R03: "",
      WE2_F02_R04: "",
      WE2_F03_R01: "",
      WE2_F03_R02: "",
      WE2_F03_R03: "",
      WE2_F03_R04: "",
      WE2_F04_R01: "",
      WE2_F04_R02: "",
      WE2_F04_R03: "",
      WE2_F04_R04: "",
      WE2_F05_R01: "",
      WE2_F05_R02: "",
      WE2_F05_R03: "",
      WE2_F05_R04: "",
      WE2_F06_R01: "",
      WE2_F06_R02: "",
      WE2_F06_R03: "",
      WE2_F06_R04: "",
      WE2_F07_R01: "",
      WE2_F07_R02: "",
      WE2_F07_R03: "",
      WE2_F07_R04: "",
      WE2_F08_R01: "",
      WE2_F08_R02: "",
      WE2_F08_R03: "",
      WE2_F08_R04: "",
      WE2_F09_R01: "",
      WE2_F09_R02: "",
      WE2_F09_R03: "",
      WE2_F09_R04: "",
      WE2_F10_R01: "",
      WE2_F10_R02: "",
      WE2_F10_R03: "",
      WE2_F10_R04: "",
      WE2_F11_R01: "",
      WE2_F11_R02: "",
      WE2_F11_R03: "",
      WE2_F11_R04: "",
      WE2_F12_R01: "",
      WE2_F12_R02: "",
      WE2_F12_R03: "",
      WE2_F12_R04: "",
      WE2_F13_R01: "",
      WE2_F13_R02: "",
      WE2_F13_R03: "",
      WE2_F13_R04: "",
      WE2_F14_R01: "",
      WE2_F14_R02: "",
      WE2_F14_R03: "",
      WE2_F14_R04: "",
    },
    COOPER: {
      COOPER1_RAW: "",
      COOPER2_RAW: "",
      COOPER3_RAW: "",
      COOPER4_RAW: "",
      COOPER5_RAW: "",
      COOPER6_RAW: "",
      COOPER7_RAW: "",
      COOPER8_RAW: "",
      COOPER_NORM: "",
      COOPER_ELAPSED: "",
    },
    COOPER_RESP: {
      COOPER_F01_R01: "",
      COOPER_F02_R01: "",
      COOPER_F03_R01: "",
      COOPER_F04_R01: "",
      COOPER_F05_R01: "",
      COOPER_F06_R01: "",
      COOPER_F07_R01: "",
      COOPER_F08_R01: "",
      COOPER_F09_R01: "",
      COOPER_F10_R01: "",
      COOPER_F11_R01: "",
      COOPER_F12_R01: "",
      COOPER_F13_R01: "",
      COOPER_F14_R01: "",
      COOPER_F15_R01: "",
      COOPER_F16_R01: "",
      COOPER_F17_R01: "",
      COOPER_F18_R01: "",
      COOPER_F19_R01: "",
      COOPER_F20_R01: "",
      COOPER_F21_R01: "",
      COOPER_F22_R01: "",
      COOPER_F23_R01: "",
      COOPER_F24_R01: "",
      COOPER_F25_R01: "",
      COOPER_F26_R01: "",
      COOPER_F27_R01: "",
      COOPER_F28_R01: "",
      COOPER_F29_R01: "",
      COOPER_F30_R01: "",
      COOPER_F31_R01: "",
      COOPER_F32_R01: "",
      COOPER_F33_R01: "",
      COOPER_F34_R01: "",
      COOPER_F35_R01: "",
      COOPER_F36_R01: "",
      COOPER_F37_R01: "",
      COOPER_F38_R01: "",
      COOPER_F39_R01: "",
      COOPER_F40_R01: "",
      COOPER_F41_R01: "",
      COOPER_F42_R01: "",
      COOPER_F43_R01: "",
      COOPER_F44_R01: "",
      COOPER_F45_R01: "",
      COOPER_F46_R01: "",
      COOPER_F47_R01: "",
      COOPER_F48_R01: "",
      COOPER_F49_R01: "",
      COOPER_F50_R01: "",
      COOPER_F51_R01: "",
      COOPER_F52_R01: "",
      COOPER_F53_R01: "",
      COOPER_F54_R01: "",
      COOPER_F55_R01: "",
      COOPER_F56_R01: "",
      COOPER_F57_R01: "",
      COOPER_F58_R01: "",
      COOPER_F59_R01: "",
      COOPER_F60_R01: "",
      COOPER_F61_R01: "",
      COOPER_F62_R01: "",
      COOPER_F63_R01: "",
      COOPER_F64_R01: "",
      COOPER_F65_R01: "",
      COOPER_F66_R01: "",
      COOPER_F67_R01: "",
      COOPER_F68_R01: "",
      COOPER_F69_R01: "",
      COOPER_F70_R01: "",
      COOPER_F71_R01: "",
      COOPER_F72_R01: "",
      COOPER_F73_R01: "",
      COOPER_F74_R01: "",
      COOPER_F75_R01: "",
      COOPER_F76_R01: "",
      COOPER_F77_R01: "",
      COOPER_F78_R01: "",
    },
    EFE_ALL: {
      EFE1: "8FE_PURPOSE",
      EFE2: "8FE_ACCOUNTABILITY",
      EFE3: "8FE_RESOURCES",
      EFE4: "8FE_TRUST",
      EFE5: "8FE_RECOGNITION",
      EFE6: "8FE_CARE",
      EFE7: "8FE_DEVELOPMENT",
      EFE8: "8FE_AGILITY",
      EFE_C: "8FE_CALCULATED_ENGAGEMENT",
      EFE1Q1: "8FE_PURPOSE_Q1",
      EFE1Q2: "8FE_PURPOSE_Q2",
      EFE1Q3: "8FE_PURPOSE_Q3",
      EFE2Q1: "8FE_ACCOUNTABILITY_Q1",
      EFE2Q2: "8FE_ACCOUNTABILITY_Q2",
      EFE2Q3: "8FE_ACCOUNTABILITY_Q3",
      EFE3Q1: "8FE_RESOURCES_Q1",
      EFE3Q2: "8FE_RESOURCES_Q2",
      EFE3Q3: "8FE_RESOURCES_Q3",
      EFE4Q1: "8FE_TRUST_Q1",
      EFE4Q2: "8FE_TRUST_Q2",
      EFE4Q3: "8FE_TRUST_Q3",
      EFE5Q1: "8FE_RECOGNITION_Q1",
      EFE5Q2: "8FE_RECOGNITION_Q2",
      EFE5Q3: "8FE_RECOGNITION_Q3",
      EFE6Q1: "8FE_CARE_Q1",
      EFE6Q2: "8FE_CARE_Q2",
      EFE6Q3: "8FE_CARE_Q3",
      EFE7Q1: "8FE_DEVELOPMENT_Q1",
      EFE7Q2: "8FE_DEVELOPMENT_Q2",
      EFE7Q3: "8FE_DEVELOPMENT_Q3",
      EFE8Q1: "8FE_AGILITY_Q1",
      EFE8Q2: "8FE_AGILITY_Q2",
      EFE8Q3: "8FE_AGILITY_Q3",
      EFE_NORM: "",
      EFE_ELAPSED: "",
      EFE_RESPONSES: "",
    },
  },
};

config.fields = Object.values(config.fieldMappings).reduce((o, v) => {
  Object.entries(v).forEach(([key, val]) => (o[key] = val === "" ? key : val.replace(/ /g, "_")));
  return o;
}, {});

config.fieldNames = Object.values(config.fieldMappings).reduce((o, v) => {
  Object.entries(v).forEach(([key, val]) => (o[key] = val));
  return o;
}, {});

export default config;
