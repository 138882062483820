// Reducers
import { combineReducers } from "redux";
import { Action } from "./actions";

// TODO: More clearly define the individual state types

function reportClipboard(state: any[] = [], action: { any; id: number; type: Action }): any[] {
  const { type, ...rest } = action;
  switch (action.type) {
    case Action.PICKUP_REPORT:
      return [...state, rest];
    case Action.PUTDOWN_REPORT:
      return state.filter(m => m.id !== action.id);
    case Action.CLEAR_REPORTS:
      return [];
    default:
      return state;
  }
}

function linkClipboard(state: any = [], action: { any; id: number; type: Action }): any {
  const { type, ...rest } = action;
  switch (action.type) {
    case Action.PICKUP_LINK:
      return [...state, rest];
    case Action.PUTDOWN_LINK:
      return state.filter(m => m.id !== action.id);
    case Action.CLEAR_LINKS:
      return [];
    default:
      return state;
  }
}

function accountClipboard(state: any[] = [], action: { any; id: number; type: Action }): any {
  const { type, ...rest } = action;
  switch (action.type) {
    case Action.PICKUP_ACCOUNT:
      return [...state, rest];
    case Action.PUTDOWN_ACCOUNT:
      return state.filter(m => m.id !== action.id);
    case Action.CLEAR_ACCOUNTS:
      return [];
    default:
      return state;
  }
}

export type StoreType = {
  reportClipboard: any[];
  linkClipboard: any[];
  accountClipboard: any[];
};

export default combineReducers({
  reportClipboard,
  linkClipboard,
  accountClipboard
});
