import * as React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import RestDataProvider, { toastErrors } from "../Helpers/RestDataProvider";
import FilterableReportviewSelector from "../Forms/Widgets/FilterableReportviewSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, Formik } from "formik";
import { TReportview } from "../../support/dataTypes";
import { toast } from "react-toastify";
import { ComboSourceList } from "./ComboSource";
import LabeledCheckbox from "../Forms/Widgets/LabeledCheckbox";
import ConfirmButton from "../Forms/Widgets/ConfirmButton";

import { defaultClient as axios } from "@app/support/api_client";
import isEqual from "react-fast-compare";

export type TComboDetail = {
  id: number;
  reportview: TReportview;
  live: boolean;
  pushed: boolean;
};

interface FormCallbacks {
  comboTargetId?: number;
  onSuccess(): void;
  onClose(): void;
}

function ComboDetailForm({
  title,
  onSubmit,
  initialValues,
  onClose,
  onDelete,
}: {
  title: string;
  initialValues: any;
  onSubmit(values: any);
  onDelete?(e: any): void;
} & FormCallbacks) {
  const validate = (values) => {
    const errors = {} as any;
    if (!values.reportview_id) {
      errors.reportview_id = "must be selected";
    }
    return errors;
  };

  return (
    <Modal centered show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        enableReinitialize={true}
        render={(props) => (
          <form onSubmit={props.handleSubmit}>
            <Modal.Body>
              <FilterableReportviewSelector name="reportview_id" label="Reportview" />
              <Field component={LabeledCheckbox} custom name="live" label="Live?" />
              <Field
                component={LabeledCheckbox}
                custom
                name="pushed"
                label="Must be pushed to account?"
              />
            </Modal.Body>
            <Modal.Footer>
              {onDelete && (
                <ConfirmButton
                  icon="trash"
                  label="Delete"
                  prompt="Really delete?"
                  onConfirm={onDelete}
                />
              )}
              <Button type="submit">
                <FontAwesomeIcon icon="save" /> Save
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
}

function CreateComboDetail({ comboTargetId, onSuccess, onClose }: FormCallbacks) {
  const handleSubmit = (values) => {
    axios
      .post(`/combo_targets/${comboTargetId}/combo_details.json`, { comboDetail: values })
      .then(() => {
        onClose();
        onSuccess();
        toast.success("Created new combo detail");
      })
      .catch(toastErrors);
  };

  return (
    <ComboDetailForm
      title="Create New Combo Detail"
      initialValues={{ live: true }}
      onClose={onClose}
      onSuccess={onSuccess}
      onSubmit={handleSubmit}
    />
  );
}

function EditComboDetail({
  comboTargetId,
  detail,
  onClose,
  onSuccess,
}: {
  detail: TComboDetail;
  onClose(): void;
} & FormCallbacks) {
  const url = `/combo_targets/${comboTargetId}/combo_details/${detail.id}.json`;

  const handleSubmit = (values) => {
    axios
      .put(url, {
        comboDetail: values,
      })
      .then(() => {
        onClose();
        onSuccess();
        toast.success(`Updated combo detail #${detail.id}`);
      })
      .catch(toastErrors);
  };

  const onDelete = React.useCallback(() => {
    axios
      .delete(url)
      .then(() => {
        onClose();
        onSuccess();
        toast.success(`Deleted combo detail #${detail.id}`);
      })
      .catch(toastErrors);
  }, [url, onClose, onSuccess, detail.id]);

  const initialValues = {
    reportview_id: detail.reportview.id,
    live: detail.live,
    pushed: detail.pushed,
  };
  return (
    <ComboDetailForm
      onDelete={onDelete}
      title="Edit Combo Detail"
      initialValues={initialValues}
      onClose={onClose}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
    />
  );
}

function ComboDetail({
  detail,
  active,
  onSelect,
  setEditing,
  comboTargetId,
  comboTargetMode,
  canEdit,
}: {
  detail: TComboDetail;
  active: boolean;
  comboTargetId: number;
  comboTargetMode: number;
  onSelect(d: TComboDetail);
  setEditing(d: TComboDetail);
  canEdit: boolean;
}) {
  const toggle = React.useCallback(
    (e) => {
      onSelect(detail);
      e.preventDefault();
    },
    [onSelect, detail]
  );

  const edit = React.useCallback(
    (e) => {
      setEditing(detail);
      e.preventDefault();
    },
    [setEditing, detail]
  );
  return (
    <ListGroup.Item key={detail.id} className="py-2">
      <Row>
        <Col>
          <a href="#" onClick={toggle}>
            <FontAwesomeIcon icon={active ? "caret-down" : "caret-right"} /> {detail.reportview.id}{" "}
            - {detail.reportview.name} {detail.reportview.description}
          </a>
        </Col>
        {canEdit && (
          <Col xs="auto">
            <a href="#" onClick={edit}>
              <FontAwesomeIcon icon="pencil-alt" /> Edit
            </a>
          </Col>
        )}
      </Row>
      {active && (
        <ComboSourceList
          comboTargetMode={comboTargetMode}
          comboTargetId={comboTargetId}
          comboDetailId={detail.id}
          canEdit={canEdit}
        />
      )}
    </ListGroup.Item>
  );
}

export default function ComboDetailList({
  comboTargetId,
  comboTargetMode,
  canEdit,
}: {
  comboTargetId: number;
  comboTargetMode: number;
  canEdit: boolean;
}) {
  const [selected, setSelected] = React.useState<TComboDetail | null>(null);
  const [editing, setEditing] = React.useState<TComboDetail | null>(null);
  const clearEditing = React.useCallback(() => setEditing(null), [setEditing]);
  const onSelect = React.useCallback(
    (next) => {
      if (selected === next) {
        setSelected(null);
      } else {
        setSelected(next);
      }
    },
    [selected]
  );

  let detailModeLabel = "Create a new report of type:";
  // Convert in place
  if (comboTargetMode === 4) {
    detailModeLabel = "Replace an existing report of type:";
  }

  return (
    <RestDataProvider
      active={true}
      url={`/combo_targets/${comboTargetId}/combo_details.json`}
      params={{ combo_target_id: comboTargetId }}
      defaultValue={[] as TComboDetail[]}
      path="comboDetails"
    >
      {({ data, refresh }) => (
        <Card className="my-4">
          <Card.Header>
            <Row>
              <Col>
                <strong>{detailModeLabel}</strong>
              </Col>
              {canEdit && (
                <Col xs="auto">
                  <Button
                    size="sm"
                    onClick={() =>
                      setEditing({
                        id: 0,
                        live: true,
                        pushed: false,
                        reportview: { id: 0, name: "" },
                      })
                    }
                  >
                    <FontAwesomeIcon icon="plus" /> Add New Detail
                  </Button>
                </Col>
              )}
            </Row>
          </Card.Header>
          <ListGroup variant="flush">
            {data.map((d) => (
              <ComboDetail
                comboTargetId={comboTargetId}
                comboTargetMode={comboTargetMode}
                key={d.id}
                detail={d}
                active={isEqual(selected, d)}
                onSelect={onSelect}
                setEditing={setEditing}
                canEdit={canEdit}
              />
            ))}
          </ListGroup>
          {editing && editing.id > 0 && (
            <EditComboDetail
              detail={editing}
              onClose={clearEditing}
              comboTargetId={comboTargetId}
              onSuccess={refresh}
            />
          )}
          {editing && editing.id === 0 && (
            <CreateComboDetail
              onClose={clearEditing}
              comboTargetId={comboTargetId}
              onSuccess={refresh}
            />
          )}
        </Card>
      )}
    </RestDataProvider>
  );
}
