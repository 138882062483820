import * as React from "react";
import { connect } from "react-redux";
import ResourcePane from "./ResourcePane";
import { putdown, pickup, clearClipboard, ClipboardType } from "../Store/actions";

class Pane extends ResourcePane {
  controller = "reports";
  clipboardType: ClipboardType = "report";

  canCopy = () => this.props.targets.link != undefined && this.props.targets.link != null;
  canMove = () => this.props.targets.link != undefined && this.props.targets.link != null;
  canTrash = () =>
    this.props.targets.trashLink != undefined && this.props.targets.trashLink != undefined;

  copyParams = (ids) => ({
    link_id: this.props.targets.link,
    report_ids: ids,
  });

  moveParams = (ids) => ({
    link_id: this.props.targets.link,
    report_ids: ids,
  });

  trashParams = (ids) => {
    return { link_ids: this.props.targets.trashLink, report_ids: ids };
  };

  renderField(row, col) {
    switch (col.key) {
      case "login":
        return <a href={`/respondents/${row.respondent_id}`}>{row.login}</a>;
      default:
        return super.renderField(row, col);
    }
  }
}

const mapStateToProps = (state) => ({
  data: state.reportClipboard,
  keys: { name: "Name", login: "Login", shortName: "Short Name" },
});
export default connect(mapStateToProps, { putdown, pickup, clearClipboard })(Pane);
