import * as React from "react";
import DateRangePicker from "../DateRangePicker";
import moment from "moment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { defaultClient as axios } from "@app/support/api_client";

import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from "recharts";

function ReportHistogram() {
  const [data, setData] = React.useState<{ key: string; count: number }[]>([]);

  const [range, setRange] = React.useState([moment().startOf("year"), moment()]);
  const [bucket, setBucket] = React.useState("month");
  const [kind, setKind] = React.useState("all");

  const onSetActiveButton = React.useCallback(
    (e) => {
      setBucket(e.currentTarget.value);
    },
    [setBucket]
  );

  const onSetActiveKind = React.useCallback(
    (e) => {
      setKind(e.currentTarget.value);
    },
    [setKind]
  );

  React.useEffect(() => {
    axios
      .get("/misc/histogram.json", {
        params: {
          find: kind,
          startDate: range[0].toISOString(),
          endDate: range[1].toISOString(),
          span: bucket,
        },
      })
      .then((res) => {
        if (res.data && res.data.data) {
          setData([]); // cause chart to reset YAxis
          setData(res.data.data);
        }
      });
  }, [bucket, range, kind]);

  const kinds = [
    { label: "All Reports", value: "all" },
    { label: "Non-Comp Reports", value: "non" },
    { label: "Comp Reports", value: "comp" },
    { label: "New Links", value: "links" },
    { label: "New Accounts", value: "accts" },
  ];

  const buckets = [
    { label: "Year", value: "year" },
    { label: "Month", value: "month" },
    { label: "Week", value: "week" },
    { label: "Day", value: "day" },
    { label: "Hour", value: "hour" },
  ];

  return (
    <Row>
      <Col>
        <div>
          <BarChart width={600} height={350} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="key" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="value"
              fill="#3983ae"
              name={(kinds.find((k) => k.value === kind) || { label: "Count" })["label"]}
            />
          </BarChart>
        </div>
      </Col>
      <Col>
        <ButtonGroup>
          {kinds.map((b) => (
            <Button
              key={b.value}
              value={b.value}
              active={kind === b.value}
              onClick={onSetActiveKind}
            >
              {b.label}
            </Button>
          ))}
        </ButtonGroup>
        <span className="mx-3">by</span>
        <ButtonGroup>
          {buckets.map((b) => (
            <Button
              key={b.value}
              value={b.value}
              active={bucket === b.value}
              onClick={onSetActiveButton}
            >
              {b.label}
            </Button>
          ))}
        </ButtonGroup>
        <Row className="mt-3">
          <Col>
            <DateRangePicker startDate={range[0]} endDate={range[1]} onChange={setRange} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ReportHistogram;
