import * as React from "react";
import { connect } from "react-redux";
import ResourcePane from "./ResourcePane";

import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Modal from "react-bootstrap/Modal";

import { putdown, pickup, clearClipboard, ClipboardType } from "../Store/actions";

class Pane extends ResourcePane {
  controller = "accounts";
  clipboardType: ClipboardType = "account";

  resetErrors() {
    this.setState({ errors: {} });
  }

  canCopy = () => false;
  copyParams = () => [];

  canMove() {
    return this.props.targets.account !== undefined && this.props.targets.account !== null;
  }

  moveParams = (ids) => {
    return { accountId: this.props.targets.account, sourceAccountIds: ids };
  };

  trashParams = (ids) => {
    return { sourceAccountIds: ids };
  };

  trashPicked() {
    this.setState({ confirmTrash: true });
  }

  confirmTrash = () => {
    this.setState({ confirmTrash: false });
    super.trashPicked();
  };

  moveWithAdd(rid) {
    this.movePicked([rid], { add_missing: "true" });
  }

  moveWithSkip(rid) {
    this.movePicked([rid], { add_missing: "false" });
  }

  renderField(row, col) {
    switch (col.key) {
      case "login":
        return <a href={`/accounts/${row.id}`}>{row.login}</a>;
      default:
        return super.renderField(row, col);
    }
  }

  renderRowButtons(row): JSX.Element | null | false {
    return (
      this.state.lastOp === "move" &&
      this.state.errors[row.id] !== null &&
      this.state.errors[row.id].match(
        /Target account does not have access to this account's reportviews/
      ) && (
        <Row>
          <Col>
            <ButtonGroup className="mt-1">
              <Button size="sm" variant="success" onClick={() => this.moveWithAdd(row.id)}>
                Move & Add Reports
              </Button>
              <Button size="sm" variant="warning" onClick={() => this.moveWithSkip(row.id)}>
                Move Without Reports
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      )
    );
  }

  onHide = () => {
    this.setState({ confirmTrash: false });
  };

  render() {
    return (
      <div>
        {super.render()}
        <Modal show={this.state.confirmTrash} onHide={this.onHide}>
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              This will delete the selected accounts, if they do not contain any subaccounts, links,
              respondents, or reports. Continue?
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.onHide}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.confirmTrash}>
              Trash Account(s)
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.accountClipboard,
});

export default connect(mapStateToProps, { pickup, putdown, clearClipboard })(Pane);
