import * as React from "react";
import { useCallback } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import SortableList, { SortableDragHandle } from "../SortableList";

function KeyValueField({ name, value, index, editable, setField }) {
  const onSetField = useCallback((e) => setField(index, e.currentTarget.value), [index, setField]);
  return (
    <input
      value={value}
      onChange={onSetField}
      readOnly={!editable}
      className="form-control"
      name={`${name}[]`}
    />
  );
}

function KeyValueRow({
  names,
  values,
  index,
  setRow,
  removeRow,
  editable,
  sortable,
  resizeable,
}: {
  names: string[];
  values: string[];
  index: number;
  setRow(index: number, values: string[]);
  removeRow(index: number);
  editable: boolean;
  sortable: boolean;
  resizeable: boolean;
}) {
  const onSetField = useCallback(
    (fIndex, val) => {
      const v = [...values];
      v[fIndex] = val;
      setRow(index, v);
    },
    [setRow, index, values]
  );

  const onRemoveRow = useCallback(() => {
    removeRow(index);
  }, [removeRow, index]);

  return (
    <Form.Group>
      <Row noGutters>
        <React.Fragment>
          {sortable && editable && (
            <Col xs="auto">
              <SortableDragHandle />
            </Col>
          )}
          {names.map((name, i) => (
            <Col key={i} className="mx-1">
              <KeyValueField
                name={name}
                value={values[i] || ""}
                index={i}
                setField={onSetField}
                editable={editable}
              />
            </Col>
          ))}
          {resizeable && editable && (
            <Col xs="auto">
              <Button onClick={onRemoveRow} variant="danger">
                <FontAwesomeIcon icon="minus" />
              </Button>
            </Col>
          )}
        </React.Fragment>
      </Row>
    </Form.Group>
  );
}

function KeyValueEditor({
  fieldNames,
  columnLabels,
  initialValues,
  resizeable,
  editable,
  sortable,
  onSetItems,
}: {
  fieldNames: string[];
  columnLabels: string[];
  initialValues: string[][];
  resizeable: boolean;
  editable: boolean;
  sortable: boolean;
  onSetItems(items: string[][]);
}) {
  const onAddItem = useCallback(() => {
    const i = [...initialValues];
    const nextValue = Array(fieldNames.length).map(() => "");
    i.push(nextValue);
    onSetItems(i);
  }, [onSetItems, initialValues, fieldNames.length]);

  const onItemsChanged = useCallback(
    (nextItems) => {
      onSetItems(nextItems);
    },
    [onSetItems]
  );

  return (
    <div>
      <Row className="mb-3" noGutters>
        {sortable && <Col xs="auto" style={{ width: "3rem" }} />}
        {columnLabels.map((c, i) => (
          <Col key={i}>
            <strong>{c}</strong>
          </Col>
        ))}
        {editable && resizeable && (
          <Col className={`text-right ${columnLabels.length > 0 && "col-auto"}`}>
            <Button onClick={onAddItem}>
              <FontAwesomeIcon icon="plus" />
            </Button>
          </Col>
        )}
      </Row>

      <SortableList
        editable={editable}
        resizeable={resizeable}
        sortable={sortable}
        onItemsChanged={onItemsChanged}
        items={initialValues}
        useDragHandle
        lockAxis="y"
        lockToContainerEdges={true}
      >
        {({ item, index, removeRow, setRow }) => {
          return (
            <KeyValueRow
              index={index}
              names={fieldNames}
              values={item}
              removeRow={removeRow}
              setRow={setRow}
              editable={editable}
              sortable={sortable}
              resizeable={resizeable}
            />
          );
        }}
      </SortableList>
    </div>
  );
}

KeyValueEditor.defaultProps = {
  editable: true,
  sortable: true,
  resizeable: true,
  columnLabels: ["Value"],
};

export default KeyValueEditor;
